import React from 'react'
import { withStyles, Tabs, Tab, makeStyles, Dialog, DialogTitle, DialogContent, Typography, TextField, DialogActions, Grid, IconButton, Button, createStyles, Theme } from '@material-ui/core';
import { colors, theme } from '../../common/theme';
import { Link, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { CommonLength } from '../../common/util'
import { ApiUtil } from '../../common/api/api';
import { useProject, ActionFactory } from '../../common/component/provider';
import ClearIcon from '@material-ui/icons/Clear';
import { CornerGradientButton } from '../../common/component';
import {
    CountryPhoneWidget,
    CountryType,
} from "../../common/component/countries";
import {
    getCountryCallingCode,
    CountryCode,
    isPossibleNumber,
    format,
    parse,
} from "libphonenumber-js";
import { StoreUtil } from '../../common/store';
interface StyledTabsProps {
    value: number;
    onChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
}

export const StyledTabs = withStyles({
    indicator: {
        display: "flex",
        justifyContent: "center",
        backgroundColor: "transparent",
        height: 4,
        "& > div": {
            maxWidth: 60,
            width: "100%",
            backgroundColor: colors.primary,
            borderRadius: 4,
        }
    }
})((props: StyledTabsProps) => (
    <Tabs {...props} variant="scrollable" scrollButtons="auto" textColor='primary' TabIndicatorProps={{ children: <div /> }} />
));

interface TabInfo {
    readonly id: string
    readonly to: string
}

const userTabsInfo: Array<TabInfo> = [
    { id: "prompt_permanent_access", to: "permanetAccess" },
    { id: "prompt_temporary_access", to: "temporayAccess" },
    { id: "prompt_audit_report", to: "auditReport" },
    { id: "action_user_profile", to: "profile" },
]

const userTabsInfoNoReport: Array<TabInfo> = [
    { id: "prompt_permanent_access", to: "permanetAccess" },
    { id: "prompt_temporary_access", to: "temporayAccess" },
    { id: "action_user_profile", to: "profile" },
]

const lockTabsInfo: Array<TabInfo> = [
    { id: "prompt_permanent_access", to: "permanetAccess" },
    { id: "prompt_temporary_access", to: "temporayAccess" },
    { id: "prompt_audit_report", to: "auditReport" },
    { id: "prompt_lock_info", to: "profile" },
]

const lockTabsInfoNoReport: Array<TabInfo> = [
    { id: "prompt_permanent_access", to: "permanetAccess" },
    { id: "prompt_temporary_access", to: "temporayAccess" },
    { id: "prompt_lock_info", to: "profile" },
]

const InvoiceTabsInfo: Array<TabInfo> = [
    { id: "prompt_bills", to: "bills" },
    { id: "prompt_card", to: "card" },
]

const VendorTabsInfo: Array<TabInfo> = [
    { to: "substation", id: "prompt_substations" },
    { to: "workType", id: "prompt_type_of_work" },
]

interface CreateTabsPros {
    panel: string | null
    label: string | null
}
export function CreateTabs({ panel, label }: CreateTabsPros) {
    if (panel === 'invoice') return <GenInvoiceTabs data={InvoiceTabsInfo} baseUrl={`/home/${panel}`} />
    if (panel === 'vendor' && label === "substations") return <GenTabs data={VendorTabsInfo} baseUrl={`/home/${panel}/${label}`} />

    return <div />
}


interface GenTabsProsp {
    data: Array<TabInfo>
    baseUrl: string
}

const tabsStyle = makeStyles({
    tab: {
        fontSize: '1rem',
        fontWeight: "normal",
    },
    tabPrimary: {
        fontSize: '1rem',
        color: colors.primary,
        fontWeight: "normal",
    }
})

function GenTabs({ data, baseUrl }: GenTabsProsp) {
    const { pathname } = useLocation();
    const pathTab = pathname.split("/")[4]
    const position = pathTab ? data.findIndex(info => info.to === pathTab) : 0
    const classes = tabsStyle()
    return (
        <StyledTabs
            value={position}
            onChange={() => { }}
            aria-label="user tabs"
        >
            {data.map((info, index) => <FormattedMessage id={info.id} key={info.id}>{text =>
                <Tab component={Link} label={text} to={`${baseUrl}/${info.to}`} className={index === position ? classes.tabPrimary : classes.tab} />}</FormattedMessage>)}
        </StyledTabs>
    );
}

function GenInvoiceTabs({ data, baseUrl }: GenTabsProsp) {
    const { pathname } = useLocation();
    const pathTab = pathname.split("/")[3]
    const position = pathTab ? data.findIndex(info => info.to === pathTab) : 0
    const classes = tabsStyle()
    return (
        <StyledTabs
            value={position}
            onChange={() => { }}
            aria-label="user tabs"
        >
            {data.map((info, index) => <FormattedMessage id={info.id} key={info.id}>{text =>
                <Tab component={Link} label={text} to={`${baseUrl}/${info.to}`} className={index === position ? classes.tabPrimary : classes.tab} />}</FormattedMessage>)}
        </StyledTabs>
    );
}

interface ChangePwdProp {
    isOpen: boolean
    onSelectClose: () => void
}


const ChangePasswordDialog = (prop: ChangePwdProp) => {
    const [values, setValues] = React.useState<{ oldPwd: string, newPwd: string, comfirm: string, isLoading: boolean }>({ oldPwd: '', newPwd: '', comfirm: '', isLoading: false, })

    const [errorPwd, setErrorPwd] = React.useState(false)
    const [errorNewPwd, setErrorNewPwd] = React.useState(false)
    const [errorConfirmPwd, setErrorConfirmPwd] = React.useState(false)
    const { handler } = useProject()
    const classes = makeStyles({
        diglogTitleBg: {
            color: '#ffffff',
            backgroundColor: '#262834',
            // height: theme.spacing(4),
            paddingTop: 8,
            paddingBottom: 8,
        },

        textFont: {
            fontSize: 16,
            color: colors.textSeconday,
        },
        textFeild: {
            fontSize: 16,
            color: colors.textSeconday,
            height: 50,
        },
        loadingBg: {
            position: 'relative',
        },

        loading: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12,
        },

        saveBtn: {
            fontSize: 16,
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 35,
            paddingRight: 35,
            borderRadius: 15,
            marginTop: 10,
            marginBottom: 10
        },

        changePwdTitle: {
            fontSize: 18,
            color: 'white',
            fontWeight: 600
        },
        changeCancelBtn: {
            padding: 0,
            '& :hover': {
                cursor: 'pointer'
            }
        },
    })()

    const handleOldpwdOnchange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, oldPwd: event.target.value })
    }
    const handleNewpwdOnchange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, newPwd: event.target.value })
    }
    const handleComfirmpwdOnchange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, comfirm: event.target.value })
    }


    const canSave = () => {
        let getError = false
        console.log(values.oldPwd)
        console.log(values.newPwd)

        if (values.oldPwd.length === 0 || values.oldPwd.length < CommonLength.passwordMinLength || values.oldPwd.length > CommonLength.passwordMaxLength) {
            getError = true
            setErrorPwd(true)
        }

        if (values.newPwd.length === 0 || values.newPwd.length < CommonLength.passwordMinLength || values.newPwd.length > CommonLength.passwordMaxLength) {
            getError = true
            setErrorNewPwd(true)
        }

        if (values.newPwd !== values.comfirm) {
            getError = true
            setErrorConfirmPwd(true)
        }
        return !getError
    }

    const checkOld = () => {
        if (values.oldPwd.length === 0) {
            return <FormattedMessage id='prompt_fill_in_pass' />
        } else if (values.oldPwd.length < 8) {
            return <FormattedMessage id='prompt_pass_length_short' />
        } else {
            return <FormattedMessage id='prompt_errorMessage_PasswordOverLenth' values={{ number0: CommonLength.passwordMaxLength }} />
        }
    }
    const checkNew = () => {
        if (values.newPwd.length === 0) {
            return <FormattedMessage id='prompt_fill_in_pass' />
        } else if (values.newPwd.length < 8) {
            return <FormattedMessage id='prompt_pass_length_short' />
        } else {
            return <FormattedMessage id='prompt_errorMessage_PasswordOverLenth' values={{ number0: CommonLength.passwordMaxLength }} />
        }
    }

    const onClickSave = () => {
        if (canSave()) {
            setValues({ ...values, isLoading: true })
            ApiUtil.changePassword({ newPassword: values.newPwd, oldPassword: values.oldPwd }).then(
                () => {
                    setValues({ ...values, isLoading: false })
                    handler(ActionFactory.success("prompt_update_success"))
                    prop.onSelectClose()
                }
            ).catch(
                err => {
                    setValues({ ...values, isLoading: false })
                    handler(ActionFactory.error(err))
                }
            )
        }
    }

    return (
        <>
            <Dialog fullWidth maxWidth='sm' open={prop.isOpen} onClose={prop.onSelectClose} aria-labelledby="customized-dialog-ChangePassword">
                <DialogTitle id="customized-dialog-title" className={classes.diglogTitleBg}>

                    <Grid container justify='space-between' alignItems='center'>
                        <Grid item>
                            <FormattedMessage id="action_update_pass" />
                        </Grid>
                        <Grid item>
                            <IconButton onClick={prop.onSelectClose} className={classes.changeCancelBtn} ><ClearIcon fontSize='large' style={{ color: 'white' }} /></IconButton>
                        </Grid>
                    </Grid>

                </DialogTitle>

                <DialogContent>

                    <Grid container direction="column" justify="space-between" spacing={3} style={{ marginTop: 0, marginBottom: 0, paddingTop: 8 }}>
                        <Grid item>
                            <Grid container direction="row" justify="space-between" alignItems="center">
                                <Grid item xs={4} sm={4} md={4} lg={4}>
                                    <Typography noWrap className={classes.textFont} ><FormattedMessage id='prompt_old_pass'></FormattedMessage><span style={{ color: 'red' }}>*</span> </Typography>
                                </Grid>
                                <Grid item xs={8} sm={8} md={8} lg={8}>
                                    <TextField fullWidth variant="outlined" type='password' autoComplete="new-password"
                                        size="small"
                                        onFocus={() => setErrorPwd(false)}
                                        error={errorPwd}
                                        helperText={errorPwd ? checkOld() : null}
                                        onChange={handleOldpwdOnchange}
                                    ></TextField>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item >
                            <Grid container direction="row" justify="space-between" alignItems="center">
                                <Grid item xs={4} sm={4} md={4} lg={4}>
                                    <Typography noWrap className={classes.textFont} ><FormattedMessage id='prompt_new_pass'></FormattedMessage><span style={{ color: 'red' }}>*</span> </Typography>
                                </Grid>
                                <Grid item xs={8} sm={8} md={8} lg={8}>
                                    <TextField fullWidth variant="outlined" type='password'
                                        size="small"
                                        onFocus={() => setErrorNewPwd(false)}
                                        error={errorNewPwd}
                                        helperText={errorNewPwd ? checkNew() : null}
                                        onChange={handleNewpwdOnchange}
                                    ></TextField>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item >

                            <Grid container direction="row" justify="space-between" alignItems="center">
                                <Grid item xs={4} sm={4} md={4} lg={4} >
                                    <Typography noWrap className={classes.textFont} ><FormattedMessage id='prompt_confirm_pass'></FormattedMessage><span style={{ color: 'red' }}>*</span> </Typography>
                                </Grid>
                                <Grid item xs={8} sm={8} md={8} lg={8}>
                                    <TextField fullWidth variant="outlined" type='password'
                                        size="small"
                                        onFocus={() => setErrorConfirmPwd(false)}
                                        error={errorConfirmPwd}
                                        helperText={errorConfirmPwd ? <FormattedMessage id='prompt_pass_mismatch_repass' /> : null}
                                        onChange={handleComfirmpwdOnchange}
                                    ></TextField>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <CornerGradientButton variant="contained" color='primary' disabled={values.isLoading} className={classes.saveBtn} onClick={onClickSave}><FormattedMessage id="action_save" /></CornerGradientButton>

                </DialogActions>
            </Dialog>
        </>
    )
}

export const ChangePasswordComponent = React.memo(ChangePasswordDialog)

interface VerifitProp {
    isOpen: boolean
    onSelectClose: () => void
}

const VerificationWin = (props: VerifitProp) => {
    const useStyle = makeStyles((theme: Theme) =>
        createStyles({

            doalogTitle: {
                backgroundColor: "#262834",
                fontSize: 18,
                fontWeight: "normal",
                color: "white",
            },
            sendButton: {
                fontWeight: 500,
                fontSize: 16,
                width: "100%",
                height: 40
            },
            saveAction: {
                position: "relative",
                right: 4,
                minWidth: 0,
                fontSize: 16,
            },
            phoneErrorMessave: {
                position: "absolute",
                color: "#f44336",
                fontSize: "0.75rem",
                marginTop: 4,
                paddingLeft: 13,
            },
            verificationGrid: {
                marginRight: 3,
                marginTop: 25,
                marginBottom: 30,
            },
            verificationCodeError: {
                position: "absolute",
            },
            chooseContent: {
                "& :hover": {
                    cursor: "pointer",
                },
            },
            inputInfo: {
                width: "95%",
                "& > div": {
                    height: 40,
                },
                "& > * > input": {
                    padding: "10px 14px",
                    width: 500,
                },
            },
            verification: {
                "& > div": {
                    height: 40,
                },
                "& > * > input": {
                    padding: "10px 14px",
                    width: "100%",
                },
            },
        })
    );
    const { handler } = useProject();
    const { isOpen, onSelectClose } = props;
    const classes = useStyle();
    const userprofile = StoreUtil.user();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [sendTime, setSendTime] = React.useState<number>(60);
    const [verificationCode, setVerificationCode] = React.useState<string>("");
    const [updateVerificationStatus, setUpdateVerificationStatus] = React.useState<
        boolean
    >(false);
    const [updatePhoneStatus, setPhoneUpdateInputStatus] = React.useState<boolean>(
        false
    );
    const [sendLoading, setSendLoading] = React.useState<boolean>(false);
    const [phoneNo, setPhoneNo] = React.useState<string>(userprofile?.phone || "");
    const [countryCode, setCountryCode] = React.useState<CountryType | undefined>();
    const closePhoneUpdateWin = () => {
        setPhoneUpdateInputStatus(false);
        setUpdateVerificationStatus(false);
    };

    const phoneIfTrue = () => {
        return isPossibleNumber(`+${countryCode?.phone || ""}${phoneNo}`
        );
    };
    const sendClick = () => {
        if (!phoneIfTrue()) {
            return;
        }
        setSendLoading(true)
        ApiUtil.getUpdatePhoneVerifyCode(countryCode?.phone + phoneNo
        )
            .then(() => {
                if (sendTime < 60) {
                    return;
                }
                let timer = sendTime + 1;
                let interval = setInterval(() => {
                    if (timer === 1) {
                        setSendLoading(false)
                        clearInterval(interval);
                        setSendTime(60);
                    } else {
                        timer -= 1;
                        setSendTime(timer - 1);
                    }
                }, 1000);
            })
            .catch((error) => {
                setSendLoading(false);
                handler(ActionFactory.error(error));
            });
    };
    const commitUserNewPhone = () => {
        if (!phoneIfTrue() || verificationCode.length === 0) {
            return;
        }
        setLoading(true)
        ApiUtil.updateUserPhone({
            newPhone: countryCode?.phone + phoneNo,
            vcode: verificationCode,
        })
            .then(() => {
                StoreUtil.updateUserVerifyPhoneStatus(true);
                onSelectClose()
                setLoading(false)
                handler(ActionFactory.success("prompt_verifition_successed"))
            })
            .catch((error) => {
                handler(ActionFactory.error(error));
                setLoading(false)
            });
    };
    return (
        <Dialog
            fullWidth
            maxWidth="sm"
            open={isOpen}
            onClose={() => {
                closePhoneUpdateWin();
                onSelectClose();
                setVerificationCode("");
            }}
        >
            <DialogTitle className={classes.doalogTitle}>
                <Grid
                    container
                    justify="space-between"
                    alignItems="center"
                    style={{ lineHeight: 1 }}
                >
                    <Grid item>
                        <FormattedMessage id="action_verifition" />
                    </Grid>
                    <Grid item>
                        <div className={classes.chooseContent}>
                            <ClearIcon
                                fontSize="large"
                                onClick={() => {
                                    onSelectClose();
                                    setVerificationCode("");
                                    closePhoneUpdateWin();
                                }}
                            />
                        </div>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid
                    container
                    alignItems="center"
                    style={{ marginRight: 3, marginTop: 25 }}
                >
                    <Grid item lg={3} md={3} xs={3}>
                        <FormattedMessage id="prompt_phone_number" />
                    </Grid>
                    <Grid item lg={7} md={7} xs={7}>
                        <Grid container alignItems="center" spacing={1}>
                            <Grid item xs={6} md={6} lg={5}>
                                <CountryPhoneWidget
                                    onChange={setCountryCode}
                                    code={countryCode}
                                />
                            </Grid>
                            <Grid item xs={6} md={6} lg={7}>
                                <TextField
                                    id="outlined-secondary"
                                    variant="outlined"
                                    type="text"
                                    autoComplete="off"
                                    error={!phoneIfTrue() && updateVerificationStatus}
                                    className={classes.inputInfo}
                                    value={phoneNo}
                                    fullWidth
                                    onChange={(event) => setPhoneNo(event.target.value)}
                                    style={{ maxWidth: 300 }}
                                    InputProps={{
                                        startAdornment: `+${countryCode?.phone || ""}`,
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container style={{ position: "relative" }}>
                            {!phoneIfTrue() && updateVerificationStatus ? (
                                <div className={classes.phoneErrorMessave}>
                                    <FormattedMessage id="prompt_phone_format" />
                                </div>
                            ) : null}
                        </Grid>
                    </Grid>
                    <Grid item lg={2} md={2} xs={2}>
                        <Button
                            variant="outlined"
                            color="primary"
                            className={classes.sendButton}
                            disabled={sendLoading}
                            onClick={() => {
                                sendClick();
                                setUpdateVerificationStatus(true);
                            }}
                        >
                            {sendTime === 60 ? (
                                <FormattedMessage id="prompt_login_send" />
                            ) : (
                                    sendTime
                                )}
                        </Button>
                    </Grid>
                </Grid>
                <Grid
                    container
                    alignItems="center"
                    className={classes.verificationGrid}
                >
                    <Grid item lg={3} md={3} xs={3}>
                        <FormattedMessage id="prompt_verification_code" />
                    </Grid>
                    <Grid item lg={9} md={9} xs={9}>
                        <TextField
                            style={{ position: "relative" }}
                            id="outlined-secondary"
                            variant="outlined"
                            autoComplete="off"
                            type="text"
                            error={verificationCode.length === 0 && updatePhoneStatus}
                            helperText={
                                verificationCode.length === 0 && updatePhoneStatus ? (
                                    <div className={classes.verificationCodeError}>
                                        <FormattedMessage id="prompt_fill_in_email_code" />
                                    </div>
                                ) : null
                            }
                            fullWidth
                            value={verificationCode}
                            onChange={(event) => setVerificationCode(event.target.value)}
                            className={classes.verification}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={loading}
                    className={classes.saveAction}
                    variant="text"
                    color="primary"
                    onClick={() => {
                        commitUserNewPhone();
                        setUpdateVerificationStatus(true);
                        setPhoneUpdateInputStatus(true);
                    }}
                    style={{ margin: "0 8px 5px 0" }}
                >
                    <FormattedMessage id="action_save" />
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export const VerificationWinComponent = React.memo(VerificationWin)


