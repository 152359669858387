import React, { useEffect } from 'react'
import { Typography, makeStyles, createStyles, TextField, Button, Container, Grid, CircularProgress, } from '@material-ui/core'
import { colors } from '../../../common/theme'
import { FormattedMessage } from 'react-intl'
import { ApiUtil } from '../../../common/api/api';
import { InForgotPass, InForgotPassByPhone } from '../../../common/api/model'
import { useLoginContext } from '..';
import { PorjectError } from '../../../common/api/errorMessage'
import { emailVerifyFormatte, isVailLoginPhone } from '../../../common/util'
import { CommonLength } from '../../../common/util'
const useStyle = makeStyles(() => createStyles({

    root: {
        flexGrow: 1,
        width: 496,
        backgroundColor: 'white',
    },

    getStartText: {
        fontSize: 24,
        fontWeight: 500,
        color: colors.menuColor
    },
    emailText: {
        fontSize: 16,
        color: colors.textSeconday
    },

    textField: {
        fontSize: 16,
        color: colors.textSeconday,
        '& .MuiInputBase-input': {
            boxShadow: '0 0 0px 1000px white inset !important'
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: colors.divider,
            }
        },
        '& .MuiFormLabel-root': {
            color: '#b0b3ba'
        }
    },
    contentText: {
        fontSize: 16,
        color: colors.textSeconday,
    },

    submitAction: {
        fontSize: 16,
        borderRadius: 4,
        width: 132
    },
    sendAction: {
        fontSize: 18,
        // fontWeight: 'bold',
        borderRadius: 4,
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 20,
        paddingRight: 20,
    },
    loadingBg: {
        position: 'relative',
    },
    loading: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}))

interface State {
    mail: string,
    password: string,
    confirmPassword: string,
    verifyCode: string,
    isLoading: boolean,
}

type Langs = 'en' | 'ko';

function localLang(): Langs {
    const language = navigator.language.split(/[-_]/)[0];
    switch (language) {
        case 'en': return "en";
        case 'ko': return "ko";
        default: return "en";
    }
}
export function ForgotPass() {
    const cls = useStyle()
    const { state, method } = useLoginContext()
    // const { goBack } = useHistory()
    // const { formatMessage } = useIntl()
    const unknownMesg = "net_code_unknown"
    const [num, setNum] = React.useState(0)
    const [isSend, setIsSend] = React.useState(false)
    const [values, setValues] = React.useState<State>({
        mail: '',
        password: '',
        confirmPassword: '',
        isLoading: false,
        verifyCode: '',
    });

    const [errorEmail, setErrorEmail] = React.useState(false)
    const [errorCode, setErrorCode] = React.useState(false)
    const [errorPwd, setErrorPwd] = React.useState(false)
    const [errorCpwd, setErrorCpwd] = React.useState(false)
    // const isEmailLogin = values.mail.includes("@");
    const handleEmailOnchange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, mail: event.target.value })
    }

    const handleVerifyCodeOnchange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, verifyCode: event.target.value })
    }
    const handleNewPwdOnchange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, password: event.target.value })
    }

    const handleCorimPwdOnchange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, confirmPassword: event.target.value })
    }

    const setMethod = (value: string | boolean) => {
        if (typeof value === 'string') {
            method({ type: 'errorHint', payload: { ...state, errorMesg: value, isOpen: true } })
        } else {
            method({ type: 'userLogin', payload: { ...state, isCanLogin: value } })
        }
    }

    const sendVerification = () => {
        if (canSendCode()) {
            setNum(60)
            sendEmailCode()
            // isEmailLogin ?  : sendPhoneCode();
        }
    }

    const sendEmailCode = () => {
        const local = localLang()
        ApiUtil.getForgortMailVerifyCode(values.mail, local).then(
            () => { setIsSend(true) }
        ).catch(
            err => {
                setNum(0)
                setValues({ ...values, isLoading: false })
                let mesg = PorjectError.get(err.code) || unknownMesg
                setMethod(mesg)
            }
        )
    };

    const sendPhoneCode = () => {
        ApiUtil.getForgortPhoneVerifyCode(values.mail)
            .then(() => {
                setIsSend(true);
            })
            .catch((err) => {
                setNum(0)
                setValues({ ...values, isLoading: false });
                let mesg = PorjectError.get(err.code) || unknownMesg;
                setMethod(mesg)
            });
    };
    const canSendCode = () => {
        if (values.mail.length !== 0) {
            if (!emailVerifyFormatte(values.mail)) {
                setErrorEmail(true);
                return false;
            }
            // if (isEmailLogin) {
                
            // } else {
            //     if (!isVailLoginPhone(values.mail)) {
            //         setErrorEmail(true);
            //         return false;
            //     }
            // }
        } else {
            setErrorEmail(true);
            return false;
        }
        if (num !== 0) {
            return false
        }
        return true;
    };

    useEffect(() => {
        let timer = 0;
        if (isSend && num !== 0) {
            timer = setInterval(() => {
                setNum(n => {
                    if (n === 1) {
                        setIsSend(false)
                        clearInterval(timer)
                    }
                    return n - 1
                });
            }, 1000)
        }
        return () => {
            clearInterval(timer)
        };
    }, [isSend])


    const canSave = () => {
        let getError = false
        if (values.mail.length !== 0) {
            if (!emailVerifyFormatte(values.mail)) {
                getError = true;
                setErrorEmail(true);
            }
            // if (isEmailLogin) {
                
            // } else {
            //     if (!isVailLoginPhone(values.mail)) {
            //         getError = true;
            //         setErrorEmail(true);
            //     }
            // }
        } else {
            getError = true;
            setErrorEmail(true);
        }

        if (values.verifyCode.length === 0) {
            getError = true;
            setErrorCode(true);
        }

        if (values.password.length === 0 || values.password.length < CommonLength.passwordMinLength || values.password.length > CommonLength.passwordMaxLength) {
            getError = true
            setErrorPwd(true)
        }

        if (values.password !== values.confirmPassword) {
            getError = true
            setErrorCpwd(true)
        }
        return !getError
    }

    const checkEmail = () => {
        if (values.mail.length === 0) {
            return <FormattedMessage id="prompt_fill_in_email_phone" />;
        } else {
            if (!emailVerifyFormatte(values.mail)) {
                return <FormattedMessage id="prompt_error_format_email" />;
            }
            // if (isEmailLogin) {
               
            // } else {
            //     if (!isVailLoginPhone(values.mail)) {
            //         return <FormattedMessage id="prompt_error_format_phone" />;
            //     }
            // }
        }
    };

    const checkLN = () => {
        if (values.password.length === 0) {
            return <FormattedMessage id='prompt_fill_in_pass' />
        } else if (values.password.length < 8) {
            return <FormattedMessage id='prompt_pass_length_short' />
        } else {
            return <FormattedMessage id='prompt_errorMessage_PasswordOverLenth' values={{ number0: CommonLength.passwordMaxLength }} />
        }
    }

    const handleSave = () => {
        if (canSave()) {
            setValues({ ...values, isLoading: true })
            saveEmailAPI()
            // isEmailLogin ?  : savePhoneAPI()
        }
    }

    const saveEmailAPI = () => {
        const model: InForgotPass = { newPassword: values.password, mail: values.mail, verifyCode: values.verifyCode }
        ApiUtil.forgotPassword(model).then(
            () => {
                setMethod(true)
            }
        ).catch(
            err => {
                setValues({ ...values, isLoading: false })
                let mesg = PorjectError.get(err.code) || unknownMesg
                setMethod(mesg)
            }
        )
    }

    const savePhoneAPI = () => {
        const model: InForgotPassByPhone = { newPassword: values.password, phone: values.mail, verifyCode: values.verifyCode }
        ApiUtil.forgotPasswordByPhone(model).then(
            () => {
                setMethod(true)
            }
        ).catch(
            err => {
                setValues({ ...values, isLoading: false })
                let mesg = PorjectError.get(err.code) || unknownMesg
                setMethod(mesg)
            }
        )
    }

    return (

        <Container className={cls.root}>
            <Grid container direction="column" justify="center" alignItems="stretch">
                <Grid item>
                    <Typography className={cls.getStartText}><FormattedMessage id="prompt_forgot_password" /></Typography>
                </Grid>

                <Grid item style={{ marginTop: 40 }}>
                    <TextField className={cls.textField} id="emailtextfield" fullWidth
                        autoComplete='off'
                        variant='outlined'
                        label={<FormattedMessage id="prompt_email"></FormattedMessage>}
                        onFocus={() => setErrorEmail(false)}
                        error={errorEmail}
                        helperText={errorEmail ? checkEmail() : null}
                        onChange={handleEmailOnchange} />
                </Grid>

                <Grid item style={{ marginTop: 24 }} >
                    <TextField id="Verification" name="Verification" className={cls.textField}
                        onFocus={() => setErrorCode(false)}
                        fullWidth
                        autoComplete="new-password"
                        label={<FormattedMessage id="prompt_verification_code"></FormattedMessage>}
                        variant='outlined'
                        error={errorCode}
                        // type="number"
                        type="text"
                        helperText={errorCode ? <FormattedMessage id='prompt_fill_in_email_code' /> : null}
                        onChange={handleVerifyCodeOnchange}
                        InputProps={{
                            endAdornment: (
                                <Button type="button" className={cls.sendAction} color='primary' variant='contained' onClick={sendVerification} >{num !== 0 ? `${num}s` : <FormattedMessage id="prompt_login_send" />}</Button>
                            ),
                        }}
                    ></TextField>

                </Grid>
                <Grid style={{ marginTop: 24 }} >
                    <TextField fullWidth id="newpassword" name="newpassword" className={cls.textField}
                        autoComplete="new-password"
                        variant='outlined'
                        label={<FormattedMessage id="prompt_new_password"></FormattedMessage>}
                        onFocus={() => setErrorPwd(false)}
                        error={errorPwd}
                        helperText={errorPwd ? checkLN() : null}
                        type='password'
                        onChange={handleNewPwdOnchange}
                    ></TextField>
                </Grid>

                <Grid style={{ marginTop: 24 }} >
                    <TextField fullWidth id="confirmPassword" name="confirmPassword" className={cls.textField}
                        autoComplete='off'
                        variant='outlined'
                        label={<FormattedMessage id="prompt_confirm_pass"></FormattedMessage>}
                        onFocus={() => setErrorCpwd(false)}
                        error={errorCpwd}
                        helperText={errorCpwd ? <FormattedMessage id='prompt_pass_mismatch_repass' /> : null}
                        type='password'
                        onChange={handleCorimPwdOnchange}
                    ></TextField>
                </Grid>
                <Grid container style={{ marginTop: 50 }} justify="flex-end" className={cls.loadingBg}>
                    <Button variant="contained" disabled={values.isLoading} color='primary' type="button" className={cls.submitAction} onClick={handleSave}><FormattedMessage id="action_save" /></Button>
                    {values.isLoading && <CircularProgress size={35} className={cls.loading} />}
                </Grid>
            </Grid>
        </Container>
    )
}