import React from 'react';
import { Typography, makeStyles, createStyles, Divider, Button, Grid, Paper, Radio, FormControlLabel } from '@material-ui/core'
import { colors } from '../../../../common/theme'
import { FormattedMessage } from 'react-intl'
import { ApiUtil } from '../../../../common/api/api'
import { ApplicationWorkDetail, Substation, CreateApplication, Attachment } from '../../../../common/api/model'
import { StoreUtil } from '../../../../common/store'
import { Link, Redirect } from 'react-router-dom';
import { Loading } from '../../../../common/component'
import { useProject, ActionFactory } from '../../../../common/component/provider'
import { CommonLength } from '../../../../common/util'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { InputInfoComponent, WorkDetailTableComponent, InputSelectComponent, WorkTypeListComponet, WorkDateComponet, UploadDocComponent, InputWorkTypeComponent, DocThumbnailComponent } from '../component'
const useStyle = makeStyles((theme) => createStyles({
    root: {
        flexGrow: 1,
    },

    titleStyle: {
        paddingLeft: theme.spacing(9),
        paddingRight: theme.spacing(9),
        height: 64
    },

    titleText: {
        fontSize: 20,
        fontWeight: 500,
        color: colors.menuColor
    },
    subTitle: {
        fontSize: 18,
        paddingTop: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        color: colors.menuColor,
        fontWeight: 500
    },
    thirdTitle: {
        fontSize: 18,
        color: colors.menuColor,
        fontWeight: 500
    },

    GridStyle: {
        padding: 12,
        margin: 0,
        width: '100%'
    },

    topGridStyle: {
        paddingTop: 24,
        paddingLeft: 24,
        paddingBottom: 0,
        paddingRight: 24,
        margin: 0,
        // width: '100%'
    },

    conentStyle: {
        paddingLeft: theme.spacing(9),
        paddingRight: theme.spacing(9),
    },
    topStyle: {
        marginTop: theme.spacing(3)
    },

    submitAction: {
        fontSize: 16,
        borderRadius: 4,
        fontWeight: 500,
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 2,
        paddingBottom: 2
    },

    textField: {
        fontSize: 16,
        color: colors.textSeconday,
        '& .MuiInputBase-input': {
            boxShadow: '0 0 0px 1000px white inset !important'
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: colors.divider,
            }
        },
        '& .MuiFormLabel-root': {
            color: '#b0b3ba'
        }
    },

    textStyle: {
        fontSize: 16,
        color: colors.textSeconday
    },


    contentText: {
        fontSize: 16,
        color: colors.textSeconday,
    },

}))




interface AddWorkDetailProp {
    substation: string | null
    workType: string | null
    workData: string[]
}

export function CreateNewApplication() {
    const cls = useStyle()
    const [isRedirect, setIsRedirect] = React.useState(false)
    const { store, handler } = useProject()
    const userName = StoreUtil.username()
    const isVendor = StoreUtil.user()?.isVendorUser === true
    const userEmail = StoreUtil.userEmail()!
    const companyName = StoreUtil.usercompany()!
    const [isLoading, setIsLoading] = React.useState(false)
    const [queryIndex, setQueryIndex] = React.useState(0)
    const [subData, setSubData] = React.useState<Substation[]>([])
    const [workTypeData, setworkTypeData] = React.useState<string[]>([])

    const [errorSub, setErrorSub] = React.useState(false)
    const [errorWorkType, setErrorWorkType] = React.useState(false)
    const [errorDate, setErrorDate] = React.useState<[boolean, boolean]>([false, false])

    const [model, setModel] = React.useState<CreateApplication>({
        companyName: companyName,
        hpNo: '',
        icNo: '',
        poNo: '',
        workList: [],
        attachmentList: [],
    })

    const [addWork, setAddWork] = React.useState<AddWorkDetailProp>({
        substation: null,
        workType: null,
        workData: [],
    })

    const [inputValue, setInputValue] = React.useState<{ a: string | null, b: string }>({
        a: null,
        b: ''
    })

    const [workDate, setWorkDate] = React.useState<{ begin: MaterialUiPickersDate | null, end: MaterialUiPickersDate | null }>({
        begin: null,
        end: null
    })

    React.useEffect(() => {
        ApiUtil.querySubstations().then(d => {
            setSubData(d.list)
        }).catch(e => handler(ActionFactory.error(e)))

        ApiUtil.queryWorkType().then(d => {
            let x = d.map(row => row.workTypeName)
            setworkTypeData(x)
        }).catch(e => handler(ActionFactory.error(e)))

    }, [queryIndex, store.reload])

    const onClickSubmit = () => {
        if (canSubmit()) {
            setIsLoading(true)
            console.log(model)
            ApiUtil.createApplication(model).then(d => {
                setIsRedirect(true)
            }).catch(e => handler(ActionFactory.error(e))).finally(hiddenLoading)
        }

    }
    const canSubmit = () => {
        // todo
        if (model.companyName.length === 0) {
            return false
        }
        if (model.workList.length === 0) {
            handler(ActionFactory.error({ code: 500000, message: "prompt_addworkDetail" }))
            return false
        }
        return true
    }

    const canAddWork = () => {
        let isError = true
        if (addWork.substation === null || addWork.substation.length === 0) {
            isError = false
            setErrorSub(true)
        }
        if (addWork.workData.length === 0) {
            isError = false
            setErrorWorkType(true)
        }
        if (workDate.begin === null) {
            isError = false
            setErrorDate(pre => { return [true, pre[1]] })
        }
        if (workDate.end === null) {
            isError = false
            setErrorDate(pre => { return [pre[0], true] })
        }
        return isError
    }

    const onClickAddWork = () => {
        if (canAddWork()) {
            let sub = subData.filter(row => row.substationName === addWork.substation)[0]
            let wk = { substationId: sub.id, substationName: sub.substationName, beginTime: workDate.begin!.unix(), endTime: workDate.end!.unix(), typeOfWorks: addWork.workData }
            console.log(wk)
            setModel(prev => { return { ...prev, works: prev.workList.unshift(wk) } })
            clearAddWorkDetail()
        }
    }
    const clearAddWorkDetail = () => {
        setAddWork({ substation: null, workData: [], workType: null, })
        setInputValue({ a: null, b: '' })
        setWorkDate({ begin: null, end: null })
    }

    const onClickDeleteWorkType = (text: string) => {
        setAddWork(prev => { return { ...prev, workData: prev.workData.filter(row => row !== text) } })
    }
    const onClickSaveWorkType = () => {
        if (addWork.workData.length >= 5) {
            handler(ActionFactory.error({ code: 500000, message: "prompt_create_application_addwork" }))
        } else if (addWork.workType !== null && addWork.workType!.length !== 0 && !addWork.workData.includes(addWork.workType!)) {
            setAddWork(prev => { return { ...prev, workData: [...prev.workData, prev.workType!] } })
        }
        setInputValue({ a: null, b: '' })
    }
    const onClickWorkDetail = (index: number, status: 1 | 2) => {
        setModel(prev => { return { ...prev, works: prev.workList.splice(index, 1) } })
    }
    const onDropAccepted = (files: File[]) => {
        if (model.attachmentList!.length >= CommonLength.uploadUrlMax) {
            handler(ActionFactory.errorMessage('prompt_fileTooMore'))
        } else {
            // if (count) {
            //     handler(ActionFactory.errorMessage('prompt_fileTooMore'))
            // }
            setIsLoading(true)
            const data = model.attachmentList || []
            let count = 3 - data.length
            for (let index = 0; index < files.length; index++) {
                if (index === count) {
                    break
                }
                const item = files[index]
                ApiUtil.uploadCloudaryFile(item).then(r => {
                    let md: Attachment = { name: r.original_filename, url: r.secure_url, deleteToken: r.delete_token }
                    data.unshift(md)
                    setModel(pre => { return { ...pre, attachmentList: data } })
                    setIsLoading(false)
                }).catch(error => {
                    setIsLoading(false)
                    handler(ActionFactory.error({ code: 500000, message: "prompt_upload_failed" }))
                })
            }
        }
    }

    const onDeleteFile = (text: string) => {
        setIsLoading(true)
        const x = model.attachmentList!.filter(item => item.url === text)[0]
        const y = model.attachmentList!.filter(item => item.url !== text)
        ApiUtil.deleteCloudaryFile(x.deleteToken).then(r => {
            console.log(y)
            setModel(pre => { return { ...pre, attachmentList: y } })
            setIsLoading(false)
        }).catch(error => {
            setIsLoading(false)
            handler(ActionFactory.error({ code: 500000, message: "prompt_file_deleteFail" }))
        })
    }

    const hiddenLoading = () => { setIsLoading(false) }
    if (isRedirect) {
        return (<Redirect to='/home'></Redirect>)
    }
    return (
        <div className={cls.root}>
            <div >
                <Grid container justify="space-between" alignItems="center" className={cls.titleStyle}>
                    <Grid item>
                        <Typography className={cls.titleText}><FormattedMessage id='prompt_create_application'></FormattedMessage></Typography>
                    </Grid>
                    <Grid item>
                        <Grid container spacing={2}>
                            <Grid item>
                                <Button variant='outlined' className={cls.submitAction} color='primary' onClick={() => { setIsRedirect(true) }}><FormattedMessage id="prompt_back"></FormattedMessage></Button>
                            </Grid>
                            <Grid item>
                                <Button variant='contained' className={cls.submitAction} color='primary' onClick={onClickSubmit}><FormattedMessage id="action_save"></FormattedMessage></Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            <Divider variant='fullWidth'></Divider>
            <div className={cls.conentStyle}>
                <Paper elevation={3} className={cls.topStyle} >
                    <Grid container justify="space-between" alignItems="center" >
                        <Grid item>
                            <Typography className={cls.subTitle}><FormattedMessage id='prompt_create_application_detail'></FormattedMessage></Typography>
                        </Grid>
                        <Grid item>
                            <Grid container spacing={2}>
                                <Grid item>
                                    <FormControlLabel value="TNB staff" disabled control={<Radio checked = {!isVendor}/>} label={<FormattedMessage id="prompt_tnbstaff"></FormattedMessage>} />
                                </Grid>
                                <Grid item>
                                    <FormControlLabel value="TNB vendor" disabled control={<Radio checked = {isVendor} />} label={<FormattedMessage id="prompt_tnbvender"></FormattedMessage>} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container justify="space-between" alignItems="center" spacing={3} className={cls.GridStyle}>
                        <Grid item xs={12} sm={5} md={5} lg={5}>
                            <InputInfoComponent label='prompt_create_application_name' defaultValue={userName} isDefault={true} isApprov={false}></InputInfoComponent>
                        </Grid>
                        <Grid item xs={12} sm={5} md={5} lg={5}>
                            <InputInfoComponent label='prompt_create_application_email' defaultValue={userEmail} isDefault={true} isApprov={false}></InputInfoComponent>
                        </Grid>
                        <Grid item xs={12} sm={5} md={5} lg={5} >
                            <InputInfoComponent label='prompt_create_application_po' onChange={text => { setModel({ ...model, poNo: text }) }} maxLength={50} isDefault={false} isApprov={false}></InputInfoComponent>
                        </Grid>
                        <Grid item xs={12} sm={5} md={5} lg={5}>
                            <InputInfoComponent label='prompt_create_application_hp' onChange={text => { setModel({ ...model, hpNo: text }) }} maxLength={50} isDefault={false} isApprov={false}></InputInfoComponent>
                        </Grid>
                        <Grid item xs={12} sm={5} md={5} lg={5}>
                            <InputInfoComponent label='prompt_create_application_ic' onChange={text => { setModel({ ...model, icNo: text }) }} maxLength={50} isDefault={false} isApprov={false}></InputInfoComponent>
                        </Grid>
                        <Grid item xs={12} sm={5} md={5} lg={5}>
                            <InputInfoComponent label='prompt_company_name' defaultValue={companyName} onChange={text => { setModel({ ...model, companyName: text }) }} maxLength={50} isDefault={false} isApprov={false}></InputInfoComponent>
                        </Grid>
                    </Grid>
                </Paper>

                <Paper elevation={3} className={cls.topStyle}>
                    <Grid container justify="space-between" alignItems="center" className={cls.topGridStyle}>
                        <Grid item>
                            <Typography className={cls.thirdTitle}><FormattedMessage id='prompt_create_application_Workdetail'></FormattedMessage></Typography>
                        </Grid>
                        <Grid item>
                            <Button variant='outlined' onClick={onClickAddWork} className={cls.submitAction} color='primary'><FormattedMessage id="prompt_create_application_addWorkdetail"></FormattedMessage></Button>
                        </Grid>
                    </Grid>

                    <Grid container justify="space-between" alignItems="flex-start" className={cls.GridStyle} spacing={3}>
                        <Grid item xs={12} sm={12} md={12} lg={5} >
                            <Grid container direction="column" alignItems="stretch" justify="flex-start" spacing={3} >
                                <Grid item>
                                    <InputSelectComponent isError={errorSub} onFocuError={() => { setErrorSub(false) }} data={subData.map(row => row.substationName)} onChange={text => { setAddWork({ ...addWork, substation: text }) }} defalutValue={addWork.substation}></InputSelectComponent>
                                </Grid>
                                <Grid item >
                                    <WorkDateComponet isError={errorDate} onFocuError={() => { setErrorDate([false, false]) }} time={workDate} onSave={time => { setWorkDate(time) }}></WorkDateComponet>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={5} md={5} lg={4} >
                            <InputWorkTypeComponent isError={errorWorkType} onFocuError={() => { setErrorWorkType(false) }} data={workTypeData} value={inputValue} onChange={text => { setInputValue({ ...inputValue, a: text }); setAddWork({ ...addWork, workType: text }) }} onInputChange={text => { setInputValue({ ...inputValue, b: text }); setAddWork({ ...addWork, workType: text }) }} onSave={onClickSaveWorkType}></InputWorkTypeComponent>
                        </Grid>
                        <Grid item xs={12} sm={5} md={5} lg={3} style={{ paddingLeft: 0 }}>
                            <WorkTypeListComponet data={addWork.workData} onClickDelete={onClickDeleteWorkType}></WorkTypeListComponet>
                        </Grid>
                    </Grid>
                    <Divider variant='fullWidth'></Divider>
                    <WorkDetailTableComponent status={1} soure={model.workList} onClickRow={onClickWorkDetail}></WorkDetailTableComponent>
                </Paper>

                <Paper elevation={3} className={cls.topStyle}>
                    <Typography className={cls.subTitle}><FormattedMessage id='prompt_create_application_documents'></FormattedMessage></Typography>
                    <Grid container alignItems="flex-start" className={cls.GridStyle} spacing={3}>
                        <Grid item >
                            <UploadDocComponent onDropAccepted={onDropAccepted} disabled={model.attachmentList!.length > CommonLength.uploadUrlMax} ></UploadDocComponent>
                        </Grid >
                        {
                            model.attachmentList!.map(row => {
                                return (<Grid key={model.attachmentList!.indexOf(row)} item style={{ paddingTop: 0, paddingBottom: 0 }}> <DocThumbnailComponent onDelete={onDeleteFile} isShow={true} url={row.url}></DocThumbnailComponent></Grid>)
                            })
                        }
                    </Grid>
                </Paper>
            </div>
            <Loading open={isLoading} />
            <div style={{ height: 24 }}></div>
        </div>
    )
}