import React from 'react'
import { Typography, makeStyles, createStyles, Button, Container, Grid, FormControlLabel, Checkbox, TextField } from '@material-ui/core'
import { colors } from '../../../common/theme'
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom'
import { ApiUtil } from '../../../common/api/api';
import { Login, LoginPhone } from '../../../common/api/model';
import { emailVerifyFormatte, isVailLoginPhone } from '../../../common/util'
import { PorjectError } from '../../../common/api/errorMessage';

import { useLoginContext } from '..';
// '& > * + * + *'
const useStyle = makeStyles((theme) => createStyles({
    root: {
        flexGrow: 1,
        backgroundColor: 'white',

        // [theme.breakpoints.up('md')]: {
        //     width: 496
        // },
        // [theme.breakpoints.down('md')]: {
        //     width: "80%"
        // },\
        width: 496

    },

    getStartText: {
        fontSize: 24,
        fontWeight: 500,
        color: colors.menuColor
    },
    emailText: {
        fontSize: 16,
        color: colors.textSeconday,


    },
    textField: {
        fontSize: 16,
        color: colors.textSeconday,
        '& .MuiInputBase-input': {
            boxShadow: '0 0 0px 1000px white inset !important'
        },
        // '& .MuiInput-underline:before': {
        //     height: 1,
        //     borderBottom: "solid 2px rgba(38, 40, 52, 0.1)"
        // },
        // '& .MuiInput-underline:after': {
        //     height: 1,
        //     borderBottom: "2px solid #ff6600"
        // },

        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: colors.divider,
            }
        },
        '& .MuiFormLabel-root': {
            color: '#b0b3ba'
        }
    },

    rememberText: {
        fontSize: 16,
        color: colors.textSeconday,
    },

    forgotAction: {
        fontSize: 16,
        color: '#f27a54',
        opacity: 0.5,
    },


    dialogAction: {
        margin: theme.spacing(6, 3, 0, 6),
        paddingLeft: 20,
        paddingRight: 20,
    },
    loadingBg: {
        position: 'relative',
    },
    loading: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    submitAction: {
        fontSize: 16,
        borderRadius: 4,
        width: 157
    },



}))



interface State {
    mail: string,
    password: string,
    showPassword: boolean,
    boxChecked: boolean,
    isOpen: boolean,
    isLoading: boolean,
    jumpTo: number,
}


export function UserLogin() {
    const cls = useStyle()
    // const { formatMessage } = useIntl()
    const unknownMesg = "net_code_unknown"
    const { state, method } = useLoginContext()
    const [errorEmail, setErrorEmail] = React.useState(false)
    const [errorPwd, setErrorPwd] = React.useState(false)
    const [values, setValues] = React.useState<State>({
        mail: '',
        password: '',
        showPassword: false,
        boxChecked: false,
        isOpen: false,
        isLoading: false,
        jumpTo: 0,  // 1 冻结 2 完善信息
    });
    // const isEmailLogin = values.mail.includes("@");

    const setMethod = (value: string | boolean) => {
        if (typeof value === 'string') {
            method({ type: 'errorHint', payload: { ...state, errorMesg: value, isOpen: true } })
        } else {
            method({ type: 'userLogin', payload: { ...state, isCanLogin: value } })
        }
    }
    const handleEmailOnchange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, mail: event.target.value })
    }

    const handlePasswordChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, [prop]: event.target.value })
    };

    const handleLogin = () => {
        if (canLogin()) {
            setValues({ ...values, isLoading: true })
            emailLoginAPI()
            // isEmailLogin ?  : phoneLoginAPI();
        }
    }
    const emailLoginAPI = () => {
        let info: Login = { "mail": values.mail, "password": values.password }
        ApiUtil.login(info).then(
            success => {
                setMethod(true)
                setValues({ ...values, isLoading: false })
            }
        ).catch(
            err => {
                let mesg = PorjectError.get(err.code) || unknownMesg
                setMethod(mesg)
                setValues({ ...values, isLoading: false })
            }
        )
    }

    const phoneLoginAPI = () => {
        let info: LoginPhone = { phone: values.mail, password: values.password };
        ApiUtil.loginPhone(info)
            .then((success) => {
                setMethod(true)
                setValues({ ...values, isLoading: false })
            })
            .catch((err) => {
                let mesg = PorjectError.get(err.code) || unknownMesg;
                setMethod(mesg);
                setValues({ ...values, isLoading: false });
            })
    }

    const canLogin = () => {
        let getError = false
        if (values.mail.length !== 0) {
            if (!emailVerifyFormatte(values.mail)) {
                getError = true;
                setErrorEmail(true);
            }
            // if (isEmailLogin) {
                
            // } else {
            //     if (!isVailLoginPhone(values.mail)) {
            //         getError = true;
            //         setErrorEmail(true);
            //     }
            // }
        } else {
            getError = true;
            setErrorEmail(true);
        }

        if (values.password.length === 0) {
            getError = true;
            setErrorPwd(true);
        }
        return !getError
    }

    const checkEmail = () => {
        if (values.mail.length === 0) {
            return <FormattedMessage id='prompt_fill_in_email' />
        } else {
            return <FormattedMessage id='prompt_error_format_email' />
        }
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, boxChecked: event.target.checked });
    };

    return (
        <Container className={cls.root} >
            <Grid container direction="column" justify="center" alignItems="stretch" >
                <Grid item>
                    <Typography className={cls.getStartText}><FormattedMessage id="prompt_Login_account" /></Typography>
                </Grid>

                <Grid item style={{ marginTop: 40 }}>
                    <TextField className={cls.textField} id="emailtextfield" fullWidth
                        autoComplete='off'
                        variant='outlined'
                        label={<FormattedMessage id="prompt_email"></FormattedMessage>}
                        onFocus={() => setErrorEmail(false)}
                        error={errorEmail}
                        helperText={errorEmail ? checkEmail() : null}
                        onChange={handleEmailOnchange} />
                </Grid>

                <Grid item style={{ marginTop: 24 }}>

                    <TextField className={cls.textField} id="password" fullWidth
                        autoComplete='off'
                        variant='outlined'
                        label={<FormattedMessage id="hint_password"></FormattedMessage>}
                        type={values.showPassword ? 'text' : 'password'}
                        onFocus={() => setErrorPwd(false)}
                        error={errorPwd}
                        helperText={errorPwd ? <FormattedMessage id='prompt_fill_in_pass' /> : null}
                        onChange={handlePasswordChange('password')}
                        onKeyPress={e => {
                            if (e.key === 'Enter') {
                                handleLogin()
                            }
                        }}
                    />

                </Grid>
                <Grid item style={{ marginTop: 20 }}>
                    <Grid container justify="space-between" direction="row" alignItems="center">
                        <Grid >
                            <FormControlLabel control={<Checkbox checked={values.boxChecked} onChange={handleChange} color="primary" />} label={<Typography className={cls.rememberText}><FormattedMessage id="prompt_tutorial_dialog_Rememberme" /></Typography>} ></FormControlLabel>
                        </Grid>
                        <Grid>
                            <Button variant='text' className={cls.forgotAction} component={Link} to='/login/forgot'><FormattedMessage id="prompt_forgot_password" />?</Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item style={{ marginTop: 50 }} className={cls.loadingBg}>
                    <Grid container justify="space-between" direction="row" alignItems="center">
                        <Grid >
                            <Button variant="contained" disabled={values.isLoading} color='primary' className={cls.submitAction} onClick={handleLogin}><FormattedMessage id="action_login" /></Button>
                        </Grid>
                        <Grid>
                            <Button variant="outlined" color='primary' className={cls.submitAction} component={Link} to='/login/inviteCode'><FormattedMessage id="prompt_login_Register" /></Button>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
        </Container>
    )
}


