import React, { Dispatch, useReducer, useContext } from 'react'
import { ProjectError, Group } from '../api/model';

interface Store {
    err?: ProjectError
    groups?: Group[]
    reload: number
}

interface Action {
    action: 'error' | 'reload' | 'group' | 'userTags' | 'lockTags' | 'user' | 'lock'
    err?: ProjectError
    groups?: Group[]
}

export class ActionFactory {
    static dismissError(): Action {
        return { action: 'error' }
    }

    static error(err: ProjectError): Action {
        return { action: 'error', err: err }
    }

    static errorMessage(message: string, ext?: Map<string, string>): Action {
        return { action: 'error', err: { code: 400051, message: message, ext: ext } as ProjectError }
    }

    static success(messageId: string): Action {
        return { action: 'error',  err: { code: 100000, message: messageId } }
    }


    static reload(): Action {
        return { action: 'reload'}
    }

    static groups(groups: Group[]): Action{
        return { action: 'group', groups: groups}
    }
}

interface ContextStore {
    store: Store
    handler: Dispatch<Action>
}

function reducer(state: Store, action: Action): Store {
    switch (action.action) {
        case 'error': return { ...state, err: action.err }
        case 'reload': return { ...state, reload: state.reload + 1, err: undefined }
        case 'group': return { ...state, groups: action.groups }
        default: return state
    }
}

export const ProjectContext = React.createContext<ContextStore>({ store: { reload: 0 }, handler: () => { } })

interface ProjectProviderProps {
    children?: React.ReactNode
}

export function useProject(): ContextStore {
    return useContext<ContextStore>(ProjectContext)
}

export function ProjectProvider({ children }: ProjectProviderProps) {
    const [state, dispatch] = useReducer(reducer, { reload: 0 })
    return (
        <ProjectContext.Provider value={{ store: state, handler: dispatch }}>
            {children}
        </ProjectContext.Provider>
    )
}