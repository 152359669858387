

import dayjs from 'dayjs';
import { Group } from "../api/model";
import { StoreUtil } from '../store';
// import { StoreUtil } from '../store/index'
/*时间戳转字符串 */
export const commonFormatTime = (time: number, formatText: string='YYYY-MM-DD HH:mm:ss') => {
    if (time === 0) {
        return '--'
    }
    let tm = dayjs(time * 1000).format(formatText)
    return tm
}

export function username(user:{firstName?: string, lastName?: string}):string {
    return `${user.firstName} ${user.lastName||""}`
}

export function isNumber(text: string): boolean {
    let reg = /^\+?[0-9][0-9]*$/;
    return reg.test(text);
}

export function isVailLoginPhone(text: string): boolean {
    if (text.length > 1) {
      if (text.indexOf("+") === 0) {
        let x = text.slice(1, text.length - 2);
        return isNumber(x);
      } else {
        return isNumber(text);
      }
    } else {
      return false;
    }
}

export function lockFirmwareVeirsonFormatte(hard: string, firmware: string): string {
    if (firmware.length === 6) { //010203       
        const a = parseInt(firmware.substr(0,2), 16).toString()
        const b = parseInt(firmware.substr(2,2), 16).toString()
        const c = parseInt(firmware.substr(4,2), 16).toString()
      
        return `A180 V${c}.${b}.${a}`
    } else { //0102
        const a = parseInt(firmware.substr(0,2), 16).toString()
        const b = parseInt(firmware.substr(2,2), 16).toString()
        return `A180 V${b}.${a}`
    }
}

export const formateText = (name?:string) => {
    if(name){
        return name
    } else {
        return '--'
    }
}
// (?:\(?[0\+]\d{2,3}\)?)[\s-]?(?:(?:\(0{1,3}\))?[0\d]{1,4})[\s-](?:[\d]{7,8}|[\d]{3,4}[\s-][\d]{3,4})
export function emailVerifyFormatte(mail: string): boolean {
    let reg =/^[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/
    return reg.test(mail)
}

export const isLockDisable = (lock: {lockStatus:number; isOffline?:number}) => lock.lockStatus === -1 || lock.isOffline === 1

export class CommonLength {
    static userNameMaxLength = 40
    static mailMaxLength = 50
    static groupNameMaxLength = 30
    static labelNameMaxLength = 30
    static passwordMaxLength = 20
    static passwordMinLength = 8
    static phoneMaxLength = 12
    static stffIDMaxLength = 40
    static lockNameMaxLength = 40
    static uploadUrlMax = 3
}

interface CloudinaryThumbnail {
    readonly url: string
    readonly thumbnail: string
    readonly name: string
}

export function CloudinaryThumbnail(url: string, width: number, height: number): CloudinaryThumbnail {
    //https://res.cloudinary.com/xiaolong/image/upload/w_120,h_160/v1597304544/upload_test/TLL05A_%D0%BD_%D1%89_%D1%9E_V1.0.2_20190620_wb1ztx.png
    return {
        url: url,
        thumbnail: url.replace("/image/upload/", `/image/upload/w_${width},h_${height}/`).replace(/(.\w+)$/i, ".png"),
        name: url.substring(url.lastIndexOf("/")+1) 
    }
}



