import React from 'react';
import { IntlProvider } from "react-intl";
import { ThemeProvider } from '@material-ui/core/styles';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  RouteProps
} from "react-router-dom";

import locale_en from "./i18n/en.json"
import locale_ko from "./i18n/ko.json"
import { ApiUtil } from './common/api/api';
import { StoreUtil } from './common/store';
import { Login } from './ui/login';
import { Home } from './ui/home';
import { NoMatch } from './ui/404';
import { theme } from './common/theme';
// import { CssBaseline } from '@material-ui/core';
import { ProjectProvider } from './common/component/provider';
import './App.css'
// import License from './ui/login/license'
const data = {
  'en': locale_en,
  'ko': locale_ko,
};

type Langs = 'en' | 'ko';

function localLang(): Langs {
  const language = navigator.language.split(/[-_]/)[0];
  switch (language) {
    case 'en': return "en";
    case 'ko': return "ko";
    default: return "en";
  }
}

function App() {
  StoreUtil.init(window.localStorage);
  ApiUtil.init();
  // const [lang, setLang] = useState<Langs>(localLang());

  // function switchLang() {
  //   setLang(lang === 'en' ? 'ko' : 'en');
  // }

  return (
    <IntlProvider locale={localLang()} messages={data[localLang()]}>
      <ThemeProvider theme={theme}>
        <ProjectProvider>
          <Router>
            <Switch>
              <Route exact path="/">
                <Redirect to="/home" />
              </Route>
              <Route path="/login">
                <Login />
              </Route>
              <PrivateRouter path="/home">
                <Home />
              </PrivateRouter>
              <Route path="*">
                <NoMatch />
              </Route>
            </Switch>
          </Router>
        </ProjectProvider>

      </ThemeProvider>
    </IntlProvider>
  );
}

function PrivateRouter({ children, ...rest }: RouteProps) {
  return (
    <Route {...rest} render={({ location }) => StoreUtil.hasLogin() ? (
      children
    ) : <Redirect to={{ pathname: "/login", state: { from: location } }} />} />);
}

export default App;
