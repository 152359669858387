import axios, { AxiosRequestConfig, AxiosResponse, AxiosTransformer } from 'axios';
import { StoreUtil } from '../store';
import { WorkType, LoginUser, LoginPhone, InForgotPassByPhone, deleteFile, Login, ApplicationDetail, CreateApplication, uploadFile, InQueryApplicationList, Response, Page, InChangePass, InPersonInfo, InForgotPass, ProjectError, Substation, UserUpdatePhoneBO } from './model';
import Base64 from 'crypto-js/enc-base64';
import CryptoJS from 'crypto-js';
import { PorjectError } from './errorMessage';
import qs from 'qs';

const key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_API_AES_KEY!!);

const requestTrans: AxiosTransformer = ((data) => {
    console.log(data)
    let serialData = data
    if (typeof data !== "string") {
        serialData = JSON.stringify(data)
    }
    if (Number(process.env.REACT_APP_STORE_AES_OPEN) <= 0) {
        return serialData
    }
    let aesData = CryptoJS.AES.encrypt(serialData, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 }).ciphertext.toString();
    let base64Data = Base64.stringify(CryptoJS.enc.Hex.parse(aesData));
    return base64Data;
});

const responseTrans: AxiosTransformer = ((data) => {
    if (!data) return data;
    if (Number(process.env.REACT_APP_STORE_AES_OPEN) <= 0) {
        return JSON.parse(data);
    }
    let dataString = CryptoJS.AES.decrypt(data, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 }).toString(CryptoJS.enc.Utf8) || data;
    // console.log(dataString);
    return JSON.parse(dataString);
});

export class ApiUtil {

    private static clinet = axios.create({
        baseURL: process.env.REACT_APP_BASE_URL,
        timeout: 30000,
        transformRequest: requestTrans,
        transformResponse: responseTrans,
        paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: 'repeat' })
        }
    });

    static init() {
        this.clinet.interceptors.request.use(
            function (config: AxiosRequestConfig) {
                let header = config.headers;
                header.post["Content-Type"] = "application/json;charset=UTF-8"
                header.put["Content-Type"] = "application/json;charset=UTF-8"
                header.delete["Content-Type"] = "application/json;charset=UTF-8"
                header.get["Content-Type"] = "application/json;charset=UTF-8"
                let token = StoreUtil.token()
                // console.log(token)
                if (header['passAuth'] === undefined && header['Authorization'] === undefined && token != null) {
                    header['Authorization'] = token
                }
                return config;
            }, function (error) {
                return Promise.reject(error);
            }
        );

        this.clinet.interceptors.response.use(
            function (response: AxiosResponse): AxiosResponse {
                return response;
            },
            function (error) {
                if (error.code === 'ECONNABORTED') {
                    return Promise.reject({ code: 500000, message: "hint_error_time_out" } as ProjectError);
                } else if (error.message === 'Network Error') {
                    return Promise.reject({ code: 500000, message: "prompt_server_upgrade" } as ProjectError);
                }
                const response = error.response as AxiosResponse
                if (response) {
                    if (response.status === 401) {
                        return Promise.reject({ code: 9999, message: "prompt_user_change" } as ProjectError);
                    }
                    switch (Math.floor(response.status / 100)) {
                        case 5: {
                            return Promise.reject({ code: 500000, message: "prompt_server_upgrade" } as ProjectError);
                        }
                        default: {
                            return Promise.reject({ code: 500000, message: "net_code_unknown" } as ProjectError);
                        }
                    }
                }
                return Promise.reject(error)
            }
        );
    }

    private static resolveData<T>(response: AxiosResponse<Response<T>>): T {
        const r = response.data
        console.log(response.config.url)
        console.log(r)
        if (r.code === 0) {
            return r.data
        } else {
            if (r.code === 400019) {
                throw { code: r.code, message: PorjectError.get(r.code), ext: [["groups", r.data]] }
            }
            else {
                let message = PorjectError.get(r.code) || "net_code_unknown"
                throw { code: r.code, message: message }
            }
        }
    }

    // 新用户语言
    static updatePersonalLangType(langType: number): Promise<null> {
        return this.clinet.put<Response<null>>('/api/v1/user/updatePersonalLangType', langType).then(r => this.resolveData(r))
    }

    // WEB用户登录
    static login(info: Login): Promise<LoginUser> {
        let data: Login = { ...info, password: CryptoJS.SHA256(info.password).toString() };
        return this.clinet.post<Response<LoginUser>>("/api/v1/vendorUser/vendorUserLogin", data).then(r => this.resolveData(r)).then(u => StoreUtil.saveUser(u))
    }

    // WEB手机用户登录
    static loginPhone(info: LoginPhone): Promise<LoginUser> {
        let data: LoginPhone = {
            ...info,
            password: CryptoJS.SHA256(info.password).toString(),
        };
        return this.clinet
            .post<Response<LoginUser>>("/api/v1/vendorUser/vendorUserLoginByPhone", data)
            .then((r) => this.resolveData(r))
            .then((u) => StoreUtil.saveUser(u));
    }

    // 邮箱获取验证码
    static getMailVerifyCode(mail: string, lang: string): Promise<null> {
        return this.clinet.get<Response<null>>(`/api/v1/verifyCode/getVendorRegisterVerifyCode`, { params: { "mail": mail, "lang": lang } }).then(r => this.resolveData(r))
    }

    // 忘记密码邮箱获取验证码
    static getForgortMailVerifyCode(mail: string, lang: string): Promise<null> {
        return this.clinet
            .get<Response<null>>(`/api/v1/verifyCode/getMailVerifyCode`, {
                params: { mail: mail, lang: lang },
            })
            .then((r) => this.resolveData(r));
    }

    // 手机号获取验证码
    static getForgortPhoneVerifyCode(phone: string): Promise<null> {
        return this.clinet
            .get<Response<null>>(`/api/v1/verifyCode/sendSmsForgotPwdVCode`, {
                params: { phone: phone },
            })
            .then((r) => this.resolveData(r));
    }

     // 添加手机号获取验证码
     static getAddPhoneCode(phone: string): Promise<null> {
        return this.clinet
            .get<Response<null>>(`/api/v1/verifyCode/sendVendorSignUpPhoneVCode`, {
                params: { phone: phone },
            })
            .then((r) => this.resolveData(r));
    }

    // 验证邀请码
    static verifyInviteCode(mail: string, invitationCode: string, verifyStr: string): Promise<string> {
        let model = { mail: mail, vendorInvitationCode: invitationCode, verifyStr: verifyStr }
        return this.clinet.post<Response<string>>(`/api/v1/vendorUser/checkVendorUserRegister`, model).then(r => this.resolveData(r))
    }

     // 手机验证邀请码
     static verifyPhoneInviteCode(phone: string, invitationCode: string, verifyStr: string): Promise<string> {
        let model = { phone: phone, vendorInvitationCode: invitationCode, verifyStr: verifyStr }
        return this.clinet.post<Response<string>>(`/api/v1/vendorUser/checkVendorUserRegister`, model).then(r => this.resolveData(r))
    }

    // 首次登录完善用户信息
    static completePersonalInfo(info: InPersonInfo): Promise<null> {
        let data: InPersonInfo = { ...info, password: CryptoJS.SHA256(info.password).toString() };
        return this.clinet.post<Response<null>>('/api/v1/vendorUser/vendorUserRegister', data).then(r => this.resolveData(r))
    }
    //  用户忘记密码
    static forgotPassword(data: InForgotPass): Promise<null> {
        let newdata: InForgotPass = { ...data, newPassword: CryptoJS.SHA256(data.newPassword).toString() }
        return this.clinet.put<Response<null>>(`/api/v1/user/forgotPassword`, newdata).then(r => this.resolveData(r))
    }

     //  用户忘记密码
     static forgotPasswordByPhone(data: InForgotPassByPhone): Promise<null> {
        let newdata: InForgotPassByPhone = { ...data, newPassword: CryptoJS.SHA256(data.newPassword).toString() }
        return this.clinet.put<Response<null>>(`/api/v1/user/forgotPasswordByPhone`, newdata).then(r => this.resolveData(r))
    }

    // 用户修改密码
    static changePassword(users: InChangePass): Promise<null> {
        let model = { oldPassword: CryptoJS.SHA256(users.oldPassword).toString(), newPassword: CryptoJS.SHA256(users.newPassword).toString() }
        return this.clinet.put<Response<null>>('/api/v1/user/changePassword', model).then(r => this.resolveData(r))
    }

    // 登出
    static logout(): Promise<null> {
        return this.clinet.delete<Response<null>>(`/api/v1/user/logout`).then(r => this.resolveData(r))
    }

    // 用户修改头像
    static updateUserPhoto(avatar: string): Promise<null> {
        return this.clinet.put<Response<null>>('/api/v1/user/updateUserPhoto', avatar).then(r => this.resolveData(r))
    }

    // vander applicaiton 列表
    static vanderApplicationList(query: InQueryApplicationList): Promise<Page<ApplicationDetail>> {
        return this.clinet.get<Response<Page<ApplicationDetail>>>('/api/v1/application/queryVendorApplicationList', { params: query }).then(r => this.resolveData(r))
    }
    //查询详情app
    static queryApplicationDetail(id: number): Promise<ApplicationDetail> {
        return this.clinet.get<Response<ApplicationDetail>>(`/api/v1/application/queryApplicationById/${id}`).then(r => this.resolveData(r))
    }
    //查询Substations
    static querySubstations(): Promise<Page<Substation>> {
        return this.clinet.get<Response<Page<Substation>>>('/api/v1/substation/querySubstationList', { params: { page: 1, size: 10000 } }).then(r => this.resolveData(r))
    }

    //查询WorkType
    static queryWorkType(): Promise<WorkType[]> {
        return this.clinet.get<Response<WorkType[]>>("/api/v1/workType/queryWorkTypeList").then(r => this.resolveData(r))
    }

    //创建app
    static createApplication(model: CreateApplication): Promise<null> {
        return this.clinet.post<Response<null>>(`/api/v1/application/saveOrUpdate`, model).then(r => this.resolveData(r))
    }


    //上传文件
    static uploadCloudaryFile(file: File): Promise<uploadFile> {
        let form = new FormData()
        form.append('file', file)
        form.append('upload_preset', 'ckckzgic');
        form.append('api_key', "");
        form.append('source', "ml")
        return axios.post<uploadFile>('https://api.cloudinary.com/v1_1/xiaolong/upload', form).then(r => { return r.data })
    }

    static deleteCloudaryFile(token: string): Promise<deleteFile> {
        return axios.post<deleteFile>('https://api.cloudinary.com/v1_1/xiaolong/delete_by_token', { "token": token }).then(r => {
            const x = r.data
            if (x.result === 'ok') {
                return r.data
            } else {
                throw { code: 200, message: "prompt_file_deleteFail" }
            }
        })
    }

    // 添加手机号获取验证码
    static getUpdatePhoneVerifyCode(phone: string): Promise<null> {
        return this.clinet
            .get<Response<null>>(`/api/v1/verifyCode/sendUpdatePhoneVCode`, {
                params: { phone: phone },
            })
            .then((r) => this.resolveData(r));
    }

    static updateUserPhone(userUpdatePhoneBO: UserUpdatePhoneBO): Promise<null> {
        return this.clinet
            .put<Response<null>>(`/api/v1/user/updateUserPhone`, userUpdatePhoneBO)
            .then((r) => this.resolveData(r));
    }
}
