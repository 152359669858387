import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { colors } from '../../common/theme';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import { withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


import {
  Switch,
  Route,
  useRouteMatch,
  Redirect,
  Link,
  useLocation,
} from "react-router-dom";
import Profile from './profile';
import { NavSelect } from '../../common/component';
import { FormattedMessage } from 'react-intl';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { Menu, MenuItem, Avatar, Snackbar, Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Button } from '@material-ui/core';
import { CreateTabs, ChangePasswordComponent, VerificationWinComponent } from './component';
import { TappIcon } from '../../common/component/icons';
import { useProject, ActionFactory } from '../../common/component/provider';
import MuiAlert from '@material-ui/lab/Alert';
import { isApiErr, isSuccessErr, isSessionErr } from '../../common/api/errorMessage';
import { StoreUtil } from '../../common/store';
import { Login } from '../../common/api/model';
import { ApiUtil } from '../../common/api/api';
import fromEntries from 'object.fromentries';
import { CreateNewApplication } from './newApplication/createApp'
import { ApprovedApplication } from './newApplication/approveApp'
import { RejectedApplication } from './newApplication/rejectApp'
import { DeclinedApplication } from './newApplication/declineApp'
import comanyLogo from "../../images/comanyLogo.png"
import { Application } from '../home/Application'
const drawerWidth = 250;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      height: '100%',
    },
    drawer: {
      [theme.breakpoints.up('lg')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'center',
      marginTop: 24,
    },
    appBar: {
      // [theme.breakpoints.up('lg')]: {
      //   width: '100%',
      //   marginLeft: drawerWidth,
      // },
      width: '100%',
      background: 'white',
    },
    appBarLabel: {
      [theme.breakpoints.down('md')]: {
        width: 0,
      },
      maxWidth: 200,
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('lg')]: {
        display: 'none',
      },
    },
    // necessary for content to be below app bar
    drawerPadding: {
      textIndent: 16,
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
      background: colors.menuColor,
      color: "#fff",
      borderWidth: 0
    },
    panelPaper: {
      background: colors.menuColor,
      color: "#fff",
    },
    content: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      background: '#f5f5f5',
    },
    subcontent: {
      flexGrow: 1,
      display: "flex",
      flexDirection: 'column',
    },
    drawerIndex: {
      textIndent: 20,
    },
    expansionAction: {
      color: "#fff"
    },
    space: {
      flexGrow: 1,
    },
    avatar: {
      width: theme.spacing(3),
      height: theme.spacing(3),
      // marginRight: theme.spacing(1),
    },
    image: {
      backgroundImage: 'url(' + require('../../images/comanyLogo.png') + ')',
      // backgroundSize: '70%',
      width: 300,
      height: 40,
      // backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      // position: 'relative',
    },
  }),
);

const ExpansionPanel = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },



  expanded: {},
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(0),
    display: 'inherit',
    textIndent: theme.spacing(1)
  },
}))(MuiExpansionPanelDetails);

function panelAndLabel(pathArray: Array<string>): { panel: string | null; label: string | null } {
  if (pathArray.length === 0) return { panel: "dashboard", label: "dashboard" };
  const panel = pathArray[0] || null
  const label = pathArray[1] || null
  return { panel, label }
}

export const Home = React.memo(() => {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const { path, url } = useRouteMatch();
  const { pathname } = useLocation();
  const pathArray = pathname.split('/').slice(2);
  const { panel, label } = panelAndLabel(pathArray)
  // const titleId = getTitle(label, panel)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [expanded, setExpanded] = React.useState<string | false>(panel || false);
  const [password, setPassword] = React.useState<string>("");
  const { store, handler } = useProject()
  const [changePwdExpand, setchangePwdExpand] = React.useState(false)
  const [phoneVerificationWin, setPhoneVerificationWin] = React.useState<boolean>(
    false
  );
  const [alert, setAlert] = React.useState<'api' | 'none' | 'session' | 'success'>('none')
  const [openDialog, setOpenDialog] = React.useState(false);
  const userprofile = StoreUtil.user();
  React.useEffect(() => {
    if (isApiErr(store.err)) {
      setAlert('api')
    } else if (isSuccessErr(store.err)) {
      setAlert('success')
    } else if (isSessionErr(store.err)) {
      setOpenDialog(true)
    } else {
      setAlert('none')
    }
  }, [store.err])
  React.useEffect(() => {

  }, [store.reload])
  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const userLogOut = () => {
    setAnchorEl(null);
    StoreUtil.clearUser()
    handler(ActionFactory.dismissError())
  };
  const hiddeError = () => {
    // handler(ActionFactory.dismissError())
    setAlert('none')
  }

  const onclickChangePwdDialog = () => {

    setchangePwdExpand(false)
  }

  const onclickVerifitionWin = () => {
    setPhoneVerificationWin(false)
  }
  const onclickVerifitionWinTrue = () => {
    setPhoneVerificationWin(true)
  }
  const onclickChangePwd = () => {
    setAnchorEl(null);
    setchangePwdExpand(true)
  }
  const onclickVerifition = () => {
    setAnchorEl(null);
    setPhoneVerificationWin(true)
  }
  const handlePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!openDialog) {
      setOpenDialog(true);
    }
    setPassword(event.target.value)
  };

  const clearUser = () => {
    StoreUtil.clearUser()
    handler(ActionFactory.dismissError())
    setOpenDialog(false)
  }

  function login() {
    const info: Login = { "mail": StoreUtil.userEmail()!!, "password": password };
    ApiUtil.login(info).then(d => { setOpenDialog(false); handler(ActionFactory.reload()); }).catch(e => handler(ActionFactory.error(e)))
  }

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar} color="inherit" elevation={0}>
        <Toolbar>
          <div>
            <img src={comanyLogo} alt="" style={{ width: 92 }}></img>
          </div>
          <div className={classes.space} />
          <div style={{ borderRight: 'solid 1px 	rgb(212,212,214)', paddingRight: 32, marginRight: 32 }}>
            <Avatar src={StoreUtil.userAvatar()} className={classes.avatar} />
          </div>
          <Typography variant='subtitle2' noWrap>{StoreUtil.username()}</Typography>
          <div>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <ExpandMore />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={open}
              onClose={handleClose}
            >
              {/* <MenuItem onClick={handleClose} component={Link} to={`${url}/profile`}><FormattedMessage id="prompt_personalProfile" /></MenuItem> */}
              {/* {userprofile?.isVerifyPhone ? null : <MenuItem onClick={onclickVerifition}><FormattedMessage id="action_verifition" /></MenuItem>} */}
              <MenuItem onClick={onclickChangePwd}><FormattedMessage id="action_update_pass" /></MenuItem>
              <MenuItem onClick={userLogOut} component={Link} to={`/login`}><FormattedMessage id="action_logout" /></MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>


      <main className={classes.content}>
        <div className={classes.toolbar} />
        <div id="subcontent" className={classes.subcontent}>
          <Switch>

            <Route path={`${url}/createApp`}>
              <CreateNewApplication />
            </Route>
            <Route path={`${url}/approveApp/:id`}>
              <ApprovedApplication />
            </Route>
            <Route path={`${url}/rejectApp/:id`}>
              <RejectedApplication />
            </Route>
            <Route path={`${url}/declineApp/:id`}>
              <DeclinedApplication />
            </Route>
            <Route path={`${url}/profile`}>
              <Profile />
            </Route>
            <Route exact path={url}>
              <Application />
            </Route>
            <Route path="*">
              <Redirect to={url} />
            </Route>
          </Switch>
        </div>
        <Snackbar open={alert === 'api'} autoHideDuration={1500} onClose={hiddeError} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <MuiAlert elevation={6} variant="filled" severity="error">
            <FormattedMessage id={store.err?.message || "net_code_unknown"} values={fromEntries(store.err?.ext || new Map())} />
          </MuiAlert>
        </Snackbar>
        <Snackbar open={alert === 'success'} autoHideDuration={1500} onClose={hiddeError} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <MuiAlert elevation={6} variant="filled" severity="success">
            <FormattedMessage id={store.err?.message || "net_code_unknown"} />
          </MuiAlert>
        </Snackbar>
        <Dialog open={openDialog} onClose={hiddeError} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title"><FormattedMessage id="prompt_relogin_title" /></DialogTitle>
          <DialogContent>
            <DialogContentText>
              <FormattedMessage id="formate_relogin_content" values={{ email: StoreUtil.userEmail() }} />
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="mail"
              label="Password"
              type="password"
              fullWidth
              onChange={handlePassword}
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  login()
                }
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={login} color="primary">
              <FormattedMessage id="action_login" />
            </Button>
            <Button onClick={clearUser} color="primary" component={Link} to="/login">
              <FormattedMessage id="action_cancel" />
            </Button>
          </DialogActions>
        </Dialog>
        <ChangePasswordComponent isOpen={changePwdExpand} onSelectClose={onclickChangePwdDialog}></ChangePasswordComponent>
        <VerificationWinComponent isOpen={phoneVerificationWin} onSelectClose={onclickVerifitionWin}></VerificationWinComponent>
      </main>
    </div>
  );
})
