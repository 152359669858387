import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { CancleIcon, SearchIcon } from '../../../common/component/icons';
import { Container, makeStyles, Theme, createStyles, Grid, Select, MenuItem, FormControl, Button, InputLabel, Typography, TextField, Paper, Tooltip } from '@material-ui/core'
import { CornerGradientButton, BottomPagination, Loading } from '../../../common/component';
import { NoData } from '../../nodata'
import { Link, useRouteMatch, useHistory } from 'react-router-dom'
import { ActionFactory, useProject } from '../../../common/component/provider';
import { ApiUtil } from '../../../common/api/api';
import { InQueryApplicationList, Page, ApplicationDetail } from '../../../common/api/model'
import { commonFormatTime } from '../../../common/util';

const useStyle = makeStyles((theme: Theme) => createStyles({
    topSearch: {
        width: '100%',
        maxWidth: '100%',
        paddingBottom: 10,
        borderBottom: '0.5px solid #d4d4d6',
        borderTop: '0.5px solid #d4d4d6',
        paddingTop: 10
    },
    contentPage: {
        width: '100%',
        maxWidth: '100%',
        marginTop: 62
    },
    ColorSelectfield: {
        top: 1,
        userSelect: 'none',
        width: 135,
        '& .MuiInputBase-root': {
            height: 31
        },
        '& label': {
            marginTop: -14
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(14px, 6px) scale(0.75)'
        },
        '& .MuiOutlinedInput-input:focus': {
            padding: '8px 10px 8px 10px',
            borderRadius: 10,
            backgroundColor: theme.palette.background.default,
        },
        '& .MuiOutlinedInput-input': {
            padding: '8px 10px 8px 10px',
            borderRadius: 10,
            backgroundColor: theme.palette.background.default,
        },
    },
    clearColor: {
        '& :hover': {
            cursor: 'pointer'
        }
    },
    cancleIconPosition: {
        position: 'absolute',
        right: 4,
        margin: 'auto',
        top: 1,
        left: '77%',
        bottom: 0,
        backgroundColor: '#f5f5f5',
        width: 15,
        height: 15
    },
    searchfield: {
        top: 1,
        '& .MuiInputBase-root': {
            height: 31,
        },
        '& label': {
            marginTop: -5
        },
        marginLeft: 15,
        '& input': {
            paddingRight: 35
        }
    },
    searchicon_button: {
        borderRadius: '6px',
        minWidth: 0,
        margin: 0,
        boxShadow: 'none',
        height: 32,
        width: 32,
        marginLeft: 15
    },
    addicon_button: {
        borderRadius: '6px',
        minWidth: 0,
        margin: 0,
        marginLeft: 24,
        boxShadow: 'none',
        position: 'relative',
        paddingBottom: 4,
        paddingTop: 4
    },
    addicon_button_create: {
        borderRadius: '6px',
        minWidth: 0,
        margin: 0,
        boxShadow: 'none',
        position: 'relative',
    },
    addgroup: {
        textAlign: "right",
        marginLeft: 10
    },
    contentPaperBox: {
        position: 'relative'
    },
    statusLabel_Approved: {
        // width: 108,
        padding: '1px 8px 1px 13px',
        height: 24,
        color: '#3bc477',
        borderRadius: '100px 0 0 100px',
        border: '1px solid #3bc477',
        borderRight: '0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    statusLabel_Declined: {
        // width: 108,
        padding: '1px 8px 1px 13px',
        height: 24,
        color: '#262834',
        borderRadius: '100px 0 0 100px',
        border: '1px solid #262834',
        borderRight: '0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    statusLabel_PreApproved: {
        padding: '1px 8px 1px 13px',
        height: 24,
        color: '#4a90e2',
        borderRadius: '100px 0 0 100px',
        border: '1px solid #4a90e2',
        borderRight: '0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    statusLabel_Rejected: {
        // width: 108,
        padding: '1px 8px 1px 13px',
        height: 24,
        color: '#ff0000',
        borderRadius: '100px 0 0 100px',
        border: '1px solid #ff0000',
        borderRight: '0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    statusLabel_Processing: {
        // width: 108,
        padding: '1px 8px 1px 13px',
        height: 24,
        color: '#fdc527',
        borderRadius: '100px 0 0 100px',
        border: '1px solid #fdc527',
        borderRight: '0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    nowrapContent: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        paddingLeft: 24,
        fontSize: 32,
        fontWeight: 'normal'
    },
    contentView: {
        position: 'relative',
        background: '#fbfbfb',
        height: 158,
        margin: 24,
        border: '0.5px dashed #d4d4d6',
        borderRadius: 6,
    },
    contentStyle_676870: {
        position: 'relative',
        color: '#676870',
        fontSize: 16,
        fontWeight: 'normal',
        paddingLeft: 8,
        paddingRight: 8
    },
    contentStyle: {
        position: 'relative',
        fontSize: 16,
        fontWeight: 'normal',
        paddingLeft: 8,
        paddingRight: 8
    },
    Table_Pagination_box: {
        position: 'relative',
        top: 2,
        height: 60,
        zIndex: 8,
        // backgroundColor: theme.palette.background.default,
        bottom: 0,
        width: 500,
    },
    label_box: {
        marginTop: 70,
        paddingBottom: 20
    },
    titleContent: {
        fontSize: 20,
        fontWeight: 500
    },
    reason: {
        height: 24,
        background: '#fef9e9',
        borderRadius: 16,
        fontSize: 14,
        fontWeight: 'normal',
        lineHeight: 1.29,
        color: '#fdc527',
        padding: '1px 10px',
        maxWidth: 130,
        textAlign: 'center',
        width: '90%',
    },
    userCount: {
        position: 'absolute',
        right: -7,
        top: -7,
        background: '#ffefe5',
        width: 24,
        height: 24,
        borderRadius: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#ff6600'
    }
}))

export function Application() {
    const classes = useStyle()
    const { url } = useRouteMatch()
    const [content, setContent] = useState<Page<ApplicationDetail>>();
    const [selectStatus, setSelectStatus] = useState('')
    const [searchContentText, setSearchContentText] = useState<string>('');
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const { store, handler } = useProject()
    const [page, setPage] = useState(0);
    const [searchStatus, setSearchStatus] = useState<number>(0)
    const [loading, setLoading] = useState<boolean>(false)
    useEffect(() => {
        setLoading(true)
        let request: InQueryApplicationList = { status: selectStatus.toString.length > 0 ? Number(selectStatus) : undefined, keyword: searchContentText, page: page + 1, size: rowsPerPage }
        ApiUtil.vanderApplicationList(request).then(result => {
            setContent(result)
            setLoading(false)
        }).catch(error => {
            setLoading(false)
            handler(ActionFactory.error(error))
        })
    }, [store.reload, searchStatus, page, rowsPerPage])
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const SelectStatus = (event: React.ChangeEvent<{ value: unknown }>) => {
        setSelectStatus(event.target.value as string)
    }
    const searchContent = (event: React.ChangeEvent<{ value: unknown }>) => {
        setSearchContentText(event.target.value + '')
    }
    const onClickTurnOn = (status: number) => {
        if (status === 1) {
            return "/approveApp"

        }
        if (status === 2) {
            return "/declineApp"

        }
        if (status === 3) {
            return "/rejectApp"
        }
        if (status === 4) {
            return "/approveApp"
        }
        if (status === 0) {
            return "/approveApp"
        }
        if (status === 5) {
            return "/approveApp"
        }
        else return ""
    }
    const statusViewSelect = (status: number) => {
        if (status === 1) {
            return (
                <Tooltip title={<FormattedMessage id="prompt_has_been_pre_approved" />}>
                    <div className={classes.statusLabel_PreApproved} >
                        <FormattedMessage id="prompt_status_pre_approved" />
                    </div>
                </Tooltip>
            )
        }
        if (status === 2) {
            return (
                <Tooltip title={<FormattedMessage id="prompt_has_been_declined" />}>
                    <div className={classes.statusLabel_Declined} >
                        <FormattedMessage id="prompt_status_declined" />
                    </div>
                </Tooltip>
            )
        }
        if (status === 3) {
            return (
                <Tooltip title={<FormattedMessage id="prompt_has_been_rejected" />}>
                    <div className={classes.statusLabel_Rejected} >
                        <FormattedMessage id="prompt_status_rejected" />
                    </div>
                </Tooltip>
            )
        }
        if (status === 4) {
            return (
                <Tooltip title={<FormattedMessage id="prompt_has_been_approved" />}>
                    <div className={classes.statusLabel_Approved} >
                        <FormattedMessage id="prompt_status_approved" />
                    </div>
                </Tooltip>
            )
        }
        if (status === 0) {
            return (
                <Tooltip title={<FormattedMessage id="prompt_has_been_processed" />}>
                    <div className={classes.statusLabel_Processing} >
                        <FormattedMessage id="prompt_pending" />
                    </div>
                </Tooltip>
            )
        }
        if (status === 5) {
            return (
                <Tooltip title={<FormattedMessage id="prompt_has_been_processed" />}>
                    <div className={classes.statusLabel_Processing} >
                        <FormattedMessage id="prompt_pending" />
                    </div>
                </Tooltip>
            )
        }
        else {
            return (<div></div>)
        }

    }
    return (
        <div style={{ position: 'relative', width: '100%' }}>
            <Loading open={loading} />
            <Container className={classes.topSearch}>
                <Grid container justify='space-between' alignItems='center' >
                    <Grid item lg={3} md={3} xs={12}>
                        <Typography className={classes.titleContent}><FormattedMessage id="prompt_all_application" /></Typography>
                    </Grid>
                    <Grid item lg={9} md={9} xs={12} style={{ minWidth: 613 }}>
                        <Grid container justify='flex-end'>
                            <Grid item>
                                <FormControl variant="outlined" className={classes.ColorSelectfield}>
                                    <InputLabel ><Typography style={{ fontSize: 14 }}><FormattedMessage id="prompt_status" /></Typography></InputLabel>
                                    <Select
                                        onChange={SelectStatus}
                                        value={selectStatus}
                                        label={<FormattedMessage id="prompt_status" />}
                                    >
                                        <MenuItem value={0} id="Processing">
                                            <FormattedMessage id="prompt_pending" />
                                        </MenuItem>
                                        <MenuItem value={1} id="Approved">
                                            <FormattedMessage id="prompt_status_pre_approved" />
                                        </MenuItem>
                                        <MenuItem value={2} id="Declined">
                                            <FormattedMessage id="prompt_status_declined" />
                                        </MenuItem>
                                        <MenuItem value={3} id="Rejected">
                                            <FormattedMessage id="prompt_status_rejected" />
                                        </MenuItem>
                                        <MenuItem value={4} id="Rejected">
                                            <FormattedMessage id="prompt_status_approved" />
                                        </MenuItem>
                                    </Select>
                                    {selectStatus.toString.length > 0
                                        ?
                                        <div onClick={() => setSelectStatus('')} className={classes.clearColor}><CancleIcon className={classes.cancleIconPosition} fontSize='inherit' /></div>
                                        : null}
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <Grid container>
                                    <Grid item style={{ position: 'relative' }}>
                                        <TextField id="outlined-searchf"
                                            label={<Typography variant='inherit' style={{ fontSize: 14 }}><FormattedMessage id="prompt_type_application_search" /></Typography>}
                                            variant="outlined"
                                            size='small'
                                            className={classes.searchfield}
                                            onChange={searchContent}
                                            value={searchContentText}
                                            autoComplete='off'
                                            onKeyPress={e => {
                                                if (e.key === 'Enter') {
                                                    setSearchStatus(searchStatus + 1);
                                                    setPage(0);
                                                }
                                            }}
                                        >
                                        </TextField>
                                        {searchContentText.length > 0
                                            ?
                                            <Grid item onClick={() => setSearchContentText("")}
                                                className={classes.clearColor}>
                                                <CancleIcon className={classes.cancleIconPosition}
                                                    fontSize='inherit' />
                                            </Grid>
                                            : null
                                        }
                                    </Grid>
                                    <Grid item>
                                        <CornerGradientButton variant='contained' color='primary' size='small' className={classes.searchicon_button} style={{ position: 'relative', right: 7 }} onClick={() => { setSearchStatus(searchStatus + 1); setPage(0) }}>
                                            <SearchIcon fill='#ffffff' fontSize='default' />
                                        </CornerGradientButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item className={classes.addgroup}>
                                <Button variant='outlined' color='primary' size='small' className={classes.addicon_button} component={Link} to={url + "/createApp"}>
                                    <FormattedMessage id="prompt_Create_new_application" />
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
            {content && content.list.length > 0
                ?
                <Container maxWidth='lg' className={classes.contentPage}>
                    <Grid container spacing={3}>
                        {content && content.list.map(item =>
                            <Grid item lg={3} md={6} xs={12} key={item.id}>
                                <Paper className={classes.contentPaperBox}>
                                    <Grid container justify='space-between' alignItems='center' style={{ paddingTop: 24 }}>
                                        <Grid item xs={6} md={7} lg={7}>
                                            <Tooltip title={item.applicationNo ? item.applicationNo : ''}>
                                                <Typography noWrap className={classes.nowrapContent}>{item.applicationNo || `--`}</Typography>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item xs={6} md={5} lg={5}>
                                            <Grid container justify='flex-end'>
                                                {statusViewSelect(item.status)}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid container style={{ position: 'relative' }}>
                                        <Grid item style={{ width: '100%' }}>
                                            <div className={classes.contentView}>
                                                <div className={classes.userCount}>
                                                    {item.workList && item.workList.length || 0}
                                                </div>
                                                <div style={{ paddingTop: 8 }}>
                                                    <Typography noWrap className={classes.contentStyle}><FormattedMessage id="prompt_substations" /></Typography>
                                                    <Typography noWrap className={classes.contentStyle_676870}>{item.workList && item.workList[0].substationName || `--`}</Typography>
                                                    <Typography noWrap className={classes.contentStyle}><FormattedMessage id="prompt_data_entry" /></Typography>
                                                    <Typography noWrap className={classes.contentStyle_676870}>{`${item.workList && commonFormatTime(item.workList[0].beginTime, 'YYYY-MM-DD') || `--`} ~ ${item.workList && commonFormatTime(item.workList[0].endTime, 'YYYY-MM-DD') || `--`}`}</Typography>
                                                    <Typography noWrap className={classes.contentStyle}><FormattedMessage id="prompt_type_work" /></Typography>
                                                    <Typography noWrap className={classes.contentStyle_676870}>
                                                        {item.workList && item.workList[0].typeOfWorks[0] || `--`} {item.workList && item.workList[0].typeOfWorks.slice(1, item.workList[0].typeOfWorks.length).map(itemV => <span> / {itemV}</span>)}
                                                    </Typography>
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <Grid container justify='space-between' alignItems='center' style={{ padding: '0 0 24px 0' }}>
                                        <Grid item xs={7}>
                                            <Button variant='outlined' color='primary' size='small' className={classes.addicon_button} component={Link} to={url + `${onClickTurnOn(item.status)}/${item.id}`}>
                                                {item.status === 2
                                                    ?
                                                    <FormattedMessage id="prompt_update_information" />
                                                    :
                                                    <FormattedMessage id="prompt_Details" />}
                                            </Button>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Grid container justify='flex-end' style={{ paddingRight: 24 }}>
                                                {item.status === 2 ?
                                                    <Tooltip title={item.reason ? item.reason : ''}>
                                                        <div className={classes.reason}>
                                                            <Typography noWrap><FormattedMessage id="prompt_Decline_reason" /></Typography>
                                                        </div>
                                                    </Tooltip>
                                                    :
                                                    item.status === 3 ?
                                                        <Tooltip title={item.reason ? item.reason : ''}>
                                                            <div className={classes.reason}>
                                                                <Typography noWrap><FormattedMessage id="prompt_Reject_reason" /></Typography>
                                                            </div>
                                                        </Tooltip>
                                                        : null
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        )}
                    </Grid>
                    <div className={classes.Table_Pagination_box} />
                </Container>
                :
                !loading ?
                    <div className={classes.label_box}><NoData /></div>
                    : null
            }
            <BottomPagination
                labelRowsPerPage={<FormattedMessage id="prompt_pageSize" />}
                count={content && content.totalCount || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
            <div style={{ width: '100%', height: 53, backgroundColor: '#f5f5f5', position: 'fixed', bottom: 0, right: 5 }} />
        </div>
    )
}