import React, { useEffect } from 'react'
import { Typography, makeStyles, createStyles, TextField, Button, Container, Grid, } from '@material-ui/core'
import { colors } from '../../../common/theme'
import { FormattedMessage } from 'react-intl'
import { ApiUtil } from '../../../common/api/api';
import { useLoginContext } from '..';
import { PorjectError } from '../../../common/api/errorMessage'
import { CommonLength, emailVerifyFormatte } from '../../../common/util'
import { Redirect } from 'react-router-dom';
const useStyle = makeStyles(() => createStyles({

    root: {
        flexGrow: 1,
        width: 496,
        backgroundColor: 'white',
    },

    getStartText: {
        fontSize: 24,
        fontWeight: 500,
        color: colors.menuColor
    },
    subTitle: {
        fontSize: 19,
        color: colors.secondaryNormal,
        fontWeight: 500
    },

    textField: {
        fontSize: 16,
        color: colors.textSeconday,
        '& .MuiInputBase-input': {
            boxShadow: '0 0 0px 1000px white inset !important'
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: colors.divider,
            }
        },
        '& .MuiFormLabel-root': {
            color: '#b0b3ba'
        }
    },

    submitAction: {
        fontSize: 16,
        borderRadius: 4,
    },
    sendAction: {
        fontSize: 18,
        borderRadius: 4,
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 25,
        paddingRight: 25,
    },
    loadingBg: {
        position: 'relative',
    },
    loading: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}))

interface State {
    mail: string,
    inviteCode: string,
    verifyCode: string,
}

type Langs = 'en' | 'ko';

function localLang(): Langs {
    const language = navigator.language.split(/[-_]/)[0];
    switch (language) {
        case 'en': return "en";
        case 'ko': return "ko";
        default: return "en";
    }
}
export function InviteCodeRegister() {
    const cls = useStyle()
    const { state, method } = useLoginContext()
    const unknownMesg = "net_code_unknown"
    const [num, setNum] = React.useState(0)
    const [isSend, setIsSend] = React.useState(false)
    const [values, setValues] = React.useState<State>({
        mail: '',
        inviteCode: '',
        verifyCode: '',
    });
    const [addMailSuccess, setAddMailSuccess] = React.useState<0|1|2>(0);

    const [errorEmail, setErrorEmail] = React.useState(false)
    const [errorCode, setErrorCode] = React.useState(false)
    const [errorInvite, setErrorInvite] = React.useState(false)

    const handleEmailOnchange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, mail: event.target.value })
    }

    const handleVerifyCodeOnchange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, verifyCode: event.target.value })
    }
    const handleInviteCoeOnchange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, inviteCode: event.target.value })
    }


    const sendVerification = () => {
        if (num !== 0) {
            return
        }
        if (values.mail.length === 0 || !emailVerifyFormatte(values.mail) || values.mail.length > CommonLength.mailMaxLength) {
            setErrorEmail(true)
        } else {
            const local = localLang()
            ApiUtil.getMailVerifyCode(values.mail, local).then(
                () => { setNum(60); setIsSend(true) }
            ).catch(
                err => {
                    let mesg = PorjectError.get(err.code) || unknownMesg
                    method({ type: 'errorHint', payload: { ...state, errorMesg: mesg, isOpen: true } })
                }
            )
        }
    }

    useEffect(() => {
        let timer = 0;
        if (isSend && num !== 0) {
            timer = setInterval(() => {
                setNum(n => {
                    if (n === 1) {
                        setIsSend(false)
                        clearInterval(timer)
                    }
                    return n - 1
                });
            }, 1000)
        }
        return () => {
            clearInterval(timer)
        };
    }, [isSend])


    const canSave = () => {
        let getError = false
        if (values.mail.length === 0 || !emailVerifyFormatte(values.mail)) {
            getError = true
            setErrorEmail(true)
        }

        if (values.verifyCode.length === 0) {
            getError = true
            setErrorCode(true)
        }

        if (values.inviteCode.length === 0) {
            getError = true
            setErrorInvite(true)
        }
        return !getError
    }

    const checkEmail = () => {
        if (values.mail.length === 0) {
            return <FormattedMessage id='prompt_fill_in_email' />
        } else if (values.mail.length > CommonLength.mailMaxLength) {
            return <FormattedMessage id='prompt_errorMessage_eamilOverLength' values={{ number0: CommonLength.mailMaxLength }} />
        } else {
            return <FormattedMessage id='prompt_error_format_email' />
        }
    }

    const handleSave = () => {
        if (canSave()) {
            ApiUtil.verifyInviteCode(values.mail, values.inviteCode, values.verifyCode).then(
                response => {
                    method({ type: 'verify', payload: { ...state, userInfo: { code: values.inviteCode, verifyStr: response, mail: values.mail, phone: "", phoneCheckStr: "" } } })
                    setAddMailSuccess(1);
                }
            ).catch(
                err => {
                    let mesg = PorjectError.get(err.code) || unknownMesg
                    method({ type: 'errorHint', payload: { ...state, errorMesg: mesg, isOpen: true } })
                }
            )
        }
    }

    const handleAddPhone = () => {
        if (canSave()) {
            ApiUtil.verifyInviteCode(values.mail, values.inviteCode, values.verifyCode).then(
                response => {
                     // 传入complete
                    method({ type: 'verify', payload: { ...state, userInfo: { code: values.inviteCode, verifyStr: response, mail: values.mail, phone: "", phoneCheckStr: "" } } })
                    setAddMailSuccess(2)
                }
            ).catch(
                err => {
                    let mesg = PorjectError.get(err.code) || unknownMesg
                    method({ type: 'errorHint', payload: { ...state, errorMesg: mesg, isOpen: true } })
                }
            )
        }
    }
    
    if (addMailSuccess === 1) {
        return <Redirect to="/login/completeInfo" />;
    } 
    // else if (addMailSuccess === 2) {
    //     return <Redirect to="/login/addPhone" />;
    // }
    
    return (
        <Container className={cls.root}>
            <Grid container direction="column" justify="center" alignItems="stretch">
                <Grid item>
                    <Typography className={cls.getStartText}><FormattedMessage id="prompt_register_Account" /></Typography>
                </Grid>
                <Grid item >
                    <Typography className={cls.subTitle}><FormattedMessage id="prompt_invitation_code" /></Typography>
                </Grid>
                <Grid style={{ marginTop: 40 }} >
                    <TextField fullWidth id="inviteCode" className={cls.textField}
                        autoComplete="off"
                        variant='outlined'
                        label={<FormattedMessage id="prompt_invitation_code"></FormattedMessage>}
                        onFocus={() => setErrorInvite(false)}
                        error={errorInvite}
                        helperText={errorInvite ? <FormattedMessage id='prompt_fill_in_inviteCode' /> : null}
                        onChange={handleInviteCoeOnchange}
                    ></TextField>
                </Grid>
                <Grid item style={{ marginTop: 24 }}>
                    <TextField className={cls.textField} id="emailtextfield" fullWidth
                        autoComplete='off'
                        variant='outlined'
                        label={<FormattedMessage id="prompt_email"></FormattedMessage>}
                        onFocus={() => setErrorEmail(false)}
                        error={errorEmail}
                        helperText={errorEmail ? checkEmail() : null}
                        onChange={handleEmailOnchange} />
                </Grid>

                <Grid item style={{ marginTop: 24 }} >
                    <TextField id="Verification" name="Verification" className={cls.textField}
                        onFocus={() => setErrorCode(false)}
                        fullWidth
                        autoComplete="new-password"
                        label={<FormattedMessage id="prompt_verification_code"></FormattedMessage>}
                        variant='outlined'
                        error={errorCode}
                        type="text"
                        helperText={errorCode ? <FormattedMessage id='prompt_fill_in_email_code' /> : null}
                        onChange={handleVerifyCodeOnchange}
                        InputProps={{
                            endAdornment: (
                                <Button className={cls.sendAction} color='primary' variant='contained' onClick={sendVerification} >{num !== 0 ? `${num}s` : <FormattedMessage id="prompt_login_send" />}</Button>
                            ),
                        }}
                    ></TextField>
                </Grid>
                <Grid container style={{ marginTop: 50 }} justify="flex-end">
                    {/* <Grid item>
                        <Button variant="outlined" color='primary' className={cls.submitAction} onClick={handleAddPhone}><FormattedMessage id="prompt_add_phone_number" /></Button>
                    </Grid> */}
                    <Grid item>
                        <Button variant="outlined" color='primary' className={cls.submitAction} onClick={handleSave}><FormattedMessage id="prompt_next" /></Button>
                    </Grid>

                </Grid>
            </Grid>
        </Container>
    )
}