import React from 'react';
import { Typography, makeStyles, createStyles, Button, Grid, Divider, Paper, Tooltip, Chip } from '@material-ui/core'
import { colors } from '../../../../common/theme'
import { FormattedMessage } from 'react-intl';
import { ApiUtil } from '../../../../common/api/api';
import { PorjectError } from '../../../../common/api/errorMessage';
import { Redirect, useParams } from 'react-router-dom';
import { Loading } from '../../../../common/component'
import { useProject, ActionFactory } from '../../../../common/component/provider'
import { commonFormatTime } from '../../../../common/util'
import { ApplicationDetail } from '../../../../common/api/model'
import { InputInfoComponent, WorkDetailTableComponent, DocThumbnailComponent, ApproveLockListComponent } from '../component'


const useStyle = makeStyles((theme) => createStyles({
    root: {
        flexGrow: 1,
    },

    titleStyle: {
        paddingLeft: theme.spacing(9),
        paddingRight: theme.spacing(9),
        height: 64
    },

    titleText: {
        fontSize: 20,
        fontWeight: 500,
        color: colors.menuColor
    },
    subTitle: {
        fontSize: 18,
        paddingTop: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        color: colors.menuColor,
        fontWeight: 500
    },

    subTitleBottom: {
        fontSize: 18,
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        color: colors.menuColor,
        fontWeight: 500,
        borderBottom: '0.5px solid #d4d4d6',
    },
    thirdTitle: {
        fontSize: 18,
        color: colors.menuColor,
        fontWeight: 500
    },

    GridStyle: {
        padding: 12,
        margin: 0,
        width: '100%'
    },

    topGridStyle: {
        padding: 0,
        margin: 0,
        width: '100%'
    },
    conentStyle: {
        paddingLeft: theme.spacing(9),
        paddingRight: theme.spacing(9),
    },
    topStyle: {
        marginTop: theme.spacing(3)
    },

    submitAction: {
        fontSize: 16,
        borderRadius: 4,
        fontWeight: 500,
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 2,
        paddingBottom: 2
    },

    textField: {
        fontSize: 16,
        color: colors.textSeconday,
        '& .MuiInputBase-input': {
            boxShadow: '0 0 0px 1000px white inset !important'
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: colors.divider,
            }
        },
        '& .MuiFormLabel-root': {
            color: '#b0b3ba'
        }
    },

    textStyle: {
        fontSize: 16,
        color: colors.textSeconday
    },

    gridPaddingRight: {
        // '& .MuiGrid-spacing-xs-3 > .MuiGrid-item': {
        //     paddingLeft: 0, 
        //     paddingBottom: 0,
        //     [theme.breakpoints.down("md")]: {
        //         paddingRight: 0,
        //         background: colors.primary,
        //       },
        // }
    },
    gridPaddingLeft: {
        [theme.breakpoints.down("md")]: {
            paddingLeft: 0,
        },
    },
    space: {
        flexGrow: 1,
    },

    contentText: {
        fontSize: 16,
        color: colors.textSeconday,
    },

}))

export function ApprovedApplication() {
    const cls = useStyle()
    const { id } = useParams()
    const { store, handler } = useProject()
    const [isLoading, setIsLoading] = React.useState(true)
    const [queryIndex, setQueryIndex] = React.useState(0)
    const [isRedirect, setIsRedirect] = React.useState(false)
    const [response, setResponse] = React.useState<ApplicationDetail | null>(null)
    console.log(id)
    React.useEffect(() => {
        ApiUtil.queryApplicationDetail(Number(id)).then(d => {
            if (d.status === 2 || d.status === 3) {
                setIsRedirect(true)
            }
            setResponse(d)
        }).catch(e => handler(ActionFactory.error(e))).finally(hiddenLoading)
    }, [queryIndex, store.reload])

    const statusFormat = (status: number) => {
        switch (status) {
            case 0:
                return <FormattedMessage id="prompt_pending" />
            case 1:
                return <FormattedMessage id="prompt_status_pre_approved" />
            case 2:
                return <FormattedMessage id="prompt_status_declined" />
            case 3:
                return <FormattedMessage id="prompt_status_rejected" />
            case 4:
                return <FormattedMessage id="prompt_status_approved" />
            default:
                return <FormattedMessage id="prompt_pending" />
        }
    }
    function getColor(index: number): string {
        if (index === 0 || index === 5) {
            return colors.secondary;
        } if ( index === 1) {
            return colors.preSkyBlue;
        }else if (index === 4) {
            return colors.success;
        } else if (index === 3) {
            return 'red';
        }else {
            return colors.menuColor;
        }
    }

    const hiddenLoading = () => { setIsLoading(false) }
    if (isRedirect) {
        return (<Redirect to='/home'></Redirect>)
    }
    return (
        <div className={cls.root}>

            <div >
                <Grid container justify="space-between" alignItems="center" className={cls.titleStyle}>
                    <Grid item>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item>
                                <Typography className={cls.titleText}><FormattedMessage id='prompt_create_application_detail'></FormattedMessage></Typography>
                            </Grid>
                            <Grid item>
                                {
                                    response !== null && <Chip
                                        variant="outlined"
                                        style={{
                                            minWidth: 100,
                                            borderColor: getColor(response?.status!),
                                            color: getColor(response?.status!),
                                            fontSize: 14,
                                            height: 24,
                                        }}
                                        label={
                                            statusFormat(response?.status!)
                                        }
                                    ></Chip>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid>
                        <Button variant='outlined' className={cls.submitAction} color='primary' onClick={() => { setIsRedirect(true) }}><FormattedMessage id="prompt_back"></FormattedMessage></Button>
                    </Grid>
                </Grid>
            </div>
            <Divider variant='fullWidth'></Divider>
            {response !== null && <div className={cls.conentStyle}>
                <div className={cls.topStyle}>
                    <Grid container className={cls.topGridStyle} spacing={3} >
                        <Grid item xs={12} sm={12} md={12} lg={5} style={{ paddingLeft: 0, paddingBottom: 0 }} className={cls.gridPaddingRight}>
                            <Paper elevation={3} >
                                <Typography className={cls.subTitle}><FormattedMessage id='prompt_create_application_detail'></FormattedMessage></Typography>
                                <Grid container justify="space-between" alignItems="center" spacing={3} className={cls.GridStyle}>
                                    <Grid item xs={12} sm={5} md={5} lg={5}>
                                        <InputInfoComponent label='prompt_create_application_number' defaultValue={response?.applicationNo || '--'} isDefault={true} isApprov={true}></InputInfoComponent>
                                    </Grid>

                                    <Grid item xs={12} sm={5} md={5} lg={5}>
                                        <InputInfoComponent label='prompt_create_application_name' defaultValue={response?.applicantName || '--'} isDefault={true} isApprov={true}></InputInfoComponent>
                                    </Grid>
                                    <Grid item xs={12} sm={5} md={5} lg={5}>
                                        <InputInfoComponent label='prompt_create_application_email' defaultValue={response?.applicantMail || '--'} isDefault={true} isApprov={true}></InputInfoComponent>
                                    </Grid>
                                    <Grid item xs={12} sm={5} md={5} lg={5}>
                                        <InputInfoComponent label='prompt_create_application_po' defaultValue={response?.poNo || '--'} isDefault={true} isApprov={true}></InputInfoComponent>
                                    </Grid>
                                    <Grid item xs={12} sm={5} md={5} lg={5}>
                                        <InputInfoComponent label='prompt_create_application_hp' defaultValue={response?.hpNo || '--'} isDefault={true} isApprov={true}></InputInfoComponent>
                                    </Grid>
                                    <Grid item xs={12} sm={5} md={5} lg={5}>
                                        <InputInfoComponent label='prompt_create_application_ic' defaultValue={response?.icNo || '--'} isDefault={true} isApprov={true}></InputInfoComponent>
                                    </Grid>
                                    <Grid item xs={12} sm={5} md={5} lg={5}>
                                        <InputInfoComponent label='prompt_company_name' defaultValue={response?.companyName || '--'} isDefault={true} isApprov={true}></InputInfoComponent>
                                    </Grid>
                                    <Grid item xs={12} sm={5} md={5} lg={5} >
                                        <InputInfoComponent label='prompt_create_application_ctnumber' defaultValue={response?.picPhoneNo || '--'} isDefault={true} isApprov={true}></InputInfoComponent>
                                    </Grid>
                                    <Grid item xs={12} sm={5} md={5} lg={5}>
                                        <InputInfoComponent label='prompt_create_application_sbDate' defaultValue={commonFormatTime(response?.submitTime || 0)} isDefault={true} isApprov={true}></InputInfoComponent>
                                    </Grid>
                                    <Grid item xs={12} sm={5} md={5} lg={5} >
                                        <InputInfoComponent label='prompt_create_application_PICname' defaultValue={response.picName || "--"} isDefault={true} isApprov={true}></InputInfoComponent>
                                    </Grid>
                                    <Grid item xs={12} sm={5} md={5} lg={5}>
                                        <InputInfoComponent label='prompt_create_application_approveDate' defaultValue={commonFormatTime(response?.auditTime || 0)} isDefault={true} isApprov={true}></InputInfoComponent>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={7} style={{ paddingRight: 0, paddingBottom: 0 }} className={cls.gridPaddingLeft}>
                            <Paper elevation={3} style={{ height: '100%' }} >
                                <ApproveLockListComponent data={response?.authLockList || []} mesg={response.status === 0 || response.status === 5 ? "prompt_processingWaitingFor" : "prompt_approveWaitingFor"}></ApproveLockListComponent>
                            </Paper>
                        </Grid>
                    </Grid>
                </div>
                <Paper elevation={3} className={cls.topStyle}>
                    <Typography className={cls.subTitleBottom} ><FormattedMessage id='prompt_create_application_Workdetail'></FormattedMessage></Typography>
                    <WorkDetailTableComponent soure={response?.workList || []} status={0} ></WorkDetailTableComponent>
                </Paper>
                {
                    response?.attachmentList !== null && response?.attachmentList?.length !== 0 && <Paper elevation={3} className={cls.topStyle}>
                        <Typography className={cls.subTitle}><FormattedMessage id='prompt_create_application_documents'></FormattedMessage></Typography>
                        <Grid container alignItems="flex-start" className={cls.GridStyle} spacing={3}>
                            {
                                response!.attachmentList!.map(row => {
                                    return (<Grid item  > <DocThumbnailComponent isShow={false} url={row.url}></DocThumbnailComponent></Grid>)
                                })
                            }
                        </Grid>
                    </Paper>
                }
            </div>}
            <Loading open={isLoading} />
            <div style={{ height: 24 }}></div>
        </div>
    )
}