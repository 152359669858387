import { createMuiTheme } from '@material-ui/core/styles';

export const theme = createMuiTheme({
    palette: {
        primary: {
            main: "#ff6600",
            contrastText: "#fff"
        },
        secondary: {
            main: "#fdc527",
            contrastText: "#fff"
        },
        success: {
            main: "#3bc477",
            contrastText: "#fff"
        }
    },
    typography: {
        button: {
            textTransform: "none",
        },
        subtitle2: {
            fontWeight: 'bold',
            fontSize: 18
        },
        subtitle1: {
            fontSize: 18,
            display: "contents",
        },
        h5: {
            display: "contents"
        },
        h4: {
            display: "contents",
            fontWeight: 'bold'
        },
        fontFamily: [
            'Roboto',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    }
});

interface ThemColors {
    menuColor: string,
    divider: string,
    primaryDisable: string,
    primary: string,
    secondary: string,
    success: string,
    primaryLight: string,
    secondaryLight: string,
    textSeconday: string,
    whiteColor: string,
    secondaryNormal: string,
    primaryGradient: string,
    preSkyBlue: string,
}

export const colors = {
    menuColor: "#262834",
    secondary: "#fdc527",
    divider: "#d4d4d6",
    primaryDisable: "#ffe0cc",
    primary: "#ff6600",
    primaryLight: "#ffc299",
    secondaryLight: "#fee8a9",
    textSeconday: "#676870",
    success: "#3bc477",
    whiteColor: "#ffffff",
    secondaryNormal: "#929399",
    primaryGradient: 'linear-gradient(45deg, #ff6600, #fdc527)',
    preSkyBlue: '#4a90e2'
} as ThemColors;