import React from 'react';
import { Typography, makeStyles, createStyles, Button, Grid, Divider, Paper, Chip } from '@material-ui/core'
import { colors } from '../../../../common/theme'
import { FormattedMessage } from 'react-intl'
import { ApiUtil } from '../../../../common/api/api'
import { PorjectError } from '../../../../common/api/errorMessage'
import { Redirect, useParams, useLocation } from 'react-router-dom'
import { ApplicationDetail } from '../../../../common/api/model'
import { useProject, ActionFactory } from '../../../../common/component/provider'
import { Loading } from '../../../../common/component'
import { commonFormatTime } from '../../../../common/util'
import { InputInfoComponent, WorkDetailTableComponent, DocThumbnailComponent } from '../component'
const useStyle = makeStyles((theme) => createStyles({
    root: {
        flexGrow: 1,
    },

    titleStyle: {
        paddingLeft: theme.spacing(9),
        paddingRight: theme.spacing(9),
        height: 64
    },

    titleText: {
        fontSize: 20,
        fontWeight: 500,
        color: colors.menuColor
    },
    subTitle: {
        fontSize: 18,
        paddingTop: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        color: colors.menuColor,
        fontWeight: 500
    },

    subTitleBottom: {
        fontSize: 18,
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        color: colors.menuColor,
        fontWeight: 500,
        borderBottom: '0.5px solid #d4d4d6',
    },
    thirdTitle: {
        fontSize: 18,
        color: colors.menuColor,
        fontWeight: 500
    },

    GridStyle: {
        padding: 12,
        margin: 0,
        width: '100%'
    },

    conentStyle: {
        paddingLeft: theme.spacing(9),
        paddingRight: theme.spacing(9),
    },
    topStyle: {
        marginTop: theme.spacing(3)
    },

    submitAction: {
        fontSize: 16,
        borderRadius: 4,
        fontWeight: 500,
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 2,
        paddingBottom: 2
    },

    rejectSubtitle: {
        color: colors.primary,
        fontSize: 16,
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        wordBreak: 'break-all',

    },

    topGridStyle: {
        padding: 0,
        margin: 0,
        width: '100%'
    },

    textField: {
        fontSize: 16,
        color: colors.textSeconday,
        '& .MuiInputBase-input': {
            boxShadow: '0 0 0px 1000px white inset !important'
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: colors.divider,
            }
        },
        '& .MuiFormLabel-root': {
            color: '#b0b3ba'
        }
    },

    textStyle: {
        fontSize: 16,
        color: colors.textSeconday
    },


    contentText: {
        fontSize: 16,
        color: colors.textSeconday,
    },

}))

export function RejectedApplication() {
    const cls = useStyle()
    const { store, handler } = useProject()
    const { id } = useParams()
    const [isLoading, setIsLoading] = React.useState(true)
    const [queryIndex, setQueryIndex] = React.useState(0)
    const [isRedirect, setIsRedirect] = React.useState(false)
    const [response, setResponse] = React.useState<ApplicationDetail | null>(null)

    React.useEffect(() => {
        ApiUtil.queryApplicationDetail(Number(id)).then(d => {
            setResponse(d)
        }).catch(e => handler(ActionFactory.error(e))).finally(hiddenLoading)
    }, [queryIndex, store.reload])

    const hiddenLoading = () => { setIsLoading(false) }
    if (isRedirect) {
        return (<Redirect to='/home'></Redirect>)
    }


   
    return (
        <div className={cls.root}>
            <div >
                <Grid container justify="space-between" alignItems="center" className={cls.titleStyle}>
                    <Grid item>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item>
                                <Typography className={cls.titleText}><FormattedMessage id='prompt_create_application_detail'></FormattedMessage></Typography>
                            </Grid>
                            <Grid item>
                                {
                                    response !== null && <Chip
                                        variant="outlined"
                                        style={{
                                            minWidth: 100,
                                            borderColor: "red",
                                            color: "red",
                                            fontSize: 14,
                                            height: 24,
                                        }}
                                        label={
                                            <FormattedMessage id="prompt_status_rejected" />
                                        }
                                    ></Chip>
                                }
                            </Grid>
                        </Grid>

                    </Grid>
                    <Grid>
                        <Button variant='outlined' className={cls.submitAction} color='primary' onClick={() => { setIsRedirect(true) }}><FormattedMessage id="prompt_back"></FormattedMessage></Button>
                    </Grid>
                </Grid>
            </div>
            < Divider variant='fullWidth'></Divider>
            {
                response !== null && <div className={cls.conentStyle}>
                    <Paper elevation={3} className={cls.topStyle}>
                        <Typography className={cls.subTitle}><FormattedMessage id='prompt_Reject_reason'></FormattedMessage></Typography>
                        <Typography className={cls.rejectSubtitle}>{response.reason}</Typography>
                    </Paper>

                    <div className={cls.topStyle}>
                        <Grid container className={cls.topGridStyle} spacing={3} >
                            <Grid item xs={12} sm={12} md={5} lg={5} style={{ padding: 0 }}>
                                <Paper elevation={3} >
                                    <Typography className={cls.subTitle}><FormattedMessage id='prompt_create_application_detail'></FormattedMessage></Typography>
                                    <Grid container justify="space-between" alignItems="center" spacing={3} className={cls.GridStyle}>
                                        <Grid item xs={12} sm={5} md={5} lg={5}>
                                            <InputInfoComponent label='prompt_create_application_number' defaultValue={response!.applicationNo} isDefault={true} isApprov={true}></InputInfoComponent>
                                        </Grid>
                                        <Grid item xs={12} sm={5} md={5} lg={5}>
                                            <InputInfoComponent label='prompt_create_application_name' defaultValue={response!.applicantName} isDefault={true} isApprov={true}></InputInfoComponent>
                                        </Grid>
                                        <Grid item xs={12} sm={5} md={5} lg={5}>
                                            <InputInfoComponent label='prompt_create_application_email' defaultValue={response!.applicantMail} isDefault={true} isApprov={true}></InputInfoComponent>
                                        </Grid>
                                        <Grid item xs={12} sm={5} md={5} lg={5}>
                                            <InputInfoComponent label='prompt_create_application_po' defaultValue={response!.poNo || "--"} isDefault={true} isApprov={true}></InputInfoComponent>
                                        </Grid>
                                        <Grid item xs={12} sm={5} md={5} lg={5}>
                                            <InputInfoComponent label='prompt_create_application_hp' defaultValue={response!.hpNo || "--"} isDefault={true} isApprov={true}></InputInfoComponent>
                                        </Grid>
                                        <Grid item xs={12} sm={5} md={5} lg={5}>
                                            <InputInfoComponent label='prompt_create_application_ic' defaultValue={response!.icNo || "--"} isDefault={true} isApprov={true}></InputInfoComponent>
                                        </Grid>
                                        <Grid item xs={12} sm={5} md={5} lg={5}>
                                            <InputInfoComponent label='prompt_company_name' defaultValue={response!.companyName || "--"} isDefault={true} isApprov={true}></InputInfoComponent>
                                        </Grid>
                                        <Grid item xs={12} sm={5} md={5} lg={5}>
                                            <InputInfoComponent label='prompt_create_application_ctnumber' defaultValue={response!.picName || "--"} isDefault={true} isApprov={true}></InputInfoComponent>
                                        </Grid>
                                        <Grid item xs={12} sm={5} md={5} lg={5}>
                                            <InputInfoComponent label='prompt_create_application_sbDate' defaultValue={commonFormatTime(response!.submitTime || 0)} isDefault={true} isApprov={true}></InputInfoComponent>
                                        </Grid>
                                        <Grid item xs={12} sm={5} md={5} lg={5}>
                                            <InputInfoComponent label='prompt_create_application_PICname' defaultValue={response!.picName || "--"} isDefault={true} isApprov={true}></InputInfoComponent>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={12} md={7} lg={7} style={{ padding: '0px 0px 0px 24px' }}>
                                <Paper elevation={3} style={{ height: '100%' }}>
                                    <Typography className={cls.subTitleBottom} ><FormattedMessage id='prompt_create_application_Workdetail'></FormattedMessage></Typography>
                                    <WorkDetailTableComponent soure={response.workList!} status={0} ></WorkDetailTableComponent>
                                </Paper>
                            </Grid>
                        </Grid>
                    </div>

                    {
                        response.attachmentList !== null && response.attachmentList?.length !== 0 && <Paper elevation={3} className={cls.topStyle}>
                            <Typography className={cls.subTitle}><FormattedMessage id='prompt_create_application_documents'></FormattedMessage></Typography>
                            <Grid container alignItems="flex-start" className={cls.GridStyle} spacing={3}>
                                {
                                    response.attachmentList!.map(row => {
                                        return (<Grid item  > <DocThumbnailComponent isShow={false} url={row.url}></DocThumbnailComponent></Grid>)
                                    })
                                }
                            </Grid>
                        </Paper>
                    }
                </div>
            }
            <Loading open={isLoading} />
            <div style={{ height: 24 }}></div>
        </div>
    )
}