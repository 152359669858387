import React from "react";
import {
  Typography,
  makeStyles,
  createStyles,
  TextField,
  Divider,
  IconButton,
  Grid,
  CardHeader,
  Card,
  CardMedia,
  CardContent,
  Dialog,
  DialogContent,
  Button,
  InputAdornment,

  DialogActions,
  DialogTitle,
  Popover,
  TableBody,
  TableContainer,
  Table,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TableCell,
  TableRow,
  MenuItem,
  TableHead,
  Tooltip,
  Paper,
} from "@material-ui/core";
import { colors } from "../../../common/theme";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { FormattedMessage } from "react-intl";
import {
  CreateApplication,
  ApplicationLock,
  ApplicationWorkDetail,
  AuthTime,
  Substation,
} from "../../../common/api/model";
import ClearIcon from "@material-ui/icons/Clear";
import {
  DeleteIcon,
  CircleCancleIcon,
  CalendarIcon,
  EditIcon,
  Addcon,
} from "../../../common/component/icons";
import DayjsUtils from "@date-io/dayjs"
import { ApiUtil } from '../../../common/api/api'
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { commonFormatTime, CloudinaryThumbnail } from "../../../common/util";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { CustomLabelChip } from "../../../common/component";
import { useProject, ActionFactory } from "../../../common/component/provider";
import DocumentsIcon from "../../../images/DocumentsIcon.png";
// import { DropzoneAreaBase } from 'material-ui-dropzone'
import Dropzone, { FileRejection } from "react-dropzone";
import dayjs from "dayjs";
const useStyle = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      backgroundColor: "white",
    },
    textField: {
      fontSize: 16,

      "& .MuiInputBase-input": {
        boxShadow: "0 0 0px 1000px white inset !important",
        color: colors.textSeconday,
      },

      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: colors.divider,
        },
      },
      "& .MuiFormLabel-root": {
        color: "#b0b3ba",
      },
      // '& .MuiOutlinedInput-input': {
      //     paddingLeft: 10,
      //     paddingRight: 10
      // }
    },
    textStyle: {
      fontSize: 16,
      color: colors.textSeconday,
    },
    topTextStyle: {
      fontSize: 16,
      color: colors.textSeconday,
      paddingTop: 8,
    },
    defaultTyStyle: {
      // width: "100%",
      backgroundColor: "#e9e9ea",
      fontSize: 16,
      color: colors.textSeconday,
      borderRadius: 4,
      paddingTop: 8,
      paddingBottom: 8,
      textIndent: "1em",
      paddingRight: "1em"
    },
    // calc(100% + 8px)
    workChipStyle: {
      border: "solid 1px #d4d4d6",
      height: 104,
      borderRadius: 4,
      overflowY: "auto",
    },

    datePickerUndeline: {
      "& .MuiInput-underline:before": {
        border: 0,
      },
      "& .MuiInput-underline:after": {
        border: 0,
      },
    },
    selectTimeTextfield: {

      "& .MuiInputBase-input": {
        color: colors.secondaryNormal,
        fontSize: 13,
        paddingTop: 12,
        paddingRight: 12,
        paddingBottom: 12,
        paddingLeft: 12,
        "& * > input": {
          textalign: "center",
        },
      },
      borderRadius: 4,
      border: "1px solid rgba(0, 0, 0, 0.23)",
    },

    uploadBorderStyle: {
      border: "1px dashed rgba(0, 0, 0, 0.23)",
      height: 200,
      width: 500,
      borderRadius: 4,
    },
    uploadTitle: {
      color: colors.menuColor,
      fontSize: 18,
    },
    dateTitle: {
      color: colors.menuColor,
      fontSize: 16,
    },
    uploadContent: {
      color: colors.textSeconday,
      fontSize: 16,
      whiteSpace: "nowrap",
    },

    workDetailTitle: {
      color: colors.primary,
      fontSize: 16,
      borderRight: "0.5px solid #d4d4d6",
      maxWidth: 150,
      paddingBottom: 3,
      paddingTop: 3,
      paddingRight: 24,
    },
    workSubtitle: {
      color: colors.primary,
      fontSize: 16,
    },

    thdiv: {
      borderLeftColor: colors.divider,
      borderLeftWidth: "0.5px",
      borderLeftStyle: "solid",
      paddingLeft: 16,
      display: "inline-block",
      fontSize: 18,
      color: colors.menuColor,
    },

    textCellStyle: {
      width: "100%",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    GridStyle: {
      // padding: 12,
      margin: 0,
      width: "100%",
    },

    imgCls: {
      backgroundColor: "red",
    },
  })
);

interface InputInfoProp {
  label: string;
  defaultValue?: string;
  isDefault: boolean;
  isApprov: boolean;
  onChange?: (text: string) => void;
  maxLength?: number;
}


export const InputInfoComponent = (prop: InputInfoProp) => {
  const cls = useStyle();
  const handleOnchange = (event: React.ChangeEvent<HTMLInputElement>) => {
    !prop.isDefault && prop.onChange!(event.target.value);
  };
  const defaultValueFormatt = () => {
    if (prop.defaultValue === undefined || prop.defaultValue.length === 0) {
      return "--"
    }
    return prop.defaultValue
  }
  return (
    <Grid container alignItems="center" justify="space-between" spacing={1} className={cls.GridStyle}>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={prop.isApprov ? 12 : 3}
        style={{ paddingRight: 0, paddingLeft: 0 }}
      >
        <Typography className={cls.textStyle}>
          <FormattedMessage id={prop.label}></FormattedMessage>
          {/* <span style={{color: 'red'}}>*</span> */}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={prop.isApprov ? 12 : 9}
        style={{ paddingRight: 0, paddingLeft: 0 }}
      >
        {prop.isDefault ? (
          <Tooltip arrow title={defaultValueFormatt()}>
            <Typography noWrap className={cls.defaultTyStyle}>
              {defaultValueFormatt()}
            </Typography>
          </Tooltip>
        ) : (
            <TextField
              size="small"
              className={cls.textField}
              // id="emailtextfield"
              fullWidth
              autoComplete="off"
              variant="outlined"
              defaultValue={prop.defaultValue}
              inputProps={{
                maxLength: prop.maxLength,
              }}
              // onFocus={}
              // error={}
              // helperText={}
              onChange={handleOnchange}
            />
          )}
      </Grid>
    </Grid>
  );
};

interface InputWorkTypeProp {
  data: string[];
  value: { a: string | null; b: string };
  onChange: (text: string | null) => void;
  onInputChange: (text: string) => void;
  onSave: () => void
  isError: boolean
  onFocuError: () => void
}

export const InputWorkTypeComponent = (prop: InputWorkTypeProp) => {
  const cls = useStyle();
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={12} md={12} lg={3}>
        <Typography className={cls.topTextStyle}>
          <FormattedMessage id="prompt_create_application_typeWork"></FormattedMessage>
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={9}>
        <Grid container>
          <Grid item xs={11} sm={11} md={11} lg={11}>
            <Autocomplete
              // id="combo-box-demo"
              freeSolo
              fullWidth
              options={prop.data}
              value={prop.value.a}
              inputValue={prop.value.b}
              getOptionLabel={(option) => option}
              onChange={(event: any, newValue: string | null) => {
                prop.onChange(newValue);
              }}
              onInputChange={(event, newInputValue) => {
                prop.onInputChange(newInputValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password",
                  }}
                  variant="outlined"
                  error={prop.isError}
                  helperText={prop.isError && "Please select type of works"}
                  onFocus={prop.onFocuError}
                  size="small"
                  className={cls.textField}
                />
              )}
            />
          </Grid>
          <Grid item xs={1} sm={1} md={1} lg={1}>
            <IconButton onClick={prop.onSave}>
              <Addcon fontSize="small"></Addcon>
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

interface WorkDetailTableProp {
  soure: ApplicationWorkDetail[];
  status: 0 | 1 | 2;
  setHeight?: number;
  onClickRow?: (index: number, isDel: 1 | 2) => void;
}

export const WorkDetailTableComponent = (prop: WorkDetailTableProp) => {
  const cls = useStyle();

  return (
    <TableContainer style={{ height: prop.setHeight }}>
      <Table aria-label="sticky table list" style={{ tableLayout: "fixed" }}>
        <TableBody>
          {prop.soure.map((row) => {
            return (
              <TableRow key={row.id || prop.soure.indexOf(row)}>
                <TableCell
                  style={{
                    width: 150,
                    paddingLeft: 24,
                    paddingRight: 24,
                    paddingBottom: 0,
                    paddingTop: 0,
                  }}
                >
                  <Tooltip title={row.substationName} arrow>
                    <Typography className={cls.workDetailTitle} noWrap>
                      {row.substationName}
                    </Typography>
                  </Tooltip>
                </TableCell>
                <TableCell
                  className={cls.textCellStyle}
                  style={{ paddingLeft: 0, paddingBottom: 0, paddingTop: 0 }}
                >
                  <p>
                    <span className={cls.dateTitle}>
                      <FormattedMessage id="prompt_create_application_dateEntry" />
                    </span>
                    &nbsp;&nbsp;
                    <span className={cls.uploadContent}>

                      {`${commonFormatTime(
                        row.beginTime,
                        "YYYY-MM-DD"
                      )}~${commonFormatTime(row.endTime, "YYYY-MM-DD")}`}
                    </span>
                    <br />
                    <span className={cls.dateTitle}>
                      <FormattedMessage id="prompt_create_application_typeWork" />
                    </span>
                    &nbsp;&nbsp;
                    <span className={cls.uploadContent}>
                      {row.typeOfWorks.join(" / ")}
                    </span>
                  </p>
                </TableCell>

                <TableCell
                  style={{ width: 100, paddingBottom: 0, paddingTop: 0 }}
                >
                  <div style={{ display: "flex" }}>
                    {prop.status === 2 && (
                      <IconButton
                        onClick={() => {
                          prop.onClickRow!(prop.soure.indexOf(row), 2);
                        }}
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                    )}
                    {prop.status !== 0 && (
                      <IconButton
                        onClick={() => {
                          prop.onClickRow!(prop.soure.indexOf(row), 1);
                        }}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    )}
                  </div>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

interface SubstationSelectProp {
  defalutValue: string | null;
  data: string[];
  onChange: (text: string | null) => void
  isError: boolean
  onFocuError: () => void
}

export const InputSelectComponent = (prop: SubstationSelectProp) => {
  const cls = useStyle();
  return (
    <Grid
      container
      // alignItems="center"
      justify="space-between"
      spacing={1}
      className={cls.GridStyle}
    >
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={3}
        style={{ paddingRight: 0, paddingLeft: 0 }}
      >
        <Typography className={cls.topTextStyle}>
          <FormattedMessage id="prompt_substations"></FormattedMessage>
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={9}
        style={{ paddingRight: 0, paddingLeft: 0 }}
      >
        <Autocomplete
          // id="combo-box-demo"
          options={prop.data}
          value={prop.defalutValue}
          // onFocus={prop.onFocuError}
          onChange={(event: any, newValue: string | null) => {
            prop.onChange(newValue);
          }}
          getOptionLabel={(option) => option}
          renderInput={(params) => (
            <TextField
              {...params}
              inputProps={{
                ...params.inputProps,
                autoComplete: "new-password",
              }}
              error={prop.isError}
              helperText={prop.isError && "Please select a substaion"}
              onFocus={prop.onFocuError}
              variant="outlined"
              size="small"
              className={cls.textField}
            />
          )}
        />

      </Grid>
    </Grid>
  );
};

interface WorkTypeListInterface {
  data: string[];
  onClickDelete: (text: string) => void;
}

export const WorkTypeListComponet = (prop: WorkTypeListInterface) => {
  const cls = useStyle();

  return (
    <div className={cls.workChipStyle}>
      <Grid container spacing={2} style={{ padding: 10 }}>
        {prop.data.map((row) => {
          return (
            <Grid item key={prop.data.indexOf(row)}>
              <CustomLabelChip
                onDelete={() => {
                  prop.onClickDelete(row);
                }}
                label={row}
                deleteIcon={
                  <CircleCancleIcon
                    fontSize="small"
                    style={{ paddingLeft: 30 }}
                  />
                }
              />
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

interface WorkDatListInterface {
  time: {
    begin: MaterialUiPickersDate | null;
    end: MaterialUiPickersDate | null;
  };

  onSave: (time: {
    begin: MaterialUiPickersDate | null;
    end: MaterialUiPickersDate | null;
  }) => void;
  isError: [boolean, boolean]
  onFocuError: () => void
}

export const WorkDateComponet = (prop: WorkDatListInterface) => {
  const cls = useStyle();
  const { handler } = useProject();

  const selectBeginTime = (date: MaterialUiPickersDate | null) => {
    const newdate = date?.set('hour', 0).set('minute', 0).set('second', 0) || null
    console.log(newdate?.unix())
    if (date === null || prop.time.end == null) {
      prop.onSave({ ...prop.time, begin: newdate });
    } else {
      if (prop.time.end!.isBefore(newdate!)) {
        handler(
          ActionFactory.error({
            code: 500000,
            message: "hint_end_time_after_begin_time",
          })
        )
      } else {
        prop.onSave({ ...prop.time, begin: newdate })
      }
    }
  };

  const selectEndTime = (date: MaterialUiPickersDate | null) => {
    const newdate = date?.set('hour', 23).set('minute', 59).set('second', 59) || null
    if (date === null || prop.time.begin === null) {
      prop.onSave({ ...prop.time, end: newdate });
    } else {
      if (newdate!.isBefore(prop.time.begin)) {
        handler(
          ActionFactory.error({
            code: 500000,
            message: "hint_end_time_after_begin_time",
          })
        )
      } else {
        prop.onSave({ ...prop.time, end: newdate })
      }
    }
    
    // if (prop.time.begin == null) {
    //   prop.onSave({ ...prop.time, end: newdate });
    // } else if (newdate == null) {
    //   prop.onSave({ ...prop.time, end: newdate });
    // } else {
    //   let begin = prop.time.begin!.unix()
    //   let end = newdate!.unix()
    //   if (begin > end) {
        
    //   } else {
        
    //   }
    // }
  };

  return (
    <Grid
      container
      // alignItems="center"
      justify="space-between"
      spacing={1}
      className={cls.GridStyle}
    >
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={3}
        style={{ paddingLeft: 0, paddingRight: 0 }}
      >
        <Typography style={{ fontSize: 16, color: colors.textSeconday, paddingTop: 13, }}>
          <FormattedMessage id="prompt_create_application_date"></FormattedMessage>
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={9} style={{ paddingLeft: 0 }}>
        <Grid
          container
          justify="space-between"
          // alignItems="center"
          spacing={1}
          className={cls.GridStyle}
        >
          <Grid item xs={5} sm={5} md={5} lg={5} style={{ paddingLeft: 0 }}>
            <MuiPickersUtilsProvider utils={DayjsUtils}>
              <DatePicker
                className={cls.datePickerUndeline}
                // disablePast
                clearable
                size="small"
                fullWidth
                error={prop.isError[0]}
                helperText={prop.isError[0] && 'please select start time'}
                onFocus={prop.onFocuError}
                emptyLabel="Start time"
                value={prop.time.begin}
                // ampm={false}
                format="YYYY-MM-DD"
                InputProps={{
                  className: cls.selectTimeTextfield,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton>
                        <CalendarIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onChange={selectBeginTime}
              />
            </MuiPickersUtilsProvider>

          </Grid>
          <Grid
            item
            xs={1}
            sm={1}
            md={1}
            lg={1}
          >
            <Typography style={{ fontSize: 13, paddingTop: 10, paddingLeft: 15 }}>
              <FormattedMessage id="prompt_to"></FormattedMessage>
            </Typography>

          </Grid>

          <Grid item xs={5} sm={5} md={5} lg={5} style={{ paddingRight: 0 }}>
            <MuiPickersUtilsProvider utils={DayjsUtils}>
              <DatePicker

                className={cls.datePickerUndeline}
                clearable
                fullWidth
                emptyLabel="End time"
                format="YYYY-MM-DD"
                value={prop.time.end}
                error={prop.isError[1]}
                helperText={prop.isError[1] && 'please select end time'}
                onFocus={prop.onFocuError}
                onChange={selectEndTime}
                InputProps={{
                  className: cls.selectTimeTextfield,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton>
                        <CalendarIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

interface AddWorkDetailProp {
  queryIndex: number;
}

export const AddWorkDetailComponent = (prop: AddWorkDetailProp) => { };

interface EditWorkDetailProp {
  queryIndex: number;
  model: ApplicationWorkDetail | null;
  isOpen: boolean;
  onClose: () => void;
  onSave: (model: ApplicationWorkDetail) => void;
}

export const EditWorkDetailComponent = (prop: EditWorkDetailProp) => {

  const [subData, setSubData] = React.useState<Substation[]>([])
  const { handler } = useProject();
  const [workTypeData, setworkTypeData] = React.useState<string[]>([]);
  const [addWork, setAddWork] = React.useState<{
    substation: string | null;
    workType: string | null;
    workData: string[];
  }>({
    substation: null,
    workType: null,
    workData: [],
  });

  const [inputValue, setInputValue] = React.useState<{
    a: string | null;
    b: string;
  }>({
    a: null,
    b: "",
  });

  const [workDate, setWorkDate] = React.useState<{
    begin: MaterialUiPickersDate | null;
    end: MaterialUiPickersDate | null;
  }>({
    begin: null,
    end: null,
  })
  const { store } = useProject()
  const [errorSub, setErrorSub] = React.useState(false)
  const [errorWorkType, setErrorWorkType] = React.useState(false)
  const [errorDate, setErrorDate] = React.useState<[boolean, boolean]>([false, false])

  const onClickSaveWorkType = () => {
    if (addWork.workData.length >= 5) {
      handler(ActionFactory.error({ code: 500000, message: "prompt_create_application_addwork" }))
    } else if (
      addWork.workType !== null &&
      addWork.workType!.length !== 0 &&
      !addWork.workData.includes(addWork.workType!)
    ) {
      setAddWork((prev) => {
        return { ...prev, workData: [...prev.workData, prev.workType!] };
      });
    }
  };

  const onClickDeleteWorkType = (text: string) => {
    setAddWork((prev) => {
      return { ...prev, workData: prev.workData.filter((row) => row !== text) };
    });
  };

  const canAddWork = () => {
    let isError = true
    if (addWork.substation === null || addWork.substation.length === 0) {
      isError = false
      setErrorSub(true)
    }
    if (addWork.workData.length === 0) {
      isError = false
      setErrorWorkType(true)
    }
    if (workDate.begin === null) {
      isError = false
      setErrorDate([true, errorDate[1]])
    }
    if (workDate.end === null) {
      isError = false
      setErrorDate([errorDate[0], true])
    }
    return isError
  }

  const onClickAddWork = () => {
    if (canAddWork()) {
      let sub = subData.filter((row) => row.substationName === addWork.substation)[0]
      let wk: ApplicationWorkDetail = {
        id: prop.model?.id,
        substationId: sub.id,
        substationName: sub.substationName,
        beginTime: workDate.begin!.unix(),
        endTime: workDate.end!.unix(),
        typeOfWorks: addWork.workData
      }
      prop.onSave(wk);
    }
  };

  React.useEffect(() => {
    setAddWork({
      substation: prop.model?.substationName || null,
      workType: null,
      workData: prop.model?.typeOfWorks || [],
    });
    setWorkDate({
      begin: prop.model !== null ? dayjs(prop.model!.beginTime * 1000) : null,
      end: prop.model !== null ? dayjs(prop.model!.endTime * 1000) : null,
    })

    setInputValue({ a: null, b: '' })
  }, [prop.queryIndex]);

  React.useEffect(() => {

    ApiUtil.querySubstations().then(d => {
      setSubData(d.list)
    }).catch(e => handler(ActionFactory.error(e)))

    ApiUtil.queryWorkType().then(d => {
      let x = d.map(row => row.workTypeName)
      setworkTypeData(x)
    }).catch(e => handler(ActionFactory.error(e)))

  }, [store.reload]);

  return (
    <Dialog maxWidth="sm" fullWidth open={prop.isOpen} onClose={prop.onClose}>
      <DialogTitle
        style={{
          backgroundColor: "white",
          color: colors.menuColor,
          paddingTop: 5,
          paddingBottom: 5,
          paddingLeft: 12,
          paddingRight: 12,
        }}
      >
        <Grid
          container
          justify="space-between"
          alignItems="center"
        // style={{ lineHeight: 1 }}
        >
          <Grid item style={{ fontSize: 18, color: colors.menuColor }}>
            <FormattedMessage id="prompt_create_application_editwork" />
          </Grid>
          <Grid item>
            <IconButton onClick={prop.onClose} style={{ padding: 0 }}><ClearIcon style={{ color: colors.menuColor }} /></IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider variant="fullWidth"></Divider>
      <DialogContent>
        <Grid container spacing={3} style={{ paddingTop: 4, margin: 0, width: '100%' }}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <InputSelectComponent
              isError={errorSub}
              onFocuError={() => { setErrorSub(false) }}
              data={subData.map((row) => row.substationName)}
              onChange={(text) => {
                setAddWork({ ...addWork, substation: text });
              }}
              defalutValue={addWork.substation}
            ></InputSelectComponent>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <WorkDateComponet
              isError={errorDate}
              onFocuError={() => { setErrorDate([false, false]) }}
              time={workDate}
              onSave={(time) => {
                setWorkDate(time);
              }}
            ></WorkDateComponet>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <InputWorkTypeComponent
              isError={errorWorkType}
              onFocuError={() => { setErrorWorkType(false) }}
              data={workTypeData}
              value={inputValue}
              onChange={(text) => {
                setInputValue({ ...inputValue, a: text });
                setAddWork({ ...addWork, workType: text });
              }}
              onInputChange={(text) => {
                setInputValue({ ...inputValue, b: text });
                setAddWork({ ...addWork, workType: text });
              }}
              onSave={onClickSaveWorkType}
            ></InputWorkTypeComponent>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              spacing={1}
            >
              <Grid item xs={12} sm={12} md={12} lg={3}></Grid>
              <Grid item xs={12} sm={12} md={12} lg={9}>
                <WorkTypeListComponet
                  data={addWork.workData}
                  onClickDelete={onClickDeleteWorkType}
                ></WorkTypeListComponet>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={onClickAddWork}
          style={{ margin: "0 8px 5px 0" }}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

interface UploadDocProp {
  disabled: boolean;
  onDropAccepted: (file: File[]) => void;
}

export const UploadDocComponent = (prop: UploadDocProp) => {
  const cls = useStyle();
  const { handler } = useProject();

  const onDropRejected = (reject: FileRejection) => {
    let text = reject.errors[0].code;
    if (text === "file-invalid-type") {
      handler(ActionFactory.errorMessage("prompt_fileNotType"));
    } else if (text === "file-too-large") {
      handler(ActionFactory.errorMessage("prompt_fileTooLarge"));
    } else {
      handler(ActionFactory.errorMessage("prompt_fileTooLarge"));
    }
  };
  return (
    <Dropzone
      accept={["image/*", "application/pdf"]}
      maxSize={10485760}
      disabled={prop.disabled}
      onDrop={(acceptedFiles) => {
      }}
      onDropRejected={(fileRejections, event) => {
        onDropRejected(fileRejections[0]);
      }}
      onDropAccepted={(files, event) => {
        prop.onDropAccepted(files);
      }}
    >
      {({ getRootProps, getInputProps }) => (
        <section>
          <Grid
            container
            {...getRootProps()}
            className={cls.uploadBorderStyle}
            alignItems="center"
            justify="center"
            direction="row"
            spacing={3}
          >
            <input {...getInputProps()} />
            <Grid item>
              <img
                src={DocumentsIcon}
                style={{ width: 72, height: 62 }}
                alt="logo"
              ></img>
            </Grid>
            <Grid item>
              <Typography className={cls.uploadTitle}>
                Upload document <br />
                <span className={cls.uploadContent}>
                  Drag & drop, or click to select. Max 10MB <br /> JPG. / PNG. /
                  PDF.
                </span>
              </Typography>
            </Grid>
          </Grid>
        </section>
      )}
    </Dropzone>
  );
};

interface ApproveLockListProp {
  data: ApplicationLock[]
  mesg?: string
}

export const ApproveLockListComponent = (prop: ApproveLockListProp) => {
  const cls = useStyle()
  const Tool = (data: AuthTime[]) => {
    return (
      <div >
        <List component="nav" aria-label="main mailbox folders" style={{ padding: 0 }} >
          {
            data.map(item =>
              <ListItem style={{ padding: 0 }}>
                <ListItemText >
                  {`${commonFormatTime(
                    item.beginTime,
                    "YYYY-MM-DD"
                  )}~${commonFormatTime(item.endTime, "YYYY-MM-DD")}`}
                </ListItemText>
              </ListItem>
            )
          }
        </List>
      </div>
    )
  }

  const formatTimesList = (data: AuthTime[]) => {
    return data.map(item => { return `${commonFormatTime(item.beginTime, "YYYY-MM-DD")}~${commonFormatTime(item.endTime, "YYYY-MM-DD")}` }).join(', ')
  }
  return (
    <TableContainer>
      <Table
        aria-label="sticky table list"
        style={{ tableLayout: "auto" }}
        stickyHeader
      >
        <colgroup>
          <col style={{ width: "30%" }} />
          <col style={{ width: "70%" }} />
        </colgroup>
        <TableHead>
          <TableRow>
            <TableCell
              style={{ backgroundColor: "white", borderTopLeftRadius: 4 }}
            >
              <span className={cls.uploadTitle}>
                <FormattedMessage id="prompt_locks" />
              </span>
            </TableCell>

            <TableCell
              style={{
                backgroundColor: "white",
                paddingLeft: 0,
                borderTopRightRadius: 4,
              }}
            >
              <span className={cls.thdiv}>
                <FormattedMessage id="prompt_temporary_access" />
              </span>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            prop.data.length !== 0 ? prop.data.map((row) => {
              return (
                <TableRow hover>
                  <TableCell>
                    <div className="formatName3">
                      <Tooltip arrow title={row.lockName}>
                        <Typography noWrap className={cls.textStyle}>
                          {row.lockName}
                        </Typography>
                      </Tooltip>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="formatName3">
                      <Tooltip arrow title={Tool(row.times)}>
                        <Typography noWrap className={cls.textStyle}>
                          {formatTimesList(row.times)}
                        </Typography>
                      </Tooltip>

                    </div>
                  </TableCell>

                </TableRow>
              );
            }) : <TableRow style={{ height: 641 }}>
                <TableCell colSpan={2} align='center'>
                  <div className="formatName3" style={{ color: colors.primary, fontSize: 18 }}>
                    <FormattedMessage id={prop.mesg!} />
                  </div>
                </TableCell>
              </TableRow>
          }

        </TableBody>
      </Table>
    </TableContainer>
  );
};

interface DocThumbnailProp {
  url: string;
  isShow: boolean;
  onDelete?: (text: string) => void;
}

export const DocThumbnailComponent = (prop: DocThumbnailProp) => {
  const height = prop.isShow ? 160 : 140;
  const data = CloudinaryThumbnail(prop.url, 120, height);
  const cls = useStyle();

  return (
    <Card style={{ width: 120, height: 200 }} component={Paper} elevation={3}>
      {prop.isShow && (
        <CardHeader
          style={{ padding: 5 }}
          action={
            <IconButton
              style={{ padding: 0 }}
              onClick={() => {
                prop.onDelete!(prop.url);
              }}
            >
              <CircleCancleIcon fontSize="small" />
            </IconButton>
          }
        />
      )}
      <CardMedia style={{ padding: 0 }}>
        <a href={data.url} target="_blank" rel="noopener noreferrer">
          <img
            src={data.thumbnail}
            alt={data.name}
            width="120px"
            height={prop.isShow ? "140px" : "160px"}
          />
        </a>
      </CardMedia>
      <CardContent style={{ paddingBottom: 0, paddingTop: 5 }}>
        <Tooltip title={data.name} arrow>
          <Typography
            noWrap
            className={cls.textStyle}
            style={{ maxWidth: 120 }}
            align="center"
          >
            {data.name}
          </Typography>
        </Tooltip>
      </CardContent>
    </Card>
  );
};
