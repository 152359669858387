import { LoginUser, Group, UpdatePersonalInfo } from "../api/model";
import { defaultAvatar } from "../component/icons";
import { List, Map } from "immutable";
import CryptoJS from 'crypto-js';
import { username } from "../util";

const KEY_USER = 'user'
const KEY_ACCESS_TOKEN = 'token'
const KEY_REFRESH_TOKEN = 'refresh_token'


function encript(message: string): string {
    return CryptoJS.AES.encrypt(message, process.env.REACT_APP_STORE_AES_KEY!!).toString();
}

function decript(message: string): string {
    return CryptoJS.AES.decrypt(message, process.env.REACT_APP_STORE_AES_KEY!!).toString(CryptoJS.enc.Utf8);
}

export class StoreUtil {
    private static store: Storage
    static init(store: Storage) {
        this.store = store;
    }

    static hasLogin(): boolean {
        let user = this.user()
        let token = this.store.getItem(KEY_ACCESS_TOKEN)
        return user != null && token != null
    }

    static token(): string | null {
        return this.store.getItem(KEY_ACCESS_TOKEN);
    }

    static refreshToken(): string | null {
        return this.store.getItem(KEY_REFRESH_TOKEN);
    }

    static saveUser(user: LoginUser): LoginUser {
        this.store.clear()
        this.store.setItem(KEY_USER, encript(JSON.stringify(user)));
        this.store.setItem(KEY_ACCESS_TOKEN, user.accessToken);
        return user;
    }

    static clearUser() {
        this.store.clear()
    }

    static saveToken(token: string, refreshToken: string) {
        this.store.setItem(KEY_ACCESS_TOKEN, token);
        this.store.setItem(KEY_REFRESH_TOKEN, refreshToken);
    }

    static user(): LoginUser | null {
        let userString = this.store.getItem(KEY_USER);
        if (userString) {
            try {
                return JSON.parse(decript(userString));
            } catch (error) {
                this.store.clear();
                return null
            }
        }
        return null;
    }


    static userEmail(): string | null {
        return this.user()?.mail || '--'
    }

    static usercompany(): string | null {
        return this.user()?.companyName || '--'
    }

    static username(): string {
        const user = this.user()
        if (user) {
            return username(user)
        }
        return "--"
    }

    static userAvatar(): string {
        const user = this.user()
        if (user) {
            return user.photoUrl || defaultAvatar
        }
        return defaultAvatar
    }


    static updateSelfInfo(info: UpdatePersonalInfo) {
        const user = this.user()
        if (user !== null) {
            // const newUser:LoginUser = {
            //     id: user.id,
            //     accessToken: user.accessToken,
            //     refreshToken: user.refreshToken,
            //     mail: user.mail,
            //     permissionType: user.permissionType,
            //     firstName: info.firstName,
            //     lastName: info.lastName,
            //     langType: user.langType,
            //     phone: info.phone,
            //     photoUrl: user.photoUrl,
            //     groups: user.groups,
            //     permissions: user.permissions,
            //     staffId: info.staffId,
            //     complete: user.complete, 
            //     corp: user.corp
            // }
            // this.saveUser(newUser);
        }
    }
    static updateUserVerifyPhoneStatus(info: boolean) {
        const user = this.user();
        if (user !== null) {
            const newUser: LoginUser = {
                id: user.id,
                accessToken: user.accessToken,
                photoUrl: user.photoUrl,
                mail: user.mail,
                firstName: user.firstName,
                companyName: user.companyName,
                lastName: user.lastName,
                phone: user.phone,
                logoUrl: user.logoUrl,
                isVendorUser: user.isVendorUser,
                isVerifyPhone: info
            };
            this.saveUser(newUser);
        }
    }
}