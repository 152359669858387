import React from 'react';
import { Typography, makeStyles, createStyles, Button, Grid, Divider, Paper, Chip } from '@material-ui/core'
import { colors } from '../../../../common/theme'
import { FormattedMessage, useIntl } from 'react-intl'
import { ApiUtil } from '../../../../common/api/api'
import { Redirect, useParams } from 'react-router-dom'
import { Loading, ShowDialogAction } from '../../../../common/component'
import { useProject, ActionFactory } from '../../../../common/component/provider'
import { commonFormatTime, CommonLength } from '../../../../common/util'
import { ApplicationDetail, ApplicationWorkDetail, CreateApplication, Attachment } from '../../../../common/api/model'
import { InputInfoComponent, WorkDetailTableComponent, EditWorkDetailComponent, DocThumbnailComponent, UploadDocComponent, } from '../component'
const useStyle = makeStyles((theme) => createStyles({
    root: {
        flexGrow: 1,
    },

    titleStyle: {
        paddingLeft: theme.spacing(9),
        paddingRight: theme.spacing(9),
        height: 64
    },

    titleText: {
        fontSize: 20,
        fontWeight: 500,
        color: colors.menuColor
    },
    subTitle: {
        fontSize: 18,
        paddingTop: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        color: colors.menuColor,
        fontWeight: 500
    },

    subTitleBottom: {
        fontSize: 18,
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        color: colors.menuColor,
        fontWeight: 500,
        borderBottom: '0.5px solid #d4d4d6',
    },
    thirdTitle: {
        fontSize: 18,
        color: colors.menuColor,
        fontWeight: 500
    },

    GridStyle: {
        padding: 12,
        margin: 0,
        width: '100%'
    },

    conentStyle: {
        paddingLeft: theme.spacing(9),
        paddingRight: theme.spacing(9),
    },
    topStyle: {
        marginTop: theme.spacing(3)
    },

    submitAction: {
        fontSize: 16,
        borderRadius: 4,
        fontWeight: 500,
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 2,
        paddingBottom: 2
    },

    topGridStyle: {
        paddingTop: 24,
        paddingLeft: 24,
        paddingBottom: 12,
        paddingRight: 24,
        margin: 0,
        // width: '100%'
    },

    rejectSubtitle: {
        color: colors.primary,
        fontSize: 16,
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        wordBreak: 'break-all',
    },

    textField: {
        fontSize: 16,
        color: colors.textSeconday,
        '& .MuiInputBase-input': {
            boxShadow: '0 0 0px 1000px white inset !important'
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: colors.divider,
            }
        },
        '& .MuiFormLabel-root': {
            color: '#b0b3ba'
        }
    },

    textStyle: {
        fontSize: 16,
        color: colors.textSeconday
    },


    contentText: {
        fontSize: 16,
        color: colors.textSeconday,
    },

}))


export function DeclinedApplication() {
    const cls = useStyle()
    const { id } = useParams()
    const { store, handler } = useProject()
    const { formatMessage } = useIntl()
    const [isLoading, setIsLoading] = React.useState(true)
    const [queryIndex, setQueryIndex] = React.useState(0)
    const [isRedirect, setIsRedirect] = React.useState(false)
    const [response, setResponse] = React.useState<ApplicationDetail | null>(null)
    const [deleteTokens, setDeleteTokens] = React.useState<string[]>([])
    const [editWork, setEditWork] = React.useState<{ index: number, status: 0 | 1 | 2 | 3 }>({
        index: 0,
        status: 0
    }) // delte 1   edit 2  add 3
    React.useEffect(() => {
        ApiUtil.queryApplicationDetail(Number(id)).then(d => {
            if (d.status !== 2) {
                setIsRedirect(true)
            }
            setResponse(d)
        }).catch(e => handler(ActionFactory.error(e))).finally(hiddenLoading)
    }, [store.reload])

    const hiddenLoading = () => { setIsLoading(false) }

    const onClickSubmit = () => {
        if (canSubmit()) {
            
            setIsLoading(true)
            let model: CreateApplication = {
                id: response!.id,
                companyName: response!.companyName,
                hpNo: response!.hpNo,
                icNo: response!.icNo,
                poNo: response!.poNo,
                workList: response!.workList,
                attachmentList: response?.attachmentList!
            }
            ApiUtil.createApplication(model).then(d => {
                setIsRedirect(true)
                deleteTokendsAPI()
            }).catch(e => handler(ActionFactory.error(e))).finally(hiddenLoading)
        }
    }

    const canSubmit = () => {
        // todo
        if (response!.workList.length === 0) {
            return false
        }
        return true
    }

    const onClickWorkDetail = (index: number, status: 1 | 2) => {  //1 删除 2 编辑
        setQueryIndex(queryIndex + 1)
        setEditWork({ index: index, status: status })
    }

    const onSaveEdit = (model: ApplicationWorkDetail) => {
        const data = response?.workList!
        if (editWork.status === 2) {
            data.splice(editWork.index, 1, model)
        } else {
            data.unshift(model)
        }
        setEditWork({ ...editWork, status: 0 })
        setResponse(prev => { return { ...prev!, works: data } })
    }

    const onDeleteWork = () => {
        setEditWork({ ...editWork, status: 0 })
        setResponse(prev => { return { ...prev!, works: response?.workList!.splice(editWork.index, 1) } })
    }

    const onDropAccepted = (files: File[]) => {
        if (response!.attachmentList!.length >= CommonLength.uploadUrlMax) {
            handler(ActionFactory.errorMessage('prompt_fileTooMore'))
        } else {
            // if (count) {
            //     handler(ActionFactory.errorMessage('prompt_fileTooMore'))
            // }
            setIsLoading(true)
            const data = response!.attachmentList || []
            let count = 3 - data.length
            for (let index = 0; index < files.length; index++) {
                if (index === count) {
                    break
                }
                const item = files[index]
                ApiUtil.uploadCloudaryFile(item).then(r => {
                    let md: Attachment = { name: r.original_filename, url: r.secure_url, deleteToken: r.delete_token }
                    data.unshift(md)
                    setResponse(pre => { return { ...pre!, attachmentList: data } })
                    setIsLoading(false)
                }).catch(error => {
                    setIsLoading(false)
                    handler(ActionFactory.error({ code: 500000, message: "prompt_upload_failed" }))
                })
            }
        }
    }

    const onDeleteFile = (text: string) => {
        // setIsLoading(true)
        const x = response!.attachmentList!.filter(item => item.url === text)[0]
        const y = response!.attachmentList!.filter(item => item.url !== text)

        deleteTokens.push(x.deleteToken)
        setDeleteTokens(deleteTokens)

        setResponse(pre => { return { ...pre!, attachmentList: y } })
    }

    const deleteTokendsAPI = () => {
        for (let index = 0; index < deleteTokens.length; index++) {
            const element = deleteTokens[index]
            ApiUtil.deleteCloudaryFile(element).then(r => {
            }).catch(error => {
                // handler(ActionFactory.error({ code: 500000, message: "prompt_file_deleteFail"}))
            })
        }
    }



    const onClickAddWork = () => {
        setQueryIndex(queryIndex + 1)
        setEditWork({ ...editWork, status: 3 })
    }

    if (isRedirect) {
        return (<Redirect to='/home'></Redirect>)
    }
    return (
        <div className={cls.root}>
            <Grid container justify="space-between" alignItems="center" className={cls.titleStyle}>
                <Grid item>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item>
                            <Typography className={cls.titleText}><FormattedMessage id='prompt_update_information'></FormattedMessage></Typography>
                        </Grid>
                        <Grid item>
                            {
                                response !== null && <Chip
                                    variant="outlined"
                                    style={{
                                        minWidth: 100,
                                        borderColor: colors.menuColor,
                                        color: colors.menuColor,
                                        fontSize: 14,
                                        height: 24,
                                    }}
                                    label={
                                        <FormattedMessage id="prompt_status_declined" />
                                    }
                                ></Chip>
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <Grid>
                    <Grid container spacing={2}>
                        <Grid item>
                            <Button variant='outlined' className={cls.submitAction} color='primary' onClick={() => { setIsRedirect(true) }}><FormattedMessage id="prompt_back"></FormattedMessage></Button>
                        </Grid>
                        <Grid item>
                            <Button variant='contained' className={cls.submitAction} color='primary' onClick={onClickSubmit}><FormattedMessage id="action_save"></FormattedMessage></Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Divider variant='fullWidth'></Divider>
            {
                response !== null && <div className={cls.conentStyle}>
                    <Paper elevation={3} className={cls.topStyle}>
                        <Typography className={cls.subTitle}><FormattedMessage id='prompt_Decline_reason'></FormattedMessage></Typography>
                        <Typography className={cls.rejectSubtitle}>{response?.reason}</Typography>
                    </Paper>

                    <Paper elevation={3} className={cls.topStyle}>
                        <Typography className={cls.subTitle}><FormattedMessage id='prompt_create_application_detail'></FormattedMessage></Typography>
                        <Grid container justify="space-between" alignItems="center" spacing={3} className={cls.GridStyle}>
                            <Grid item xs={12} sm={5} md={5} lg={5}>
                                <InputInfoComponent label='prompt_create_application_number' defaultValue={response!.applicationNo} isDefault={true} isApprov={false}></InputInfoComponent>
                            </Grid>
                            <Grid item xs={12} sm={5} md={5} lg={5} >
                                <InputInfoComponent label='prompt_create_application_name' defaultValue={response!.applicantName} isDefault={true} isApprov={false}></InputInfoComponent>
                            </Grid>
                            <Grid item xs={12} sm={5} md={5} lg={5} >
                                <InputInfoComponent label='prompt_create_application_email' defaultValue={response!.applicantMail} isDefault={true} isApprov={false}></InputInfoComponent>
                            </Grid>
                            <Grid item xs={12} sm={5} md={5} lg={5} >
                                <InputInfoComponent label='prompt_create_application_sbDate' defaultValue={commonFormatTime(response!.submitTime || 0)} isDefault={true} isApprov={false}></InputInfoComponent>
                            </Grid>
                            <Grid item xs={12} sm={5} md={5} lg={5} >
                                <InputInfoComponent label='prompt_create_application_po' onChange={text => { setResponse({ ...response, poNo: text }) }} defaultValue={response!.poNo} maxLength={50} isDefault={false} isApprov={false}></InputInfoComponent>
                            </Grid>
                            <Grid item xs={12} sm={5} md={5} lg={5}>
                                <InputInfoComponent label='prompt_create_application_hp' onChange={text => { setResponse({ ...response, hpNo: text }) }} defaultValue={response!.hpNo} isDefault={false} maxLength={50} isApprov={false}></InputInfoComponent>
                            </Grid>
                            <Grid item xs={12} sm={5} md={5} lg={5} >
                                <InputInfoComponent label='prompt_create_application_ic' onChange={text => { setResponse({ ...response, icNo: text }) }} defaultValue={response!.icNo} isDefault={false} maxLength={50} isApprov={false}></InputInfoComponent>
                            </Grid>
                            <Grid item xs={12} sm={5} md={5} lg={5} >
                                <InputInfoComponent label='prompt_company_name' onChange={text => { setResponse({ ...response, companyName: text }) }} defaultValue={response!.companyName} isDefault={false} maxLength={50} isApprov={false}></InputInfoComponent>
                            </Grid>
                        </Grid>
                    </Paper>

                    <Paper elevation={3} className={cls.topStyle}>
                        <Grid container justify="space-between" alignItems="center" className={cls.topGridStyle}>
                            <Grid item>
                                <Typography className={cls.thirdTitle}><FormattedMessage id='prompt_create_application_Workdetail'></FormattedMessage></Typography>
                            </Grid>
                            <Grid item>
                                <Button variant='outlined' onClick={onClickAddWork} className={cls.submitAction} color='primary'><FormattedMessage id="prompt_create_application_addWorkdetail"></FormattedMessage></Button>
                            </Grid>
                        </Grid>
                        <WorkDetailTableComponent soure={response!.workList!} status={2} onClickRow={onClickWorkDetail}></WorkDetailTableComponent>
                    </Paper>
                    <Paper elevation={3} className={cls.topStyle}>
                        <Typography className={cls.subTitle}><FormattedMessage id='prompt_create_application_documents'></FormattedMessage></Typography>
                        <Grid container alignItems="flex-start" className={cls.GridStyle} spacing={3}>
                            <Grid item >
                                <UploadDocComponent onDropAccepted={onDropAccepted} disabled={response.attachmentList!.length > CommonLength.uploadUrlMax} ></UploadDocComponent>
                            </Grid >
                            {
                                response.attachmentList !== null && response!.attachmentList!.map(row => {
                                    return (<Grid item key={response.attachmentList!.indexOf(row)} style={{ paddingTop: 0, paddingBottom: 0 }}> <DocThumbnailComponent onDelete={onDeleteFile} isShow={true} url={row.url}></DocThumbnailComponent></Grid>)
                                })
                            }
                        </Grid>
                    </Paper>
                    {response !== null && <EditWorkDetailComponent queryIndex={queryIndex} onSave={onSaveEdit} onClose={() => { setEditWork({ ...editWork, status: 0 }) }} isOpen={editWork.status === 2 || editWork.status === 3} model={editWork.status === 2 ? response?.workList![editWork.index] : null}></EditWorkDetailComponent>}
                </div>
            }
            <ShowDialogAction defaultOpen={editWork.status === 1} content={formatMessage({ id: 'format_delete_workDetail' })} onCloseDisagree={() => { setEditWork({ ...editWork, status: 0 }) }} onCloseAgree={onDeleteWork}></ShowDialogAction>
            <Loading open={isLoading} />
            <div style={{ height: 24 }}></div>
        </div>
    )
}