export const PageArray = [20, 30, 50, 100]
export const BigPageArray = [20, 30, 50, 100, 5000]

export const currencySymbol = (code:string) =>{
    if(code === null || code === undefined) {
        return "$";
    }
    switch (code.toUpperCase()){
        case "AUD":
        case "ARS":
        case "KYD":
        case "CLP":
        case "COP":
        case "MOP":
        case "MXN":
        case "NZD":
        case "USD":
            return "$";
        case "ALL":
        case "HNL":
            return "L";
        case "DZD":	return "دج";
        case "AWG":	return "ƒ";
        case "GBP":	return "£";
        case "BSD":	return "B$";
        case "BHD":	return ".د.ب";
        case "BDT":	return "Tk";
        case "BBD":	return "BBD";
        case "BYR":	return "Br";
        case "BZD":	return "BZ$";
        case "BMD":	return "BD$";
        case "BTN":	return "Nu.";
        case "BOB":	return "Bs";
        case "BWP":	return "P";
        case "BRL":	return "R$";
        case "BND":	return "B$";
        case "BGN":	return "лв";
        case "BIF":	return "FBu";
        case "CAD":	return "C$";
        case "CNY":	return "¥";
        case "KHR":	return "KHR";
        case "CVE":	return "Esc";
        case "XOF":	return "BCEAO";
        case "XAF":	return "BEAC";
        case "KMF":	return "KMF";
        case "CRC":	return "₡";
        case "HRK":	return "kn";
        case "CUP":	return "$MN";
        case "CZK":	return "Kč";
        case "DKK":	return "kr";
        case "DJF":	return "Fdj";
        case "DOP":	return "RD$";
        case "EUR":	return "€";
        case "XCD":	return "EC$";
        case "EGP":	return "ج.م";
        case "SVC":	return "₡";
        case "EEK":	return "EEK";
        case "ETB":	return "Br";
        case "FKP":	return "£";
        case "FJD":	return "FJ$";
        case "GMD":	return "D";
        case "GTQ":	return "Q";
        case "GNF":	return "FG";
        case "GYD":	return "GY$";
        case "GHS":	return "GHS";
        case "HKD":	return "HK$";
        case "HUF":	return "Ft";
        case "IDR":	return "Rp";
        case "INR":	return "Rs.";
        case "ISK":	return "kr";
        case "IQD":	return "ع.د";
        case "ILS":	return "₪";
        case "JPY":	return "¥";
        case "JOD":	return "JOD";
        case "KZT":	return "KZT";
        case "KES":	return "KSh";
        case "KRW":	return "₩";
        case "KWD":	return "د.ك";
        case "KGS":	return "KGS";
        case "MKD":	return "MKD";
        case "MWK":	return "MK";
        case "MYR":	return "RM";
        case "MVR":	return "Rf";
        case "MRO":	return "UM";
        case "MUR":	return "₨";
        case "MDL":	return "MDL";
        case "MNT":	return "₮";
        case "MAD":	return "د.م.";
        case "MMK":	return "K";
        case "NAD":	return "N$";
        case "NPR":	return "₨";
        case "ANG":	return "NAƒ";
        case "NIO":	return "C$";
        case "NGN":	return "₦";
        case "KPW":	return "₩";
        case "NOK":	return "kr";
        case "OMR":	return "ر.ع.";
        case "XPF":	return "F";
        case "PKR":	return "Rs.";
        case "PAB":	return "B";
        case "PGK":	return "K";
        case "PEN":	return "S/.";
        case "PHP":	return "₱";
        case "PLN":	return "zł";
        case "QAR":	return "ر.ق";
        case "RON":	return "L";
        case "RUB":	return "руб";
        case "RWF":	return "RF";
        case "CHF":	return "CHF";
        case "WST":	return "WS$";
        case "STD":	return "Db";
        case "SAR":	return "ر.س";
        case "SCR":	return "SR";
        case "SLL":	return "Le";
        case "SGD":	return "S$";
        case "SKK":	return "Sk";
        case "SBD":	return "SI$";
        case "SOS":	return "So.";
        case "ZAR":	return "R";
        case "LKR":	return "ரூ";
        case "SHP":	return "£";
        case "SDG":	return "SDG";
        case "SZL":	return "SZL";
        case "SEK":	return "kr";
        case "SYP":	return "SYP";
        case "THB":	return "฿";
        case "TRY":	return "YTL";
        case "TWD":	return "NT$";
        case "TZS":	return "x";
        case "TOP":	return "T$";
        case "TTD":	return "TTD";
        case "TND":	return "د.ت";
        case "AED":	return "د.إ";
        case "UGX":	return "USh";
        case "UYU":	return "UYU";
        case "UZS":	return "UZS";
        case "VUV":	return "Vt";
        case "VND":	return "₫";
        case "VEF":	return "VEF";
        case "YER":	return "YER";
        case "ZMK":	return "ZMK";
        default: return  code.toUpperCase();
    }
}