import React, { Dispatch, useReducer, useContext } from 'react'
import { FormattedMessage } from 'react-intl';
import { Switch, Route } from 'react-router-dom';
import { UserLogin } from './userlogin'
import { ForgotPass } from './forgot'
import { InviteCodeRegister } from './inviteCode'
import { CompleteInfomation } from './completeInfo'
import { AddPhone } from './addPhone'
import License from './license'
import { colors } from '../../common/theme'
import { Snackbar, Typography, Grid, makeStyles, createStyles, Icon, } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { Redirect } from 'react-router-dom'
import { TappEnterprise } from '../../common/component/icons'
const useStyles = makeStyles((theme) => createStyles({
    root: {
        height: '100vh',
        backgroundColor: "white",
        minHeight: 800,

    },

    image: {
        backgroundImage: 'url(' + require('../../images/LoginLeftImg.webp') + ')',
        backgroundSize: '70%',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        position: 'relative',
    },


    leftImg: {
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        },
        height: 590
    },



    copyrightText: {
        color: colors.secondaryNormal,
        fontSize: 14,
        textAlign: 'right',
        paddingRight: 24,
        paddingBottom: 25

    },
}))



interface LoginState {
    isOpen: boolean,
    isCanLogin: boolean,
    errorMesg: string | null,
    userInfo?: {code: string, verifyStr: string, mail: string, phone: string, phoneCheckStr: string}
}

interface LoginActionType {
    type: 'userLogin' | 'errorHint' | 'verify'
    payload: LoginState
}

interface LoginContext {
    state: LoginState,
    method: Dispatch<LoginActionType>
}
function reducer(state: LoginState, action: LoginActionType): LoginState {
    switch (action.type) {
        case 'userLogin':
            return { ...state, isCanLogin: action.payload.isCanLogin }
        case 'errorHint':
            return { ...state, errorMesg: action.payload.errorMesg, isOpen: action.payload.isOpen }
        case 'verify':
            return { ...state, userInfo: action.payload.userInfo }
        default:
            return state
    }
}
const UserLoginContext = React.createContext<LoginContext>({ state: { isCanLogin: false, errorMesg: null, isOpen: false }, method: () => { } })
export function useLoginContext(): LoginContext {
    return useContext<LoginContext>(UserLoginContext)
}

export function Login() {
    const cls = useStyles()
    const unknownMesg = "net_code_unknown"
    const [state, method] = useReducer(reducer, { isCanLogin: false, errorMesg: null, isOpen: false })
    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        method({ type: 'errorHint', payload: { ...state, isOpen: false } })
    };
    
    if (state.isCanLogin) {
        return (<Redirect to="/home"></Redirect>)
    }
    return (
        <>
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={state.isOpen} autoHideDuration={2000} onClose={handleClose} >
                <Alert severity="error" variant="filled" >
                    <FormattedMessage id={state.errorMesg || unknownMesg} />
                </Alert>
            </Snackbar>
            <Grid container component="main" className={cls.root}>
                <Grid item xs={false} sm={false} md={6} lg={6} className={cls.image}> </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Grid container direction="column" justify="space-between" alignItems="stretch" className={cls.root}>
                        <Grid item >
                            <div style={{ float: 'right', paddingRight: 25, paddingTop: 30 }}>
                                <Icon ><TappEnterprise></TappEnterprise></Icon>
                            </div>
                        </Grid>
                        <Grid item>
                            <UserLoginContext.Provider value={{ state: state, method: method }}>
                                <Switch>
                                    <Route exact path='/login'>
                                        <UserLogin />
                                    </Route>
                                    <Route exact path='/login/forgot'>
                                        <ForgotPass />
                                    </Route>
                                    <Route exact path='/login/inviteCode'>
                                        <InviteCodeRegister />
                                    </Route>
                                    <Route exact path='/login/addPhone'>
                                        <AddPhone />
                                    </Route>
                                    
                                    <Route exact path='/login/completeInfo'>
                                        <CompleteInfomation />
                                    </Route>
                                    <Route path="*">
                                        <Redirect to='/login' />
                                    </Route>
                                </Switch>
                            </UserLoginContext.Provider>
                        </Grid>
                        <Grid item >
                            <Typography className={cls.copyrightText}>
                                Copyright © Tapplock Corp
                                </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}
