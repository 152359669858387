import React, { useEffect } from 'react'
import { Typography, makeStyles, createStyles, TextField, Button, Container, Grid, } from '@material-ui/core'
import { colors } from '../../../common/theme'
import { FormattedMessage, useIntl } from 'react-intl'
import { ApiUtil } from '../../../common/api/api';
import { useLoginContext } from '..';
import { PorjectError } from '../../../common/api/errorMessage'
import { Redirect } from 'react-router-dom';
import { isNumber } from "../../../common/util";
import {
    getCountryCallingCode,
    CountryCode,
    isPossibleNumber,
} from "libphonenumber-js";
import { CountryPhoneLoginWidget } from "../../../common/component";
const useStyle = makeStyles(() => createStyles({

    root: {
        flexGrow: 1,
        width: 496,
        backgroundColor: 'white',
    },

    getStartText: {
        fontSize: 24,
        fontWeight: 500,
        color: colors.menuColor
    },
    subTitle: {
        fontSize: 19,
        color: colors.secondaryNormal,
        fontWeight: 500
    },

    errorText: {
        fontSize: "0.75rem",
        color: "#f44336",
        marginLeft: 14
    },

    textField: {
        fontSize: 16,
        color: colors.textSeconday,
        '& .MuiInputBase-input': {
            boxShadow: '0 0 0px 1000px white inset !important'
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: colors.divider,
            }
        },
        '& .MuiFormLabel-root': {
            color: '#b0b3ba'
        }
    },

    submitAction: {
        fontSize: 16,
        borderRadius: 4,
        width: 132
    },
    sendAction: {
        fontSize: 18,
        borderRadius: 4,
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 25,
        paddingRight: 25,
    },
    loadingBg: {
        position: 'relative',
    },
    loading: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    inputInfo: {
        "& > div": {
            height: 50,
        },
        "& > * > input": {
            padding: "10px 14px",
        },
        margin: 0
    },
}))

interface State {
    phone: string,
    verifyCode: string,
}

export function AddPhone() {
    const cls = useStyle()
    const { state, method } = useLoginContext()
    const [addMailSuccess, setAddMailSuccess] = React.useState(false)
    const [countryCode, setCountryCode] = React.useState<string | undefined>()
    const { formatMessage } = useIntl()
    const [num, setNum] = React.useState(0)
    const [isSend, setIsSend] = React.useState(false)
    const [values, setValues] = React.useState<State>({
        phone: '',
        verifyCode: '',
    })
    const unknownMesg = "net_code_unknown"
    const [errorPhone, setErrorPhone] = React.useState(false);
    const [errorCode, setErrorCode] = React.useState(false)

    const handleVerifyCodeOnchange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, verifyCode: event.target.value })
    }

    const sendVerification = () => {
        if (canSendCode()) {
            setNum(60)
            ApiUtil.getAddPhoneCode(getPhoneFormatt())
              .then(() => {
                
                setIsSend(true);
              })
              .catch((err) => {
                setNum(0)
                let mesg = PorjectError.get(err.code) || unknownMesg;
                method({ type: 'errorHint', payload: { ...state, errorMesg: mesg, isOpen: true } })
                setMethod(mesg);
              });
        }
    }

    const canSendCode = () => {
        if (values.phone.length === 0) {
            setErrorPhone(true);
            return false;
        } else if (!isPossibleNumber(`+${getPhoneFormatt()}`)) {
            setErrorPhone(true);
            return false;
        }
        if (num !== 0) {
            return false
        }
        return true;
    };

    useEffect(() => {
        let timer = 0;
        if (isSend && num !== 0) {
            timer = setInterval(() => {
                setNum(n => {
                    if (n === 1) {
                        setIsSend(false)
                        clearInterval(timer)
                    }
                    return n - 1
                });
            }, 1000)
        }
        return () => {
            clearInterval(timer)
        };
    }, [isSend])


    const checkPhone = () => {
        if (values.phone.length === 0) {
            return <FormattedMessage id="prompt_fill_in_Phone" />;
        } else {
            return <FormattedMessage id="prompt_error_format_phone" />;
        }
    };


    const canSave = () => {
        let getError = false;
        if (values.phone.length === 0) {
            getError = true;
            setErrorPhone(true);
        }
        if (!isNumber(values.phone)) {
            getError = true;
            setErrorPhone(true);
        }
        if (values.verifyCode.length === 0) {
            getError = true
            setErrorCode(true);
        }
        return !getError;
    }

    const setMethod = (value: string | boolean) => {
        if (typeof value === "string") {
            method({
                type: "errorHint",
                payload: { ...state, errorMesg: value, isOpen: true },
            });
        } else {
            method({ type: "userLogin", payload: { ...state, isCanLogin: value } });
        }
    };

    const handleAddPhone = () => {
        if (canSave()) {
            const ph = getPhoneFormatt()
            ApiUtil.verifyPhoneInviteCode(ph, state.userInfo!.code, values.verifyCode).then(
                response => {
                    method({ type: 'verify', payload: { ...state, userInfo: { ...state.userInfo!, phone: ph, phoneCheckStr: response } } })
                    setAddMailSuccess(true);
                }
            ).catch(
                err => {
                    let mesg = PorjectError.get(err.code) || unknownMesg
                    method({ type: 'errorHint', payload: { ...state, errorMesg: mesg, isOpen: true } })
                }
            )
        }
    }

    const getPhoneFormatt = () => {
        const title = `${getCountryCallingCode(countryCode as CountryCode)}`;
        const ph = `${title}${values.phone}`;
        return ph;
    }



    if (addMailSuccess || state.userInfo === undefined) {
        return <Redirect to="/login/completeInfo" />;
    }

    return (
        <Container className={cls.root}>
            <Grid container direction="column" justify="center" alignItems="stretch">
                <Grid item>
                    <Typography className={cls.getStartText}><FormattedMessage id="prompt_register_Account" /></Typography>
                </Grid>
                <Grid item >
                    <Typography className={cls.subTitle}><FormattedMessage id="prompt_add_phone_number" /></Typography>
                </Grid>
                <Grid style={{ marginTop: 40 }} >

                    <Grid
                        container
                        justify="space-between"
                        // direction="row"
                        alignItems="center"
                    >
                        <Grid item xs={3} md={3} lg={3}>
                            <CountryPhoneLoginWidget
                                onChange={setCountryCode}
                                code={countryCode}
                            />
                        </Grid>
                        <Grid item xs={9} md={9} lg={9}>
                            <TextField
                                id="phone"
                                fullWidth
                                autoComplete="off"
                                type="text"
                                margin="normal"
                                variant='outlined'
                                placeholder={formatMessage({
                                    id: "prompt_phone_number",
                                })}
                                InputProps={{
                                    startAdornment:
                                        countryCode === undefined
                                            ? ""
                                            : `+${getCountryCallingCode(countryCode as CountryCode)}`,
                                }}
                                onFocus={() => setErrorPhone(false)}

                                className={cls.inputInfo}
                                value={values.phone}
                                onChange={(event) =>
                                    setValues({ ...values, phone: event.target.value })
                                }
                            ></TextField>
                        </Grid>
                    </Grid>

                </Grid>
                {errorPhone && (
                    <Grid item style={{ marginTop: 5 }}>
                        <Typography className={cls.errorText}>{checkPhone()}</Typography>
                    </Grid>
                )}
                <Grid item style={{ marginTop: 24 }} >
                    <TextField id="Verification" name="Verification" className={cls.textField}
                        onFocus={() => setErrorCode(false)}
                        fullWidth
                        type="text"
                        autoComplete="off"
                        label={<FormattedMessage id="prompt_verification_code"></FormattedMessage>}
                        variant='outlined'
                        error={errorCode}
                        helperText={errorCode ? <FormattedMessage id='prompt_fill_in_email_code' /> : null}
                        onChange={handleVerifyCodeOnchange}
                        InputProps={{
                            endAdornment: (
                                <Button className={cls.sendAction} color='primary' variant='contained' onClick={sendVerification} >{num !== 0 ? `${num}s` : <FormattedMessage id="prompt_login_send" />}</Button>
                            ),
                        }}
                    ></TextField>
                </Grid>
                <Grid container style={{ marginTop: 50 }} justify="flex-start">
                    <Grid item>
                        <Button variant="outlined" color='primary' className={cls.submitAction} onClick={handleAddPhone}><FormattedMessage id="prompt_next" /></Button>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    )
}