import React from 'react';
import { Container, Button, Grid, Typography, makeStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import ComputerImage from "../../images/computer.svg"
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Link } from 'react-router-dom'

const useStyles = makeStyles({
    root: {
        flexGrow: 1
    },
    gride: {
        height: 600
    },
    text: {
        color: "#929399",
        fontWeight: 'bold',
    },
    image: {
        height: 200,
    },
    action: {
        fontSize: 32,
        fontWeight: 'bold',
    }
});
export function NoMatch() {
    const classes = useStyles();
    return (
        <>
            <CssBaseline />
            <Container component="main" className={classes.root}>
                <Grid container direction='column' justify='space-around' alignItems='center' className={classes.gride}>
                    <Grid item>
                        <Typography className={classes.text} variant='h3'><FormattedMessage id="prompt_opps" /></Typography>
                    </Grid>
                    <Grid item>
                        <img src={ComputerImage} className={classes.image} alt="logo" />
                    </Grid>

                    <Grid item>
                        <Typography className={classes.text} variant='h3'><FormattedMessage id="prompt_unauthorized_account" /></Typography>
                    </Grid>
                    <Grid item>
                        <Button variant='text' color='primary' size='large' className={classes.action} component={Link} to="/home"><FormattedMessage id="action_home" /><ArrowForwardIcon fontSize='large' /></Button>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}