import { ProjectError } from "./model";

export const PorjectError: Map<number, string> = new Map([
    [400000, "net_code_400000"],
    [400001, "net_code_400001"],
    [400002, "net_code_400002"],
    [400003, "net_code_400003"],
    [400004, "net_code_400004"],
    [400005, "net_code_400005"],
    [400006, "net_code_400006"],
    [400007, "net_code_400007"],
    [400008, "net_code_400008"],
    [400009, "net_code_400009"],
    [400010, "net_code_400010"],
    [400011, "net_code_400011"],
    [400012, "net_code_400012"],
    [400013, "net_code_400013"],
    [400014, "net_code_400014"],
    [400015, "net_code_400015"],
    [400016, "net_code_400016"],
    [400017, "net_code_400017"],
    [400018, "net_code_400018"],
    [400019, "net_code_400019"],
    [400020, "net_code_400020"],
    [400021, "net_code_400021"],
    [400022, "net_code_400022"],
    [400023, "net_code_400023"],
    [400024, "net_code_400024"],
    [400025, "net_code_400025"],
    [400026, "net_code_400026"],
    [400041, "net_code_400026"],
    [400027, "net_code_400027"],
    [400028, "net_code_400028"],
    [400029, "net_code_400029"],
    [400030, "net_code_400030"],
    [400031, "net_code_400031"],
    [400032, "net_code_400032"],
    [400033, "net_code_400033"],
    [400034, "net_code_400034"],
    [400035, "net_code_400035"],
    [400037, "net_code_400037"],
    [400038, "net_code_400038"],
    [400043, "net_code_400043"],
    [400044, "net_code_400044"],
    [400045, "net_code_400045"],
    [400046, "net_code_400046"],
    [400047, "net_code_400047"],
    [400050, "net_code_400050"],
    [400051, "net_code_400051"],
    [400053, "net_code_400053"],
    [400054, "net_code_400054"],
    [400055, "net_code_400055"],
    [400056, "net_code_400056"],
    [400061, "net_code_400061"],
    [400062, "net_code_400062"],
    [500000, "net_code_unknown"],
    [500001, "prompt_server_upgrade"],
    [9999, "prompt_user_change"],
    [100000, "success_info"]
]);

export const isApiErr = (error?: ProjectError) => error !== undefined && error.code >= 400000 && error.code <= 500000
export const isSuccessErr = (error?: ProjectError) => error !== undefined && error.code === 100000
export const isSessionErr = (error?: ProjectError) => error !== undefined && error.code === 9999