import React from 'react';
import { Container, Grid, Typography, makeStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import CssBaseline from '@material-ui/core/CssBaseline';
import { NoDataIcon } from '../../common/component/icons';

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        height: '100%'
    },
    gride: {
        height: 600,
    },
    text: {
        color: "#929399",
        fontWeight: 'bold',
    },
    image: {
        height: 200,
    },
    action: {
        fontSize: 32,
        fontWeight: 'bold',
    }
});
export function NoData() {
    const classes = useStyles();
    return (
        <>
            <CssBaseline />
            <Container component="main" className={classes.root}>
                <Grid container direction='column' justify='center' alignItems='center' className={classes.gride} spacing={3}>
                    <Grid item style={{ position: 'relative', right: 7 }}>
                        <NoDataIcon />
                    </Grid>
                    <Grid item>
                        <Typography className={classes.text} variant='h4'><FormattedMessage id="prompt_no_data" /></Typography>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}