import React from 'react';
import { Typography, makeStyles, createStyles, TextField, Button, Container, Grid, CardHeader, Dialog, DialogContent, DialogActions, DialogTitle, Avatar, } from '@material-ui/core'
import { colors } from '../../../common/theme'
import { FormattedMessage } from 'react-intl';
import completeInfoAv from "../../../images/loginCompleteInfoAvatar.svg"
import { CornerButton } from '../../../common/component';
import { ApiUtil } from '../../../common/api/api';
import { InPersonInfo } from '../../../common/api/model';
import { useLoginContext } from '..';
import { PorjectError } from '../../../common/api/errorMessage';
import { loadAvatarImges } from '../../../common/component/icons'
import { StoreUtil } from '../../../common/store'
import { Link, Redirect } from 'react-router-dom';
import { CommonLength } from '../../../common/util'
const useStyle = makeStyles((theme) => createStyles({
    root: {
        flexGrow: 1,
        width: 496,
        backgroundColor: 'white',
    },
    textHeader: {
        fontSize: 20,
        // fontWeight: 'bold',
        color: colors.menuColor
    },

    getStartText: {
        fontSize: 24,
        fontWeight: 500,
        color: colors.menuColor
    },
    subTitle: {
        fontSize: 19,
        color: colors.secondaryNormal,
        fontWeight: 500
    },

    textField: {
        fontSize: 16,
        color: colors.textSeconday,
        '& .MuiInputBase-input': {
            boxShadow: '0 0 0px 1000px white inset !important'
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: colors.divider,
            }
        },
        '& .MuiFormLabel-root': {
            color: '#b0b3ba'
        }
    },

    loadingBg: {
        position: 'relative',
    },
    loading: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },

    contentText: {
        fontSize: 16,
        color: colors.textSeconday,
    },

    submitAction: {
        fontSize: 16,
        borderRadius: 4,
        width: 157
    },

    cardHeard: {
        '& :hover': {
            cursor: 'pointer'
        },
        paddingLeft: 0,
        paddingBottom: 0
    },
    avatarAction: {
        padding: 0
    },
    avatarContentBg: {
        width: 503,
        // height: 180,
        margin: 0,
    },
    avatartBottomAction: {
        marginTop: theme.spacing(10),
    },
    avatartSingleItem: {
        borderStyle: 'solid',
        borderColor: theme.palette.primary.main,
        borderWidth: 2,
        borderRadius: '50%'
    },

    staffText: {
        fontSize: 16,
        color: colors.textSeconday,
        // fontWeight: 'bold',
    },
    emailText: {
        fontSize: 16,
        color: colors.textSeconday,
        // fontWeight: 'bold',
    },
    chooseImg_title: {
        color: '#ffffff',
        backgroundColor: '#262834',
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
}))


interface State {
    expand: boolean,
    selectImgIndex: number,
    isLoading: boolean,
    firstName: string,
    lastName: string,
    password: string,
    comfimPassword: string,
    photoUrl: string,
    company: string,
}

export function CompleteInfomation() {
    const cls = useStyle()
    const { state, method } = useLoginContext()
    const unknownMesg = "net_code_unknown"

    const [errorFn, setErrorFn] = React.useState(false)
    const [errorLn, setErrorLn] = React.useState(false)
    const [errorCm, setErrorCm] = React.useState(false)
    const [errorPwd, setErrorPwd] = React.useState(false)
    const [errorCpwd, setErrorCpwd] = React.useState(false)

    const [values, setValues] = React.useState<State>({
        expand: false,
        selectImgIndex: -1,
        isLoading: false,
        firstName: "",
        lastName: "",
        password: '',
        photoUrl: '',
        comfimPassword: '',
        company: "",

    });

    const canSave = () => {
        let getError = false
        if (values.firstName.length === 0 || values.firstName.length > CommonLength.userNameMaxLength) {
            getError = true
            setErrorFn(true)
        }

        if (values.lastName.length > 0) {
            if (values.lastName.length > CommonLength.userNameMaxLength) {
                getError = true;
                setErrorLn(true);
            }
        }

        if (values.company.length === 0 || values.company.length > CommonLength.mailMaxLength) {
            getError = true
            setErrorCm(true)
        }

        if (values.password.length === 0 || values.password.length < CommonLength.passwordMinLength || values.password.length > CommonLength.passwordMaxLength) {
            getError = true
            setErrorPwd(true)
        }
        if (values.password !== values.comfimPassword) {
            getError = true
            setErrorCpwd(true)
        }
        return !getError
    }

    const handleSave = () => {
        if (canSave()) {
            const model: InPersonInfo = { firstName: values.firstName, lastName: values.lastName, password: values.password, phone: state.userInfo?.phone || "", phoneCheckStr: state.userInfo?.phoneCheckStr || "", photoUrl: values.photoUrl, companyName: values.company, mailCheckStr: state.userInfo?.verifyStr!, mail: state.userInfo?.mail! }
            ApiUtil.completePersonalInfo(model).then(
                () => {
                    setValues({ ...values, isLoading: false })
                    method({ type: 'userLogin', payload: { ...state, isCanLogin: true, userInfo: undefined } })
                }
            ).catch(
                err => {
                    setValues({ ...values, isLoading: false })
                    let mesg = PorjectError.get(err.code) || unknownMesg
                    method({ type: 'errorHint', payload: { ...state, errorMesg: mesg, isOpen: true } })
                }
            )
        }
    }
    const data = loadAvatarImges()

    const chooseUserAvatar = () => {
        setValues({ ...values, expand: true })
    }

    const saveSelectedAvatar = () => {
        if (values.selectImgIndex !== -1) {
            const photoUrl = data[values.selectImgIndex].url
            setValues({ ...values, photoUrl: photoUrl, expand: false })
        } else {
            setValues({ ...values, photoUrl: '', expand: false })
        }
    }
    const onClickImg = (index: number) => {
        console.log(index)
        setValues({ ...values, selectImgIndex: index })
    }
    const checkLN = () => {
        if (values.password.length === 0) {
            return <FormattedMessage id='prompt_fill_in_pass' />
        } else if (values.password.length < 8) {
            return <FormattedMessage id='prompt_pass_length_short' />
        } else {
            return <FormattedMessage id='prompt_errorMessage_PasswordOverLenth' values={{ number0: CommonLength.passwordMaxLength }} />
        }
    }

    const checkCm = () => {
        if (values.company.length === 0) {
            return <FormattedMessage id='prompt_fill_in_componyName' />
        } else if (values.company.length > CommonLength.mailMaxLength) {
            return <FormattedMessage id='prompt_errorMessage_CompanyOverLength' values={{ number0: CommonLength.mailMaxLength }} />
        } else {
            return '--'
        }
    }

    if (state.userInfo === undefined) {
        return (<Redirect to='/login'></Redirect>)
    }

    return (
        <Container className={cls.root}>
            <Grid container direction="column" justify="center" alignItems="stretch">
                <Grid item>
                    <Typography className={cls.getStartText}><FormattedMessage id="prompt_register_Account" /></Typography>
                </Grid>
                <Grid item >
                    <Typography className={cls.subTitle}><FormattedMessage id="prompt_complete_your_information" /></Typography>
                </Grid>

                <Grid item style={{ marginTop: 10 }}>
                    <Grid container direction="row" justify="space-between" alignItems="center">
                        <Grid item xs={2} sm={2} md={2} lg={2}>
                            <Avatar className={cls.large} src={values.photoUrl.length !== 0 ? values.photoUrl : completeInfoAv} alt='user' onClick={chooseUserAvatar}></Avatar>
                        </Grid>
                        <Grid item xs={10} sm={10} md={10} lg={10}>
                            <Grid container direction="column" justify="space-between" alignItems="stretch" spacing={3}>
                                <Grid item>
                                    <TextField id="firstname" fullWidth
                                        label={<FormattedMessage id="prompt_first_name" />} name="firstname" autoComplete='off'
                                        className={cls.textField} value={values.firstName}
                                        variant='outlined'
                                        onChange={event => {
                                            let firstName = event.target.value;
                                            if (firstName.length !== 0) {
                                                firstName = firstName.replace(firstName[0], firstName[0].toUpperCase());
                                            };
                                            setValues({ ...values, firstName: firstName })
                                        }
                                        }
                                        onFocus={() => setErrorFn(false)}
                                        error={errorFn}
                                        helperText={errorFn ? (values.firstName.length === 0 ? <FormattedMessage id='prompt_fill_in_first_name' /> : <FormattedMessage id='prompt_errorMessage_FirstNameOverLenth' values={{ number0: `${CommonLength.userNameMaxLength}` }} />) : null}
                                    ></TextField>
                                </Grid>
                                <Grid item>
                                    <TextField id="lastname" fullWidth label={<FormattedMessage id="prompt_last_name" />} autoComplete='off' name="lastname" variant='outlined'
                                        className={cls.textField}
                                        value={values.lastName}
                                        onChange={event => {
                                            let lastName = event.target.value;
                                            if (lastName.length !== 0) {
                                                lastName = lastName.replace(lastName[0], lastName[0].toUpperCase());
                                            };
                                            setValues({ ...values, lastName: lastName })
                                        }
                                        }
                                        onFocus={() => setErrorLn(false)}
                                        error={errorLn}
                                        helperText={errorLn ? (values.lastName.length === 0 ? <FormattedMessage id='prompt_fill_in_last_name' /> : <FormattedMessage id='prompt_errorMessage_LastNameOverLenth' values={{ number0: `${CommonLength.userNameMaxLength}` }} />) : null}
                                    ></TextField>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>

                </Grid>
                <Grid item style={{ marginTop: 24 }}>
                    <TextField id="companyName" fullWidth label={<FormattedMessage id="prompt_company_name" />} name="company"
                        autoComplete="new-password"
                        onFocus={() => setErrorCm(false)}
                        variant='outlined'
                        error={errorCm}
                        helperText={errorCm ? checkCm() : null}
                        className={cls.textField} value={values.company} onChange={event => setValues({ ...values, company: event.target.value })}
                    ></TextField>
                </Grid>
                <Grid item style={{ marginTop: 24 }}>
                    <TextField required id="password" fullWidth label={<FormattedMessage id="hint_password" />}
                        autoComplete="new-password" name="password"
                        variant='outlined'
                        className={cls.textField} onChange={event => setValues({ ...values, password: event.target.value })}
                        onFocus={() => setErrorPwd(false)}
                        error={errorPwd}
                        helperText={errorPwd ? checkLN() : null}
                        type='password'
                    ></TextField>
                </Grid>
                <Grid item style={{ marginTop: 24 }}>
                    <TextField required id="confirmpassword" fullWidth label={<FormattedMessage id="prompt_confirm_pass" />} autoComplete='off' name="confirmpassword"
                        variant='outlined'
                        className={cls.textField} onChange={event => setValues({ ...values, comfimPassword: event.target.value })}
                        onFocus={() => setErrorCpwd(false)}
                        error={errorCpwd}
                        helperText={errorCpwd ? <FormattedMessage id='prompt_pass_mismatch_repass' /> : null}
                        type='password'
                    ></TextField>
                </Grid>

                <Grid item style={{ marginTop: 50 }} className={cls.loadingBg}>
                    <Grid container justify="space-between" direction="row" alignItems="center">
                        <Grid >
                            <Button variant="outlined" color='primary' className={cls.submitAction} component={Link} to='/login/inviteCode'><FormattedMessage id="prompt_back" /></Button>
                        </Grid>
                        <Grid>
                            <Button variant="contained" color='primary' className={cls.submitAction} onClick={handleSave}><FormattedMessage id="prompt_submit" /></Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Dialog fullWidth={false} maxWidth='md' open={values.expand} onClose={() => { setValues({ ...values, expand: false }) }} aria-labelledby="customized-dialog-title">
                <DialogTitle id="customized-dialog-title" className={cls.chooseImg_title}>
                    {<FormattedMessage id="prompt_login_chooseImg" />}
                </DialogTitle>
                <DialogContent>

                    <Grid className={cls.avatarContentBg} container spacing={3} alignItems='center' direction="row" justify="flex-start">
                        {data.map(item =>
                            <Grid item key={item.id}  >
                                <Avatar className={values.selectImgIndex === data.indexOf(item) ? cls.avatartSingleItem : ""} src={item.imgPath} alt='user' onClick={() => onClickImg(data.indexOf(item))}></Avatar>
                            </Grid>
                        )}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <CornerButton className={cls.avatartBottomAction} size='small' variant='contained' onClick={saveSelectedAvatar} color="primary" style={{ marginRight: '2vh' }}>
                        <Typography style={{ paddingLeft: '2vh', paddingRight: '2vh' }}><FormattedMessage id="action_save" /></Typography>
                    </CornerButton>
                </DialogActions>
            </Dialog>
        </Container>
    )
}


