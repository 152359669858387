import React, { useState, useEffect } from 'react'
import { Container, Grid, Typography, makeStyles, Theme, createStyles, IconButton } from '@material-ui/core'
import { TagIcon, defaultAvatar, EditIcon } from '../../../common/component/icons'
import { StoreUtil } from '../../../common/store'
import { ApiUtil } from '../../../common/api/api'
import  { UpdatePersonalInfo } from '../../../common/api/model'
import { useProject, ActionFactory } from '../../../common/component/provider'
import { FormattedMessage } from 'react-intl'
import { Loading } from '../../../common/component'
import { formateText } from '../../../common/util'
import EditUser from './editUser'
import EditUserInfo from './EditUserInfo'

const useStyle = makeStyles((theme: Theme) => createStyles({
    box: {
        backgroundColor: 'white',
        boxShadow: '0 0 4px rgba(38, 40, 52, 0.1)',
        borderRadius: 5
    },
    FirstGrid: {
        paddingTop: 20,
        paddingBottom: 20,
        borderBottom: '2px dashed rgba(38, 40, 52, 0.1)',
        position: 'relative'
    },
    BorderBottom: {
        borderBottom: '2px dashed rgba(38, 40, 52, 0.06)'
    },
    GroupBottomSpace: {
        paddingBottom: 40,
    },
    GroupCard: {
        // backgroundColor: 'rgba(38, 40, 52, 0.1)',
        // padding: '8px 15px 8px 15px',
        // borderRadius: 5,
        fontSize: 16,
        fontWeight: 'normal',
        color: '#676870'
    },
    permissionbox: {
        paddingBottom: 50,
        fontSize: 16,
        fontWeight: 'normal',
        color: '#676870'
    },
    editroundButton: {
        position: 'relative',
        left: 30,
        bottom: 20,
    },
    chooseImg_title: {
        color: '#ffffff',
        backgroundColor: '#262834',
        height: theme.spacing(7),
    },
    avatarContentBg: {
        width: 503,
        height: 180,
    },
    avatartSingleItem: {
        position: 'absolute',
        margin: 'auto',
        left: 20,
        right: 0,
        bottom: 0,
        top: 0,
        width: 40,
        height: 40,
    }
    ,
    avatartSingleItem_click: {
        borderStyle: 'solid',
        borderColor: theme.palette.primary.main,
        borderWidth: 2,
        borderRadius: '50%',
        position: 'absolute',
        margin: 'auto',
        left: 20,
        right: 0,
        bottom: 0,
        top: 0,
        width: 55,
        height: 55,
    },
    avatartBottomAction: {
        // marginTop: theme.spacing(10),
    },
    EditInput: {
        height: 50,
        width: 400,
        borderRadius: 10,
        outline: 'none',
        fontSize: 20,
        paddingLeft: 10,
    },
    ContentLinePadding: {
        paddingBottom: 20,
        paddingTop: 20,
    },
    userInfoContent: {
        paddingLeft: 10
    },
    input: {
        position: 'absolute',
        opacity: 0,
        width: 1,
        height: 1,
    },
    uoloadActionLabel: {
        position: 'absolute',
        width: 15,
        height: 15,
        zIndex: 1,
        left: 54,
        '&:hover': {
            cursor: 'pointer'
        }
    },
    CompanyAvater: {
        position: 'relative',
        display: 'flex',
        bottom: 5,
        marginBottom: 10,
        width: 80,
        height: 80,
        borderRadius: '100%',
        border: '1px dashed #e0e0e0',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1,
        '&:hover': {
            cursor: 'pointer'
        }
    },
    editUserBox: {
        '& .MuiDialog-paperFullWidth': {
            position: 'relative',
            height: 300,
        }
    },
    chooseContent: {
        '& :hover': {
            cursor: 'pointer'
        }
    },
    inputInfo: {
        '& > div': {
            height: 40
        },
        '& > * > input': {
            padding: '10px 14px',
            width: 500
        }
    },
}))

export default function Profile() {
    return (<div>test</div>)
    // const classes = useStyle();
    // const { handler, store } = useProject();
    // const userprofile = StoreUtil.user()
    // const [userDetail, setUserDetail] = useState<UserDetail | null>(null);
    // const [loading, setloading] = useState<boolean>(true);
    // const [editUser, setEditUser] = useState<boolean>(false)
    // const defaultAction = () => {
    //     if (userprofile !== null) {
    //         ApiUtil.UserDetail(userprofile.id).then(
    //             result => {
    //                 setUserDetail(result);
    //                 setloading(false)
    //             }
    //         ).catch(error => {
    //             setloading(false)
    //             handler(ActionFactory.error(error))
    //         })
    //     }
    // }
    // useEffect(() => {
    //     defaultAction()
    // }, [store.reload])
    // const urlIMG = `url(${userprofile?.photoUrl || defaultAvatar})`
    // const permissions = (): Array<Array<Permission>> | null => {
    //     let permissionAdmin: Array<Permission> | null = null;
    //     let permissionManager: Array<Permission> | null = null;
    //     let permissionUser: Array<Permission> | null = null;
    //     if (userprofile !== null) {
    //         if (isMaster(userprofile.permissionType)) {
    //             const permissiontype_admin = getAllPermission().filter(itemcontent => itemcontent.permissionType === 0)
    //             const permissiontype_manager = getAllPermission().filter(itemcontent => itemcontent.permissionType === 1)
    //             const permissiontype_user = getAllPermission().filter(itemcontent => itemcontent.permissionType === 2)
    //             permissionAdmin = permissiontype_admin
    //             permissionManager = permissiontype_manager
    //             permissionUser = permissiontype_user
    //             const permistionsAll = [permissionAdmin, permissionManager, permissionUser]
    //             return permistionsAll
    //         }
    //         else {
    //             const permissiontype_admin = userprofile.permissions.filter(itemcontent => itemcontent.permissionType === 0)
    //             const permissiontype_manager = userprofile.permissions.filter(itemcontent => itemcontent.permissionType === 1)
    //             const permissiontype_user = userprofile.permissions.filter(itemcontent => itemcontent.permissionType === 2)
    //             permissionAdmin = permissiontype_admin
    //             permissionManager = permissiontype_manager
    //             permissionUser = permissiontype_user
    //             const permistionsAll = [permissionAdmin, permissionManager, permissionUser]
    //             return permistionsAll
    //         }
    //     }
    //     else {
    //         return null
    //     }
    // };

    // const permission = permissions()
    // const commitUserInfo = (request: InUpdateUser) => {
    //     const requestForSelf: UpdatePersonalInfo = { firstName: request.firstName || '', lastName: request.lastName || '', staffId: request.staffId || '', phone: request.phone || '', photoUrl: StoreUtil.user()?.photoUrl || '' }
    //     setloading(true)
    //     ApiUtil.updatePersonalInfo(requestForSelf).then(() => {
    //         StoreUtil.updateSelfInfo(requestForSelf)
    //         defaultAction()
    //         handler(ActionFactory.success("prompt_update_success"))
    //     }).catch(error => {
    //         setloading(false)
    //         handler(ActionFactory.error(error))
    //     })
    // }
    // return (
    //     <Container maxWidth='xl' className={classes.box} style={{ color: '#676870' }}>
    //         <Loading open={loading} />
    //         {/* {editUser ?
    //             <EditUser commitUserInfo={commitUserInfo} setEditUser={setEditUser} classes={classes} UserDetail={userDetail} />
    //             : null
    //         } */}
    //         {/* <EditUserInfo classes={classes} userprofile={userprofile} values={values} setValues={setValues} EditImg={EditImg} setEditImg={setEditImg} /> */}
    //         <Grid container spacing={0} className={classes.FirstGrid} alignItems='center' direction='row'>
    //             <Grid item lg={1} md={2} xs={3}>
    //                 <div style={{ backgroundImage: urlIMG, width: 80, height: 80, borderRadius: '100%', backgroundSize: 'cover' }} >
    //                     {/* <IconButton className={classes.editroundButton} onClick={() => setEditImg(true)}>
    //                         <EditRoundIcon fill='white' fontSize='small' />
    //                     </IconButton> */}
    //                 </div>
    //             </Grid>
    //             <Grid item lg={11} md={10} xs={10}>
    //                 <Grid container>
    //                     <Grid item xs={11} md={5} lg={4}>
    //                         <Grid container alignItems='center' style={{ height: 55 }}>
    //                             <Typography variant='inherit'><FormattedMessage id="prompt_first_name" />&nbsp;:</Typography>
    //                             <Typography noWrap className={classes.userInfoContent} variant='inherit'>
    //                                 {formateText(userprofile?.firstName)}
    //                             </Typography>
    //                         </Grid>
    //                     </Grid>
    //                     <Grid item xs={11} md={5} lg={4}>
    //                         <Grid container alignItems='center' style={{ height: 55 }}>
    //                             <Typography variant='inherit'>
    //                                 <FormattedMessage id="prompt_last_name" />&nbsp;:
    //                             </Typography>
    //                             <Typography variant='inherit' noWrap className={classes.userInfoContent}>{formateText(userprofile?.lastName)}</Typography>
    //                         </Grid>
    //                     </Grid>
    //                     <Grid item xs={11} md={5} lg={4}>
    //                         <Grid container alignItems='center' style={{ height: 55 }}>
    //                             <Typography variant='inherit'>
    //                                 <FormattedMessage id="prompt_email" />&nbsp;:
    //                             </Typography>
    //                             <Typography variant='inherit' noWrap className={classes.userInfoContent}>{formateText(userprofile?.mail)}</Typography>
    //                         </Grid>
    //                     </Grid>
    //                     <Grid item xs={11} md={5} lg={4}>
    //                         <Grid container alignItems='center' style={{ height: 55 }}>
    //                             <Typography variant='inherit'>
    //                                 <FormattedMessage id="hint_staff_id" />&nbsp;:
    //                             </Typography>
    //                             <Typography variant='inherit' noWrap className={classes.userInfoContent}>{formateText(userprofile?.staffId)}</Typography>
    //                         </Grid>
    //                     </Grid>
    //                     <Grid item xs={11} md={5} lg={4}>
    //                         <Grid container alignItems='center' style={{ height: 55 }}>
    //                             <Typography variant='inherit'>
    //                                 <FormattedMessage id="prompt_phone_number" />&nbsp;:
    //                             </Typography>
    //                             <Typography variant='inherit' noWrap className={classes.userInfoContent}>{formateText(userprofile?.phone)}</Typography>
    //                         </Grid>
    //                     </Grid>
    //                 </Grid>
    //             </Grid>
    //             {isMaster(StoreUtil.user()?.permissionType || 0) ? <IconButton onClick={() => { setEditUser(true) }} style={{ position: 'absolute', right: 0, top: 24 }}><EditIcon fontSize='small' fill='#ff6600' /></IconButton> : null}
    //         </Grid>
    //         <Grid container spacing={0} className={classes.BorderBottom}>
    //             <Grid container alignItems='center' style={{ height: 100 }}>
    //                 <Grid item>
    //                     <span className='userBoxLabel'><FormattedMessage id="prompt_company_information" /></span>
    //                 </Grid>
    //             </Grid>
    //             <Grid container spacing={0}>
    //                 {/* {StoreUtil.user()?.permissionType === -1
    //                     ?
    //                     < Grid item lg={1} md={2} xs={12} style={{ position: 'relative' }}>
    //                         <input
    //                             key={Math.random()}
    //                             title=''
    //                             className={classes.input}
    //                             id="upload_company_logo"
    //                             type="file"
    //                             onChange={event => uploadImageLogo(event)}
    //                         />
    //                         {urlLogo === '' ?
    //                             null
    //                             :
    //                             < label htmlFor='upload_company_logo' className={classes.uoloadActionLabel} ><EditRoundIcon fill='white' fontSize='small' /></label>

    //                         }
    //                         {urlLogo === ''
    //                             ?
    //                             <label htmlFor='upload_company_logo' className={classes.CompanyAvater}>
    //                                 <AddIcon />
    //                             </label>
    //                             :
    //                             <div style={{ backgroundImage: urlLogo, width: 64, height: 59, borderRadius: '100%', backgroundSize: 'cover' }} >
    //                             </div>}
    //                     </Grid>
    //                     : null
    //                 } */}
    //                 <Grid item lg={11} md={10} xs={9}>
    //                     <Grid container>
    //                         <Grid item xs={12} md={6} lg={6}>
    //                             <Grid container style={{ height: 55 }}>
    //                                 <Typography variant='inherit'>
    //                                     <FormattedMessage id="prompt_name" />&nbsp;:
    //                                 </Typography>
    //                                 <Typography variant='inherit' noWrap className={classes.userInfoContent}>{formateText(userprofile?.corp.corpName)}</Typography>
    //                             </Grid>
    //                         </Grid>
    //                         <Grid item xs={12} md={6} lg={6}>
    //                             <Grid container style={{ height: 55 }}>
    //                                 <Typography variant='inherit'>
    //                                     <FormattedMessage id="prompt_phone_number" />&nbsp;:
    //                                 </Typography>
    //                                 <Typography variant='inherit' noWrap className={classes.userInfoContent}>{formateText(userprofile?.corp.tel)}</Typography>
    //                             </Grid>
    //                         </Grid>
    //                         <Grid item xs={12} md={6} lg={6}>
    //                             <Grid container style={{ height: 55 }}>
    //                                 <Typography variant='inherit'>
    //                                     <FormattedMessage id="prompt_post_code" />&nbsp;:
    //                                 </Typography>
    //                                 <Typography variant='inherit' noWrap className={classes.userInfoContent}>{formateText(userprofile?.corp.postcode)}</Typography>
    //                             </Grid>
    //                         </Grid>
    //                         <Grid item xs={12} md={6} lg={6}>
    //                             <Grid container style={{ height: 55 }}>
    //                                 <Typography variant='inherit'>
    //                                     <FormattedMessage id="prompt_address" />&nbsp;:
    //                                 </Typography>
    //                                 <Typography variant='inherit' noWrap className={classes.userInfoContent}>{formateText(userprofile?.corp.address)}</Typography>
    //                             </Grid>
    //                         </Grid>
    //                     </Grid>
    //                 </Grid>
    //             </Grid>

    //         </Grid>
    //         <Grid container spacing={0} className={classes.BorderBottom}>
    //             <Grid container alignItems='center' style={{ height: 100 }}>
    //                 <Grid item>
    //                     <span className='userBoxLabel'><FormattedMessage id="prompt_permission" /></span>
    //                 </Grid>
    //             </Grid>
    //             <Grid container>
    //                 {userprofile && userprofile?.permissionType < 1
    //                     ?
    //                     <Grid container className={classes.permissionbox}>
    //                         <Grid item xs={2} md={2} lg={1}><FormattedMessage id="prompt_admin" /></Grid>
    //                         <Grid item xs={10} md={10} lg={11}>
    //                             <Grid container spacing={1}>
    //                                 {permission !== null ? permission[0].map(permission =>
    //                                     <Grid item>
    //                                         <PremisionLabel label={<FormattedMessage id={getPermissionName(permission.id)} />} />
    //                                     </Grid>
    //                                 ) : null}
    //                             </Grid>
    //                         </Grid>
    //                     </Grid>
    //                     :
    //                     null
    //                 }
    //                 {userprofile && userprofile?.permissionType < 2
    //                     ?
    //                     <Grid container className={classes.permissionbox}>
    //                         <Grid item xs={2} md={2} lg={1}><FormattedMessage id="prompt_manager" /></Grid>
    //                         <Grid item xs={10} md={10} lg={11}>
    //                             <Grid container spacing={1}>
    //                                 {permission !== null ? permission[1].map(permission =>
    //                                     <Grid item>
    //                                         <PremisionLabel label={<FormattedMessage id={getPermissionName(permission.id)} />} />
    //                                     </Grid>
    //                                 ) : null}
    //                             </Grid>
    //                         </Grid>
    //                     </Grid>
    //                     :
    //                     null
    //                 }
    //                 {userprofile && userprofile.permissionType < 3 && permission && permission[2].length > 0
    //                     ?
    //                     <Grid container className={classes.permissionbox}>
    //                         <Grid item xs={2} md={2} lg={1}><FormattedMessage id="prompt_user" /></Grid>
    //                         <Grid item xs={10} md={10} lg={11}>
    //                             <Grid container spacing={1}>
    //                                 {permission !== null ? permission[2].map(permission =>
    //                                     <Grid item>
    //                                         <PremisionLabel label={<FormattedMessage id={getPermissionName(permission.id)} />} />
    //                                     </Grid>
    //                                 ) : null}
    //                             </Grid>
    //                         </Grid>
    //                     </Grid>
    //                     :
    //                     null
    //                 }
    //             </Grid>
    //         </Grid>
    //         <Grid container spacing={0} className={classes.BorderBottom}>
    //             <Grid container alignItems='center' style={{ height: 100 }}>
    //                 <Grid item>
    //                     <span className='userBoxLabel'><FormattedMessage id="prompt_group" /></span>
    //                 </Grid>
    //             </Grid>
    //             <Grid container spacing={1} className={classes.GroupBottomSpace}>
    //                 {userprofile && userprofile?.permissionType > 0
    //                     ?
    //                     userprofile?.groups.map(item =>
    //                         <Grid item >
    //                             <PremisionLabel label={<Typography variant='inherit'>{item.groupName}</Typography>} />
    //                         </Grid>
    //                     )
    //                     : <Grid item >
    //                         <PremisionLabel label={<Typography variant='inherit'>{userprofile?.corp.corpName}</Typography>} />
    //                     </Grid>
    //                 }
    //             </Grid>
    //         </Grid>
    //         <Grid container spacing={0}>
    //             <Grid container alignItems='center' style={{ height: 100 }}>
    //                 <Grid item>
    //                     <span className='userBoxLabel'><FormattedMessage id="prompt_label" /></span>
    //                 </Grid>
    //             </Grid>
    //             <Grid container spacing={1} className={classes.GroupBottomSpace}>
    //                 {Boolean(userDetail?.tags) && userDetail!!.tags!!.length > 0 ? userDetail!!.tags!!.map(item =>
    //                     <Grid item >
    //                         <PremisionLabel label={<Typography variant='inherit'>
    //                             <TagIcon tagcolor={item.tagColor} fontSize='inherit' />
    //                                 &nbsp;&nbsp;
    //                                 {item.tagName}
    //                         </Typography>} />
    //                     </Grid>
    //                 )
    //                     :
    //                     <Grid container style={{ height: 80 }} justify='center' alignItems='flex-start'>
    //                         <Typography variant='inherit' style={{ fontSize: 16, fontWeight: 'normal', color: '#676870' }}>
    //                             <FormattedMessage id="promtp_no_label" />
    //                         </Typography>
    //                     </Grid>
    //                 }
    //             </Grid>
    //         </Grid>
    //     </Container >
    // )
}