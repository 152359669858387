import { Button, withStyles, ListItem, List, MenuItem, InputAdornment, Typography, Checkbox, TablePaginationBaseProps, AppBar, TablePagination, makeStyles, Theme, createStyles, IconButtonProps, LabelDisplayedRowsArgs, SelectProps, ButtonProps, Avatar, Chip, Backdrop, CircularProgress, Dialog, DialogContent, DialogContentText, DialogActions, IconButton, ListItemIcon, ListItemText, Tooltip, ButtonGroup, TableCell, TextField, TextFieldProps, WithStyles } from '@material-ui/core';
import styled from 'styled-components';
import React from 'react';
import { TablePaginationActionsProps } from '@material-ui/core/TablePagination/TablePaginationActions';
import { PageArray, BigPageArray } from '../config';
import KeyboardArrowDownTwoToneIcon from '@material-ui/icons/KeyboardArrowDownTwoTone'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { FormattedMessage, useIntl, } from 'react-intl'
import Popover from '@material-ui/core/Popover'

import { colors } from '../theme';

import { Group } from '../api/model'
import './index.css'
import ClearIcon from '@material-ui/icons/Clear';
// import { useProject } from './provider';
import { Map } from 'immutable'
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogActions from '@material-ui/core/DialogActions';
import COUNTRYS from "./locale_en.json";
import LANGUGAE_CODE from "./language_code.json";
import COUNTRY_CODE from "./country_code.json";
import "flagpack/dist/flagpack.css";
import { getCountryCallingCode, CountryCode } from "libphonenumber-js";
export const CornerButton = withStyles({
    root: {
        minWidth: 100,
        borderRadius: 5,
        paddingTop: 4,
        paddingBottom: 4,
        paddingRight: 16,
        paddingLeft: 16,
        fontSize: 13,
    }
})(Button);


export const CustomeButton = withStyles({
    root: {
        minWidth: 100,
        borderRadius: 5,
        paddingTop: 4,
        paddingBottom: 4,
        paddingRight: 16,
        paddingLeft: 16,
        fontSize: 13,
    }
})(Button);

/**
            borderRadius: 5,
            paddingTop: 4,
            paddingBottom: 4,
            paddingRight: 16,
            paddingLeft: 16,
            fontSize: 13,
            fontWeight: 'bold'
 */
export const CornerGradientButton = withStyles({
    root: {
        minWidth: 100,
        borderRadius: 5,
        paddingTop: 4,
        paddingBottom: 4,
        paddingRight: 16,
        paddingLeft: 16,
        fontSize: 13,
        background: 'linear-gradient(to right bottom, #ff6600, #fdc527)',
        color: '#fff',
        '&.Mui-disabled': {
            background: 'linear-gradient(to right bottom, rgba(242, 122, 84, 0.3), rgba(253, 197, 39, 0.22))',
            color: 'white'
        },
    },
    outlined: {
        '&.Mui-disabled': {
            background: 'transparent',
            color: '#ffe1cc',
            border: '1px solid #ffe1cc'
        },
        background: 'transparent',
        color: '#f60',
    },
    contained: {
        '&.Mui-disabled': {
            background: 'linear-gradient(to right bottom,rgba(242, 122, 84, 0.3), rgba(253, 197, 39, 0.22))',
            color: 'white'
        },
        background: '#ff6600',
    },
})(Button);

export const GradientButton = withStyles({
    root: {
        background: 'linear-gradient(to right bottom, #ff6600, #fdc527)',
        color: '#fff',
        minWidth: 100,
        fontWeight: 'bold',
        borderRadius: 5,
        paddingTop: 4,
        paddingBottom: 4,
        paddingRight: 16,
        paddingLeft: 16,
        fontSize: 13,
    }
})(Button);

export const SmallGradientButton = withStyles({
    root: {
        background: 'linear-gradient(to right bottom, #ff6600, #fdc527)',
        color: '#fff',
        minWidth: 0,
        fontWeight: 'bold',
        borderRadius: 5,
        padding: 4,
        fontSize: 13,
    }
})(Button);

export const CustomTableCell = withStyles({
    root: {
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 10,
        paddingBottom: 10
    }
})(TableCell);

export const CustomLabelChip = withStyles({
    root: {
        fontSize: 16,
        height: 32,
        minWidth: 96,
        backgroundColor: "rgba(255, 102, 0, 0.08)",
        borderRadius: 6
    }
})(Chip);

export const NavListItem = withStyles({
    root: {
        '&$selected': {
            '&:before': {
                content: '""',
                borderImageSlice: 2,
                borderImageSource: 'linear-gradient(#ff6600, #fdc527)',
                borderStyle: 'solid',
                borderWidth: 2,
                height: "100%",
                position: 'absolute',
                left: 0,
            },
            background: "#1e2029"
        }
    },
    selected: {}
})(ListItem);

export const NavSelect = styled(List)`
a.Mui-selected {
    &:before {
        content: '';
        width: 4px;
        border-radius: 2px;
        background: linear-gradient(#ff6600, #fdc527);
        height: 100%;
        position: absolute;
        left: 0;
    }
    background: #1e2029;
};
ul>a>div.MuiListItemText-root {
    text-indent: 0px;
    padding-left: 32px;
}
text-indent: 20px;
`

interface TagsProps {
    colors: Array<string>
}

export const Tags = React.memo((props: TagsProps) => {
    const { colors } = props
    const lastIndex = colors.length > 6 ? 5 : colors.length - 1
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="29" viewBox="0 0 48 29">
            <g transform="translate(0 5)">
                {colors.slice(0, 6).map((color, index) => (
                    <path key={`color_${index}`} fill={color} transform={`rotate(${15 * index},24 24)`} stroke="#FFF" d="M23.887 14.336c-.003-.315-.132-.62-.358-.854L10.805.402c-.49-.504-1.303-.539-1.812-.078l-8.6 7.802c-.51.464-.526 1.249-.036 1.753l12.724 13.084c.226.233.532.373.858.398l8.713.636c.738.052 1.357-.508 1.348-1.224l-.113-8.437z" />
                ))}
                <path fill="#FFF" transform={`rotate(${15 * lastIndex},24 24)`} d="M21.11 21.689c-.564.252-1.234.015-1.499-.53-.264-.545-.016-1.192.548-1.448.565-.255 1.235-.015 1.5.53.264.545.016 1.192-.548 1.448z" />
            </g>
        </svg>
    )
})

const bottomPaginationUseStyles = makeStyles((theme: Theme) => createStyles({
    appBar: {
        top: 'auto',
        bottom: 0,
        [theme.breakpoints.up('md')]: {
            width: `calc(100% - 220px)`,
            marginLeft: 220,
        },
        background: '#f5f5f5',
    },
    pager: {
        '& .MuiToolbar-root': {
            paddingLeft: 0,
        }
    },
}));

type BottomPaginationPrpos = TablePaginationBaseProps & {
    ActionsComponent?: React.ElementType<TablePaginationActionsProps>;
    backIconButtonProps?: Partial<IconButtonProps>;
    count: number;
    labelDisplayedRows?: (paginationInfo: LabelDisplayedRowsArgs) => React.ReactNode;
    labelRowsPerPage?: React.ReactNode;
    nextIconButtonProps?: Partial<IconButtonProps>;
    onChangePage: (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void;
    onChangeRowsPerPage?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
    page: number;
    rowsPerPage: number;
    SelectProps?: Partial<SelectProps>;
    printPage?: boolean;
};

export const BottomPagination = React.memo((props: BottomPaginationPrpos) => {
    const pagerRef = React.createRef<HTMLDivElement>()
    const classes = bottomPaginationUseStyles()
    React.useEffect(() => {
        const newElement = document.createElement('div');
        newElement.className = 'MuiTablePagination-spacer'
        pagerRef.current?.firstElementChild?.appendChild(newElement)
    }, [])
    return (<AppBar position="fixed" color='inherit' className={classes.appBar} elevation={0}>
        <TablePagination
            {...props}
            component="div"
            rowsPerPageOptions={Boolean(props.printPage) ? BigPageArray : PageArray}
            ref={pagerRef}
            className={classes.pager}
        />
    </AppBar>)
}, (prev: BottomPaginationPrpos, next: BottomPaginationPrpos) => (prev.count === next.count && prev.rowsPerPage === next.rowsPerPage && prev.page === next.page))


export const Loading = React.memo(({ open }: { open: boolean }) => (
    <Backdrop className='backdrop' open={open} >
        <CircularProgress color="primary" />
    </Backdrop>
))

type FilterButtonStyle = ButtonProps & {
    name: string,
    bodyName?: string,
    clickMethod: (event: React.MouseEvent<HTMLButtonElement>) => void,
    isSelect: boolean
    isHighlight: boolean
}


function FilterButtonComponent(prop: FilterButtonStyle) {
    const classes = makeStyles({
        high: {
            color: colors.primary,
            fontSize: 12,
            borderRadius: 5,
            border: 'solid 1px #ff6600',
            padding: '4px 16px'

        },
        low: {
            color: colors.secondaryNormal,
            fontSize: 12,
            borderRadius: 5,
            border: 'solid 1px rgba(38, 40, 52, 0.2)',
            padding: '4px 16px'

        }
    })()

    return (
        <Button className={prop.isHighlight ? classes.high : classes.low} endIcon={prop.isSelect ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownTwoToneIcon />} onClick={prop.clickMethod}>
            <span style={{ fontSize: 12, paddingRight: 30 }}> <FormattedMessage id={prop.name} values={{ itemCount: prop.bodyName || 0 }} /> </span>
        </Button>
    )
}

export const FilterSelectButton = React.memo(FilterButtonComponent)

interface FilterSelectClearButtonProps {
    messageId: string
    selectCount: number
    isOpen: boolean
    onClick: (event?: React.MouseEvent<HTMLButtonElement>) => void
    onClear: () => void
}

export const FilterSelectClearButton = React.memo((prop: FilterSelectClearButtonProps) => {
    const classes = makeStyles({
        root: {
            borderRadius: 5,
            border: prop.selectCount > 0 ? 'solid 1px #ff6600' : 'solid 1px rgba(38, 40, 52, 0.2)',
        },
        boutton: {
            color: prop.selectCount > 0 ? colors.primary : colors.secondaryNormal,
            padding: '3px 16px',
            borderColor: "transparent",
        },
        icon: {
            color: prop.selectCount > 0 ? colors.primary : colors.secondaryNormal,
            padding: '0px 0px',
            borderColor: "transparent",
            '& > :first-child > :first-child': {
                fontSize: 20,
            }
        }
    })()
    const icon = prop.selectCount > 0 ? <ClearIcon /> : prop.isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownTwoToneIcon />
    return (
        <ButtonGroup className={classes.root}>
            <Button onClick={prop.onClick} className={classes.boutton}>
                <span style={{ fontSize: 12, paddingRight: 30 }}> <FormattedMessage id={prop.messageId} values={{ itemCount: prop.selectCount }} /> </span>
            </Button>
            <Button size='small' className={classes.icon}
                onClick={prop.selectCount > 0 ? prop.onClear : prop.onClick}>{icon}</Button>
        </ButtonGroup>
    )
})


const userAvatarStyle = makeStyles({
    admin: {
        position: 'absolute',
        bottom: 0,
        left: '50%',
        transform: 'translate(-50%, 0)',
        height: 'auto',
        color: '#fafafa',
        background: '#ff6600',
        fontSize: '0.7rem',
        maxWidth: 80,
    },
    manager: {
        position: 'absolute',
        bottom: 0,
        left: '50%',
        transform: 'translate(-50%, 0)',
        padding: '0rem 0px',
        height: 'auto',
        color: '#fafafa',
        background: '#000',
        fontSize: '0.7rem',
        maxWidth: 80,
    },
    avatar: {
        margin: 'auto'
    }
})

interface TagChipProps {
    tagColor: string
    tagId: number
    label: string
    forceUpdate?: number
    defaultCheck: boolean
    onCheck: (isChecked: boolean, tagId: number) => void
}

export const TagChip = React.memo((props: TagChipProps) => {
    const { tagColor, label, tagId, onCheck, defaultCheck, forceUpdate } = props;
    const [check, setCheck] = React.useState(defaultCheck)
    React.useEffect(() => {
        setCheck(defaultCheck)
    }, [forceUpdate])
    const handleClick = () => {
        setCheck(!check)
        onCheck(!check, tagId)
    }
    return <Chip label={label} style={{
        backgroundColor: check ? tagColor : 'transparent',
        color: check ? "#fff" : tagColor,
        border: check ? "none" : `1px solid ${tagColor}`,
        minWidth: 60,
    }} onClick={handleClick} />
}, (p, n) => (p.forceUpdate === n.forceUpdate))

interface GroupChipProps {
    groupId: number
    label: string | React.ReactNode
    defaultCheck: boolean
    forceUpdate?: number
    onCheck: (isChecked: boolean, groupId: number) => void
}

export const GroupChip = React.memo((props: GroupChipProps) => {
    const { label, groupId, onCheck, defaultCheck, forceUpdate } = props;
    const [check, setCheck] = React.useState(defaultCheck)
    const handleClick = () => {
        setCheck(!check)
        onCheck(!check, groupId)
    }
    React.useEffect(() => {
        setCheck(defaultCheck)
    }, [forceUpdate])
    return <Chip style={{ backgroundColor: check ? "#f60" : "rgba(38, 40, 52, 0.1)", minWidth: 70, color: check ? "white" : "#676870" }} label={label} onClick={handleClick} />
}, (p, n) => (p.forceUpdate === n.forceUpdate))


const userDialogStyle = makeStyles({
    contentText: {
        color: colors.textSeconday,
        fontSize: 18,
    }
})

interface DialogProps {
    defaultOpen: boolean
    content: string | JSX.Element,
    onCloseAgree: () => void
    onCloseDisagree?: () => void
}

export const ShowDialogAction = (prop: DialogProps) => {
    const cls = userDialogStyle()
    return (
        <Dialog maxWidth='lg' open={prop.defaultOpen} onClose={prop.onCloseDisagree} aria-labelledby="showmax-width-dialog-title">
            <DialogContent> <DialogContentText className={cls.contentText}>{prop.content}</DialogContentText> </DialogContent>
            <DialogActions>
                {prop.onCloseDisagree ? <Button onClick={prop.onCloseDisagree} color="primary">
                    <FormattedMessage id='action_cancel'></FormattedMessage>
                </Button> : null}
                <Button onClick={prop.onCloseAgree} color="primary" autoFocus>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    )
}



interface RoundStatusProps {
    fontColor: string
    messageId: string
}
export class RoundStatus extends React.PureComponent<RoundStatusProps> {
    render() {
        const fontColor = this.props.fontColor
        const messageId = this.props.messageId
        return <Chip variant='outlined' style={{ minWidth: 100, borderColor: fontColor, color: fontColor, fontSize: '1rem' }} label={<FormattedMessage id={messageId} />} ></Chip>
    }
}

interface ReportAccessProp {
    anchorEl: HTMLButtonElement | null,
    onClose: () => void,
    accessParams: Array<number>,
    onSelectIndex: (index: 0 | 1 | 2, checked: boolean) => void,
}

export const ReportAccessComponent = (prop: ReportAccessProp) => {
    const open = Boolean(prop.anchorEl);
    const id = open ? 'simple-popover' : undefined
    return (
        <Popover
            id={id}
            open={open}
            anchorEl={prop.anchorEl}
            onClose={prop.onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <List style={{ padding: 0 }}>
                <ListItem style={{ paddingLeft: 5, paddingRight: 16, paddingTop: 0, paddingBottom: 0 }}>
                    <ListItemIcon style={{ minWidth: 42 }}>
                        <Checkbox color='primary' checked={prop.accessParams.includes(0)} onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => prop.onSelectIndex(0, checked)}></Checkbox>
                    </ListItemIcon>
                    <ListItemText style={{ fontSize: 16, color: colors.textSeconday }}>
                        <FormattedMessage id='prompt_access' />
                    </ListItemText>
                </ListItem>
                <ListItem style={{ paddingLeft: 5, paddingRight: 16, paddingTop: 0, paddingBottom: 0 }}>
                    <ListItemIcon style={{ minWidth: 42 }}>
                        <Checkbox color='primary' checked={prop.accessParams.includes(1)} onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => prop.onSelectIndex(1, checked)}></Checkbox>
                    </ListItemIcon>
                    <ListItemText style={{ fontSize: 16, color: colors.textSeconday }}>
                        <FormattedMessage id='prompt_authorization' />
                    </ListItemText>
                </ListItem>
                <ListItem style={{ paddingLeft: 5, paddingRight: 16, paddingTop: 0, paddingBottom: 0 }}>
                    <ListItemIcon style={{ minWidth: 42 }}>
                        <Checkbox color='primary' checked={prop.accessParams.includes(2)} onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => prop.onSelectIndex(2, checked)}></Checkbox>
                    </ListItemIcon>
                    <ListItemText style={{ fontSize: 16, color: colors.textSeconday }}>
                        <FormattedMessage id='prompt_user_activity' />
                    </ListItemText>
                </ListItem>
            </List>



        </Popover>
    )
}

export const CustomeTextFiled = React.memo((props: TextFieldProps) => {
    const classes = makeStyles({
        root: {
            '& .MuiInputBase-root': {
                height: 31,
                width: 170
            },
            '& label': {
                color: colors.secondaryNormal,
                marginTop: -3,
                fontSize: 13,
            }
        }
    })()
    return <TextField {...props} variant="outlined" size='small' type='text' autoComplete='off' className={classes.root} />
})

const dialogTitleStyles = (theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2),
            color: 'white',
            background: 'black',
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: "12px",
            color: 'white',
        },
    });

export interface DialogTitleProps extends WithStyles<typeof dialogTitleStyles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

export const DialogTitle = withStyles(dialogTitleStyles)((props: DialogTitleProps) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="subtitle1">{children}</Typography>
            {onClose ? (
                <IconButton size='small' aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

export const CustomeDialogActions = withStyles({
    root: {
        padding: "24px",
        paddingTop: "8px",
        paddingBottom: "16px",
    },
})(MuiDialogActions);

interface SomeContentLengthProps {
    firstnameLength: number,
    phoneLength: number,
    lastnameLength: number,
    staffidLength: number,
    mailLength: number
}
export const SomeContentLength: SomeContentLengthProps = {
    firstnameLength: 40,
    phoneLength: 12,
    lastnameLength: 40,
    staffidLength: 40,
    mailLength: 50
}
interface CountryPhoneProps {
    onChange: (code: string) => void;
    code?: string;
    classes: any;
}

interface CountryPhoneStates {
    code: string;
    anchor: HTMLElement | undefined;
    open: boolean;
}

function CountryPhoneStyle() {
    return createStyles({
        icon: {
            color: "#929399",
        },
        root: {
            "& > div": {
                height: 50,
            },
            "& > * > input": {
                padding: "10px 14px",
            },
            "& .MuiSelect-select": {
                padding: "10px 14px",
                minWidth: "0px",
                textIndent: "-9999px",
                lineHeight: "0px",
            },
        },
    });
}

class CountryPhoneLogin extends React.PureComponent<
    CountryPhoneProps,
    CountryPhoneStates
    > {
    constructor(prop: CountryPhoneProps) {
        super(prop);
        const lans = navigator.languages;
        const lang = navigator.language;
        const languageMap = Map(LANGUGAE_CODE);
        console.log(prop);
        if (prop.code === undefined) {
            let code = "";
            if (lans.length > 0) {
                lans.forEach((l) => {
                    if (l.includes("-")) {
                        code = l.substring(l.indexOf("-") + 1);
                        return;
                    }
                });
            }
            if (code.length === 0) {
                const v = languageMap.get(lang);
                if (v) {
                    code = v;
                }
            }
            console.log(code);
            if (code.length !== 0) {
                this.state = {
                    code,
                    open: false,
                    anchor: undefined,
                };
                prop.onChange(code);
            }
        } else {
            this.state = {
                code: prop.code,
                open: false,
                anchor: undefined,
            };
        }
        this.handleClose = this.handleClose.bind(this);
        this.handleOpen = this.handleOpen.bind(this);
        this.handleCloseMenu = this.handleCloseMenu.bind(this);
    }

    handleOpen(event: React.MouseEvent<HTMLElement>) {
        console.log(event.currentTarget);
        this.setState((s) => {
            return { ...s, anchor: event.currentTarget, open: true };
        });
    }

    handleClose(code: string) {
        this.props.onChange(code);
        this.setState((s) => {
            return { ...s, open: false, code, anchor: undefined };
        });
    }

    handleCloseMenu() {
        this.setState((s) => {
            return { ...s, open: false, anchor: undefined };
        });
    }

    render() {
        const countryMap = Map(COUNTRYS);
        const iconClass = `fp fp-md ${this.state.code?.toLowerCase()}`;
        return (
            <TextField
                select
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <span className={iconClass} />
                        </InputAdornment>
                    ),
                }}
                variant="outlined"
                value={this.state.code}
                onChange={(e) => this.handleClose(e.target.value)}
                className={this.props.classes.root}
            >
                {COUNTRY_CODE.map((code) => (
                    <MenuItem key={code} value={code}>
                        {`+${getCountryCallingCode(code as CountryCode)}(${countryMap.get(
                            code,
                            ""
                        )})`}
                    </MenuItem>
                ))}
            </TextField>
        );
    }
}

export const CountryPhoneLoginWidget = withStyles(CountryPhoneStyle, {
    withTheme: true,
})(CountryPhoneLogin);
class CountryPhone extends React.PureComponent<
    CountryPhoneProps,
    CountryPhoneStates
    > {
    constructor(prop: CountryPhoneProps) {
        super(prop);
        const lans = navigator.languages;
        const lang = navigator.language;
        const languageMap = Map(LANGUGAE_CODE);
        console.log(prop);
        if (prop.code === undefined) {
            let code = "";
            if (lans.length > 0) {
                lans.forEach((l) => {
                    if (l.includes("-")) {
                        code = l.substring(l.indexOf("-") + 1);
                        return;
                    }
                });
            }
            if (code.length === 0) {
                const v = languageMap.get(lang);
                if (v) {
                    code = v;
                }
            }
            console.log(code);
            if (code.length !== 0) {
                this.state = {
                    code,
                    open: false,
                    anchor: undefined,
                };
                prop.onChange(code);
            }
        } else {
            this.state = {
                code: prop.code,
                open: false,
                anchor: undefined,
            };
        }
        this.handleClose = this.handleClose.bind(this);
        this.handleOpen = this.handleOpen.bind(this);
        this.handleCloseMenu = this.handleCloseMenu.bind(this);
    }

    handleOpen(event: React.MouseEvent<HTMLElement>) {
        console.log(event.currentTarget);
        this.setState((s) => {
            return { ...s, anchor: event.currentTarget, open: true };
        });
    }

    handleClose(code: string) {
        this.props.onChange(code);
        this.setState((s) => {
            return { ...s, open: false, code, anchor: undefined };
        });
    }

    handleCloseMenu() {
        this.setState((s) => {
            return { ...s, open: false, anchor: undefined };
        });
    }

    render() {
        const countryMap = Map(COUNTRYS);
        const iconClass = `fp fp-md ${this.state.code?.toLowerCase()}`;
        return (
            <TextField
                select
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <span className={iconClass} />
                        </InputAdornment>
                    ),
                }}
                value={this.state.code}
                variant="outlined"
                onChange={(e) => this.handleClose(e.target.value)}
                className={this.props.classes.root}
            >
                {COUNTRY_CODE.map((code) => (
                    <MenuItem key={code} value={code}>
                        {`+${getCountryCallingCode(code as CountryCode)}(${countryMap.get(
                            code,
                            ""
                        )})`}
                    </MenuItem>
                ))}
            </TextField>
        );
    }
}
export const CountryPhoneWidget = withStyles(CountryPhoneStyle, {
    withTheme: true,
})(CountryPhone);

interface PremisionLabelPropos {
    label: string | React.ReactNode;
    isSelceted?: boolean;
}
export class PremisionLabel extends React.PureComponent<PremisionLabelPropos> {
    render() {
        const isSelected = this.props.isSelceted || false;
        return (
            <div className={isSelected ? "userPermissionSelect" : "userPermission"}>
                <span>{this.props.label}</span>
            </div>
        );
    }
}